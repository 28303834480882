import { Injectable } from '@angular/core';
import { Recruitment } from '../../../recruitments/entities/recruitment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RecruitmentService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private config: Config;

  constructor(private http: HttpClient, configService: DynamicConfigService) {
    this.config = configService.get<Config>();
  }

  public get(recruitmentId: string) {
    return this.http.get<Recruitment>(`${this.config.apiUrl}/recruitments/${recruitmentId}`).pipe(map(this.convertRecruitment));
  }

  public getAll(userId?: string) {
    return this.http
      .get<Recruitment[]>(`${this.config.apiUrl}/recruitments`, {
        params: userId ? { userId } : null,
      })
      .pipe(map(r => r.map(this.convertRecruitment)));
  }

  public save(recruitment: Recruitment) {
    if (recruitment.id) {
      return this.http.put<Recruitment>(`${this.config.apiUrl}/recruitments/${recruitment.id}`, recruitment, { headers: this.headers });
    } else {
      return this.http.post<Recruitment>(`${this.config.apiUrl}/recruitments`, recruitment, { headers: this.headers });
    }
  }

  private convertRecruitment(recruitment: Recruitment) {
    recruitment.maximumValidityDate = new Date(recruitment.maximumValidityDate);
    return recruitment;
  }
}
