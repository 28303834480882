<div class="modal-header bg-white pb-2" data-cy="DIV-5bc5">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-3ca1">
    <span aria-hidden="true" data-cy="SPAN-bda8">&times;</span>
  </button>
  <div class="modal-title w-100 text-center mt-2" data-cy="DIV-0785">
    <p class="h3" [translate]="'EMAILING.RESET_EMAIL_TITLE'"></p>
  </div>
</div>

<div class="text-center modal-body bg-white" data-cy="DIV-1065">
  <p class="text-secondary pt-1" [translate]="'EMAILING.RESET_EMAIL_CONTENT'"></p>
  <p>
    <button class="btn btn-success" (click)="resetEvent.emit()" [translate]="'EMAILING.RESET_EMAIL_SUBMIT'" data-cy="BUTTON-d302"></button>
  </p>
  <p>
    <a href="javascript:void(0)" class="link" (click)="this.close()" [translate]="'EMAILING.RESET_EMAIL_CANCEL'" data-cy="A-5105"></a>
  </p>
</div>
