import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecruitmentMode } from 'src/app/shared/business/recruitments/recruitment-mode/recruitment-mode';
import { RecruitmentModeService } from 'src/app/shared/business/recruitments/recruitment-mode/recruitment-mode.service';
import { SelectUsersTeams } from 'src/app/shared/layout/select-users-teams/select-users-teams';
import { SharingRecipientType } from 'src/app/shared/sharing/entities/sharing-recipient-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/auth/user';

import { EmailType } from '../entities/email-type';
import { EmailTypeEnum } from '../entities/email-type.enum';
import { EmailTypeService } from '../email-type.service';
import { EmailingSearchDto } from './emailing-search.dto';
import { Language } from '../../languages/language';
import { LanguageService } from '../../languages/language.service';
import { Team } from '../../../../panel/shared/entities/team';

@Component({
  selector: 'app-emailing-search-form',
  templateUrl: './emailing-search-form.component.html',
  styleUrls: ['./emailing-search-form.component.scss'],
})
export class EmailingSearchFormComponent {
  public search: EmailingSearchDto = {};
  public me: User;

  public languages: Language[] = [];
  public emailTypes: EmailType[] = [];
  public modes: RecruitmentMode[] = [];

  @Output() public createEmailEmitter = new EventEmitter<void>();
  @Output() public filter = new EventEmitter<EmailingSearchDto>();

  @Input() public teams: Promise<{ data: Team[]; total: number }>;
  @Input() public users: Promise<{ data: User[]; total: number }>;
  @Input() public panel: boolean = false;

  public filterGroup!: UntypedFormGroup;

  constructor(
    readonly translate: TranslateService,
    languageService: LanguageService,
    emailTypeService: EmailTypeService,
    recruitmentModeService: RecruitmentModeService,
    route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.me = route.snapshot.data.me;
    this.languages = languageService.getCandidateLanguages();
    this.modes = recruitmentModeService.getAll();
    emailTypeService.getAll().subscribe(types => {
      this.emailTypes = types;
    });

    this.modes = this.modes.filter(m => this.me.options.recruitmentModes.indexOf(m.value) > -1);

    this.filterGroup = this.formBuilder.group({
      language: null,
      type: null,
      mode: null,
    });
  }

  public filterByLanguage(event: { value: string }): void {
    this.search.language = event?.value;
    this.filter.emit(this.search);
  }

  public filterByText(event: { target: { value: string } }): void {
    this.search.title = event.target?.value.toLowerCase();
    this.filter.emit(this.search);
  }

  public filterByUserOrTeam(selectUsersTeams: SelectUsersTeams): void {
    this.search.ownerId = selectUsersTeams?.type == SharingRecipientType.user ? selectUsersTeams.id : undefined;
    this.search.teamId = selectUsersTeams?.type == SharingRecipientType.team ? selectUsersTeams.id : undefined;
    this.filter.emit(this.search);
  }

  public filterByType(event: { value: EmailTypeEnum }): void {
    this.search.type = event?.value;
    this.filter.emit(this.search);
  }

  public filterByMode(event: { value: string }): void {
    this.search.mode = event?.value;
    this.filter.emit(this.search);
  }
}
