<div class="col-12 row g-2 mt-0 mt-sm-3 mb-2" data-cy="DIV-8b7e">
  <div class="col-12 col-md-8 ps-0" data-cy="DIV-5ae3">
    <div class="input-group" data-cy="DIV-a403">
      <div class="input-group-text bg-lightest-gray" data-cy="DIV-ce1f">
        <i class="material-icons" data-cy="I-f525">search</i>
      </div>
      <input
        type="text"
        class="form-control"
        id="search-email"
        placeholder="Rechercher un statut"
        [placeholder]="'STATUS.PLACEHOLDER_RECHERCHER_STATUS' | translate"
        #search
        (input)="filterByValue(search.value)"
        data-cy="INPUT-aeec"
      />
    </div>
  </div>
  <div class="col-12 col-md-4 text-center order-5 order-md-2" data-cy="DIV-365c">
    <button
      class="btn btn-success"
      type="button"
      [translate]="'STATUS.CREER_STATUS'"
      (click)="openCreateStatusModal()"
      data-cy="BUTTON-a16a"
    ></button>
  </div>
</div>
