<button type="button" class="btn btn-link p-0 text-decoration-none" #popover [outsideClick]="true" container="body" [popover]="popTemplate" containerClass="p-0" [placement]="placement" (click)="$event.stopPropagation()" data-cy="BUTTON-ffde">
  <i class="material-icons md-24 text-secondary" data-cy="I-c5b1"> tag_faces </i>
</button>

<ng-template #popTemplate>
  <div class="row g-2 emojis m-0" data-cy="DIV-44cd">
    <div class="col-2 text-center" *ngFor="let emoji of emojis" data-cy="DIV-60f5">
      <button type="button" class="p-0 btn btn-link text-decoration-none" title="{{emoji.label}}" (click)="onClick(emoji.key)" data-cy="BUTTON-5395">{{ emoji.key }}</button>
    </div>
  </div>
</ng-template>
