import posthog from 'posthog-js';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Injectable } from '@angular/core';

import { Config, EnvironmentEnum } from '../config/config';

@Injectable({ providedIn: 'root' })
export class PostHogService {
  private isActivated = false;

  constructor(private configService: DynamicConfigService) {
    this.isActivated = this.configService.get<Config>().environment === EnvironmentEnum.production;
  }

  enable() {
    if (!this.isActivated) {
      return;
    }

    posthog.init(this.configService.get<Config>().posthogKey, {
      api_host: this.configService.get<Config>().urlPosthog,
      persistence: 'memory',
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
      },
    });
  }

  identify(userId: string, props: { [key: string]: string | boolean | number }) {
    if (!this.isActivated) {
      return;
    }

    posthog.identify(userId, props);
  }
}
