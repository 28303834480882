import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationResult } from 'src/app/shared/requests/pagination-result';

import { Config } from '../../shared/config/config';
import { SearchStatusDto } from './dto/search-status.dto';
import { Status } from './status';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private config: Config;

  constructor(private http: HttpClient, configService: DynamicConfigService) {
    this.config = configService.get<Config>();
  }

  public getAll(search: SearchStatusDto = {}) {
    return this.http.get<PaginationResult<Status>>(`${this.config.apiUrl}/statuses`, { params: { ...search } });
  }

  public create(status: Status) {
    return this.http.post<Status>(`${this.config.apiUrl}/statuses`, status);
  }

  public delete(id: number) {
    return this.http.delete<Status>(`${this.config.apiUrl}/statuses/${id}`);
  }

  public update(status: Status) {
    return this.http.patch<Status>(`${this.config.apiUrl}/statuses/${status.id}`, status);
  }
}
