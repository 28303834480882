import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { ItemsAction } from 'src/app/share/account-menu-selector/items-action';

import { User } from '../../../auth/user';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public previousUrl: string;
  @Input()
  public tag: string;
  @Input()
  public hasCloseButton: boolean = true;
  @Input()
  public hasMenu: boolean = true;
  @Input()
  public backgroundClasses: string;
  public colorBackground: string = '';
  public hasScrolled: boolean = false;
  public subscription: any;
  public redirectV1: string;
  public me: User = this.route.snapshot.data?.me;
  public itemsMenuHeader: ItemsAction[] = [
    {
      index: 1,
      translate: 'PRIVATE_SHARE.HEADER.MENU.LOGOUT',
      action: () => this.authService.logout(),
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (!!this.route.snapshot.data.panel) {
      this.backgroundClasses = 'panel';
      this.colorBackground = 'border-panel bg-panel';
    } else {
      this.colorBackground = 'border-blue bg-blue';
    }
  }

  public runActionItem(item: ItemsAction): void {
    item.action();
  }
}
