import { Component, Input, TemplateRef } from '@angular/core';
import { BreadcrumbItem } from './breadcrumb-item';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
})
export class CommonFooterComponent {
  @Input()
  breadcrumbItems: BreadcrumbItem[];
  @Input()
  context: any;
  @Input()
  form: any;
  @Input()
  buttons: TemplateRef<any>;
}
