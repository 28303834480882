import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './language';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languages: Language[] = [];

  constructor(private translateService: TranslateService) {
    this.languages = this.translate(environment.languages);
  }

  public getRecruiterLanguages(): Language[] {
    return this.languages;
  }

  public getCandidateLanguages(mode?: string): Language[] {
    return this.translate(
      environment.recruitmentModes
        .filter(m => (mode ? m.key === mode : true))
        .map(r => r.languages)
        .reduce((acc, arr) => [...acc, ...arr], [])
        .filter((v, idx, arr) => !arr.includes(v, idx + 1))
    );
  }

  public translate(languages: string[]): Language[];

  public translate(languages: string): Language;

  public translate(languages): any {
    if (Array.isArray(languages)) {
      return languages.map(l => {
        return this._translate(l);
      });
    } else {
      return this._translate(languages);
    }
  }

  private _translate(language: string) {
    const translationKey = `LANGAGE.${language.toUpperCase()}`;
    return { value: language, label: this.translateService.instant(translationKey) };
  }
}
export class LanguageType {
  public value: string;
  public label: string;
}
