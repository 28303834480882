<footer class="navbar text-center">
  <div class="w-100 d-flex flex-md-row flex-column justify-content-between align-items-center" data-cy="DIV-eaf4">

    <div class="px-2 invisible d-none d-md-block" *ngIf="buttons" data-cy="DIV-2560">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <div class="flex-fill" data-cy="DIV-c325">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center align-items-center m-0 p-0">
          <li             *ngFor="let item of breadcrumbItems"
            class="breadcrumb-item"
            aria-current="page"
          >
            <a *ngIf="item.url" routerLink="{{item.url}}" queryParamsHandling="merge" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" data-cy="A-d86a">{{item.title}}</a>
            <span *ngIf="!item.url" [class.active]="item.isActive" data-cy="SPAN-7c08">{{item.title}}</span>
          </li>
        </ol>
      </nav>
    </div>

    <div class="px-2" *ngIf="buttons" data-cy="DIV-9686">
      <ng-container *ngTemplateOutlet="buttons; context: context"></ng-container>
    </div>

  </div>
</footer>
