import { Angulartics2Module } from 'angulartics2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CookieService } from 'ngx-cookie-service';
import { DynamicConfigModule } from '@cleverconnect/ngx-dynamic-config';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { PageNotFoundModule } from '@cleverconnect/ngx-page-not-found';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppTranslationModule } from './app-translation.module';
import { ErrorLoggerHandler } from './shared/requests/error-logger.handler';
import { HttpUserIdInterceptor } from './shared/requests/http-user-id-interceptor';
import { HttpXsrfInterceptor } from './shared/requests/http-xsrf-interceptor';
import { InterviewThanksComponent } from './interviews/interview-thanks/interview-thanks.component';
import { LoggingModule } from './shared/logging/logging.module';
import { MenuPageComponent } from './menu-page/menu-page.component';
import { NotificationsInterceptor } from './shared/requests/notifications-interceptor';
import { SettingsModule } from './settings/settings.module';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './shared/requests/token-interceptor';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export const httpInterceptorProvider = { provide: HTTP_INTERCEPTORS, useClass: NotificationsInterceptor, multi: true };
export const authInterceptorProvider = { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true };
export const httpXsrfInterceptorProvider = { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true };
export const httpUserIdInterceptorProvider = { provide: HTTP_INTERCEPTORS, useClass: HttpUserIdInterceptor, multi: true };

@NgModule({
  declarations: [AppComponent, UnauthorizedComponent, MenuPageComponent, InterviewThanksComponent],
  imports: [
    BrowserModule,
    AppTranslationModule,
    DynamicConfigModule.forRoot(),
    ToastrModule.forRoot({ extendedTimeOut: 2000, timeOut: 8000, preventDuplicates: true }),
    SharedModule,
    BsDropdownModule,
    NgOptionHighlightModule,
    BsDropdownModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PageNotFoundModule,
    SettingsModule,
    Angulartics2Module.forRoot(),
    LoggingModule,
    BsDatepickerModule.forRoot(),
    HttpClientXsrfModule.withOptions({
      cookieName: 'ACCOUNT-XSRF-TOKEN',
      headerName: 'X-ACCOUNT-XSRF-TOKEN',
    }),
  ],
  providers: [
    httpInterceptorProvider,
    authInterceptorProvider,
    httpXsrfInterceptorProvider,
    httpUserIdInterceptorProvider,
    CookieService,
    { provide: ErrorHandler, useClass: ErrorLoggerHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
