import { NgModule } from '@angular/core';
import { ModalModule as BsModalModule } from 'ngx-bootstrap/modal';
import { ModalService } from './modal.service';
import { ngfModule } from 'angular-file';
import { ImageResizerModalModule } from '../image-resizer-modal/image-resizer-modal.module';

@NgModule({
  imports: [BsModalModule.forRoot(), ngfModule, ImageResizerModalModule],
  providers: [ModalService],
})
export class ModalModule {}
