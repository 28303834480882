<div class="modal-header mb-3 text-center" data-cy="DIV-c77e">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-0fcf">
    <span aria-hidden="true" data-cy="SPAN-a3d0">&times;</span>
  </button>
  <div class="modal-title w-100" data-cy="DIV-5556">
    <p       class="h3 text-center"
      [translate]="'MEDIATHEQUE.SUPPRIMER_MEDIA'"
    ></p>
  </div>
</div>
<div class="modal-body text-center" data-cy="DIV-4679">
  <p     class="text-secondary"
    *ngIf="campaignCount == 0 && questionCount == 0"
    translate
    [translateParams]="{VAR_SELECT:mediaType}"
  >MEDIATHEQUE.SUPPRIMER_MEDIA_IMAGE_NON_UTILISE</p>
  <p     class="text-secondary"
    *ngIf="questionCount > 0"
    translate
    [translateParams]="{count: questionCount, VAR_SELECT: mediaType, INTERPOLATION: questionCount}"
  >MEDIATHEQUE.SUPPRIMER_MEDIA_VIDEO_UTILISE_QUESTIONNAIRE</p>
  <p     class="text-secondary"
    *ngIf="campaignCount > 0"
    translate
    [translateParams]="{count: campaignCount, VAR_SELECT: mediaType, INTERPOLATION:campaignCount}"
  >MEDIATHEQUE.SUPPRIMER_MEDIA_VIDEO_UTILISE_CAMPAGNE</p>
  <p     class="text-secondary"
    [translate]="'MEDIATHEQUE.SUPPRIMER_MEDIA_CONFIRMATION'"
  ></p>
</div>
<div class="modal-footer flex-column" data-cy="DIV-879c">
  <p>
    <button class="ms-3 btn" ngClass="{{ btnConfirmClass }}" (click)="this.deleteConfirmClick()" [translate]="'MEDIATHEQUE.SUPPRIMER_MEDIA'" data-cy="BUTTON-eac7"></button>
  </p>
  <p>
    <a href="javascript:void(0)" class="link" (click)="cancel()" [translate]="'MEDIATHEQUE.SUPPRIMER_MEDIA_ANNULER'" data-cy="A-754a"></a>
  </p>
</div>
