import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-flex-fill-container',
  templateUrl: './flex-fill-container.component.html',
  styleUrls: ['./flex-fill-container.component.scss'],
})
export class FlexFillContainerComponent {
  @HostBinding('class')
  class: string = 'flex-fill align-self-stretch overflow-auto';

  @Input()
  containerClasses = '';
}
