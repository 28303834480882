import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from '../settings-routing.module';
import { MediaResolverService } from './media-resolver.service';
import { SharedModule } from '../../shared/shared.module';
import { MediasDeleteModalComponent } from './medias-delete-modal/medias-delete-modal.component';

@NgModule({
    imports: [CommonModule, SharedModule, SettingsRoutingModule],
    declarations: [MediasDeleteModalComponent],
    providers: [MediaResolverService]
})
export class MediasModule {}
