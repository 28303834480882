import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, of } from 'rxjs';
import { SharingObjectType } from 'src/app/shared/sharing/entities/sharing-object-type.enum';

import { Config } from '../config/config';
import { Sharing } from './entities/sharing';

@Injectable({
  providedIn: 'root',
})
export class SharingService {
  private config: Config;

  constructor(private http: HttpClient, configService: DynamicConfigService) {
    this.config = configService.get<Config>();
  }

  public share(sharings: Partial<Sharing>[]): Observable<void>;
  public share(sharings: Partial<Sharing>): Observable<void>;
  share(sharings: Partial<Sharing> | Partial<Sharing>[] = []): Observable<void> {
    sharings = Array.isArray(sharings) ? sharings : [sharings];
    if (!sharings.length) {
      return of(undefined);
    }
    return combineLatest(sharings.map(s => this.http.post<void>(`${this.config.apiUrl}/sharing`, s))).pipe(map(() => null));
  }

  public delete(type: SharingObjectType, id: number | string): Observable<Sharing[]> {
    return this.http.delete<Sharing[]>(`${this.config.apiUrl}/sharing`, {
      params: { type, id: id.toString() },
    });
  }

  public get(type: SharingObjectType, id: number | string): Observable<Sharing[]> {
    return this.http.get<Sharing[]>(`${this.config.apiUrl}/sharing`, {
      params: { type, id: id.toString() },
    });
  }
}
