import DOMPurify from 'dompurify';
import unescape from 'lodash/unescape';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConditionalValidator } from 'src/app/shared/form-controls/conditional.validator';
import { RolesEnum } from 'src/app/auth/roles.enum';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/auth/user';

import { BreadcrumbItem } from '../../../layout/common-footer/breadcrumb-item';
import { CompanyTeamService } from '../../../../panel/companies/company-teams/services/company-team.service';
import { EmailTemplate } from '../entities/email-template';
import { EmailTemplateRepository } from '../email-template.repository';
import { EmailType } from '../entities/email-type';
import { EmailTypeEnum } from '../entities/email-type.enum';
import { EmailTypeService } from '../email-type.service';
import { KeywordHighlightPipe } from '../keyword-highlight/keyword-highlight.pipe';
import { Language } from '../../languages/language';
import { LanguageService } from '../../languages/language.service';
import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';
import { RecruitmentMode } from '../../recruitments/recruitment-mode/recruitment-mode';
import { RecruitmentModeService } from '../../recruitments/recruitment-mode/recruitment-mode.service';
import { Sharing } from '../../../sharing/entities/sharing';
import { SharingRecipientType } from '../../../sharing/entities/sharing-recipient-type.enum';
import { SubmittableFormGroup } from '../../../form-controls/submittable-form-group';

@Component({
  selector: 'app-details-mail-modal',
  templateUrl: './emailing-details-modal.component.html',
  styleUrls: ['./emailing-details-modal.component.scss'],
})
export class EmailingDetailsModalComponent extends ModalBaseComponent<EmailTemplate> implements OnInit {
  constructor(
    bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private recruitmentModeService: RecruitmentModeService,
    private translate: TranslateService,
    private emailTypeService: EmailTypeService,
    private languageService: LanguageService,
    private emailTemplateRepository: EmailTemplateRepository,
    private companyTeamService: CompanyTeamService,
  ) {
    super(bsModalRef);
  }

  public email!: EmailTemplate;
  public emailTemplateHtml: string;
  public me!: User;
  public companyId!: number;
  public isCompanyAdministrator!: boolean;
  public filteredLanguages: Array<Language> = [];
  public modes: Array<RecruitmentMode> = [];
  public baseTypes: Array<EmailType> = [];
  public customTypes: Array<EmailType> = [];

  public emailStep1Group!: SubmittableFormGroup;
  public emailStep2Group!: UntypedFormGroup;
  public teams: Array<{ value: number; label: string }> = [];

  public withSharings: boolean = false;

  public step: number = 1;

  public showEditSubject: boolean = false;
  public showEditContent: boolean = false;
  public showMessageError: boolean = false;
  public messageError: string = this.translate.instant('EMAILING.ERROR_MESSAGE');
  public contentEmpty: string = this.translate.instant('MAIL_TEMPLATE.CONTENT.EMPTY_STATE');
  public hasHtml: boolean = false;
  public colorText: string = 'deepskyblue';

  public hasType: boolean = true;

  public tagsList = {
    recruitment_title: this.translate.instant('MAIL_TAG.RECRUITMENT_TITLE'),
    end_date: this.translate.instant('MAIL_TAG.END_DATE'),
    recruiter_fonction: this.translate.instant('MAIL_TAG.RECRUITER_FONCTION'),
    company_name: this.translate.instant('MAIL_TAG.COMPANY_NAME'),
    gender: this.translate.instant('MAIL_TAG.GENDER'),
    firstname: this.translate.instant('MAIL_TAG.FIRSTNAME'),
    lastname: this.translate.instant('MAIL_TAG.LASTNAME'),
    questions_to_prepare: this.translate.instant('MAIL_TAG.QUESTIONS_TO_PREPARE'),
    live_date: this.translate.instant('MAIL_TAG.LIVE_DATE'),
  };
  public tags: { label: string; value: string }[] = [];

  public breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem(this.translate.instant('EMAILING.TITRE_MODAL_ADD_EMAIL'), '', true),
    new BreadcrumbItem(this.translate.instant('COMMON.EDITION'), '', false),
  ];

  private keywordHighlightPipe: KeywordHighlightPipe = new KeywordHighlightPipe();

  @ViewChild('innerhtml') innerhtml!: ElementRef;
  private baliseCustomText: string = '[email-custom-text]';
  private classEltContentHTML: string = 'editor-content d-block';

  private eltOverlay: string = '<span class="edit-email-overlay"><i class="material-icons text-success w-100">create</i></span>';

  private static transformStringToHTML(str: string): HTMLElement {
    return new DOMParser().parseFromString(str, 'text/html').body;
  }

  ngOnInit(): void {
    this.me = this.route.children[0].snapshot.data.me;

    this.companyId = this.companyId || this.me.companyId;
    this.isCompanyAdministrator =
      this.me.roles.some(r => r === RolesEnum.csm || r === RolesEnum.admin) ||
      this.me.administratedCompanies.some(c => c.id == this.companyId);

    if (!this.email) {
      this.email = new EmailTemplate({
        language: 'fr',
        type: EmailTypeEnum.invitation,
        subject: '',
        content: '',
      });
    }

    this.initArrayTags();
    if (this.step == 2) {
      this.hasHtml = true;
      this.updateBreadcrumbItems();
      this.initFormStep2();
      this.emailTemplateRepository.preview(this.email.id, null, null).subscribe({
        next: (html: string) => this.initHTML(html),
      });
      return;
    }

    this.modes = this.recruitmentModeService.getAll().filter(m => this.me.options.recruitmentModes?.indexOf(m.value) > -1);
    this.initFormStep1();
    this.initTypeEmail();
    this.filterLanguages();
    if (this.withSharings) {
      this.initTeams();
    }
  }

  public save(action: string): void {
    if (action === 'subject') {
      const cleanSubject = DOMPurify.sanitize(unescape(this.emailStep2Group.get(action).value));
      this.email.subject = cleanSubject?.replace(/(<([^>]+)>)/gi, '');
      this.showEditSubject = false;
    } else if (action === 'content') {
      this.showEditContent = false;
      const cleanContent = DOMPurify.sanitize(unescape(this.emailStep2Group.get(action).value));
      this.email.content = this.keywordHighlightPipe.serialize(cleanContent, this.colorText);
      if (this.hasHtml) {
        this.resetHTML();
      }
    }
    if (this.email.subject && this.email.content && this.email.subject !== '<br/>' && this.email.content !== '<br/>') {
      this.showMessageError = false;
    }
  }

  public saveSharingTeams(): void {
    const sharings = this.emailStep1Group.get('sharings').value;
    if (sharings && sharings.length) {
      this.email.sharings = sharings.map(
        s =>
          ({
            recipientId: s,
            recipientType: SharingRecipientType.team,
          }) as Sharing,
      );
    } else {
      this.email.sharings = [
        {
          recipientType: SharingRecipientType.company,
          recipientId: this.companyId.toString(),
        } as Sharing,
      ];
    }
  }

  public isDisabled(): boolean {
    return this.emailStep2Group && (this.emailStep2Group.invalid || this.email.content === '<br/>' || this.email.subject === '<br/>');
  }

  private resetHTML(): void {
    const newContentWithBadge = this.keywordHighlightPipe.transform(this.email.content, this.tagsList, this.colorText, true);
    const contentHTML = EmailingDetailsModalComponent.transformStringToHTML(this.emailTemplateHtml);
    contentHTML.getElementsByClassName(this.classEltContentHTML)[0].innerHTML = newContentWithBadge + this.eltOverlay;
    this.addActionOnOverlay(contentHTML);
    setTimeout(() => {
      this.innerhtml.nativeElement.append(contentHTML);
    }, 100);
  }

  private initArrayTags(): void {
    this.tags = Object.keys(this.tagsList).map(k => {
      return { value: k, label: this.tagsList[k] };
    });
  }

  private initTeams(): void {
    this.companyTeamService.getAll(this.companyId).subscribe(teams => {
      this.teams = teams.data.map(t => ({ value: t.id, label: t.name }));
    });
  }

  private initTypeEmail(): void {
    this.emailTypeService.getWithStatuses().subscribe({
      next: (types: Array<EmailType>) => {
        types.forEach((type: EmailType) => {
          Number.isInteger(+type.value) ? this.customTypes.push(type) : this.baseTypes.push(type);
        });
        this.baseTypes.push({ value: 'trigger', label: this.translate.instant('EMAIL_TYPE.TRIGGER') });
      },
      error: (err: unknown) => console.error(err),
    });
  }

  public reset(action: string): void {
    if (action === 'subject') {
      this.emailStep2Group.patchValue({
        subject: this.keywordHighlightPipe.transform(this.email.subject || '', this.tagsList, this.colorText),
      });
      this.showEditSubject = false;
    } else if (action === 'content') {
      this.showEditContent = false;
      this.emailStep2Group.patchValue({
        content: this.keywordHighlightPipe.transform(this.email.content || '', this.tagsList, this.colorText),
      });
      this.resetHTML();
    }
  }

  public initFormStep1(): void {
    this.emailStep1Group = new SubmittableFormGroup({
      title: new UntypedFormControl(this.email.title, Validators.required),
      type: new UntypedFormControl(this.email.type),
      language: new UntypedFormControl(this.email.language),
      mode: new UntypedFormControl(this.email.mode),
      sharings: new UntypedFormControl(
        this.email.sharings?.filter(s => s.recipientType == SharingRecipientType.team).map(s => +s.recipientId) || [],
        this.withSharings && !this.isCompanyAdministrator ? Validators.required : undefined,
      ),
      statusId: new UntypedFormControl(
        this.email.statusId?.toString(),
        ConditionalValidator(g => g.get('type').value === 'trigger', Validators.required),
      ),
    });
    if (this.hasType) {
      this.emailStep1Group.addControl('type', new UntypedFormControl(this.email.type));
    }
    this.emailStep1Group.get('mode').valueChanges.subscribe(value => {
      const selectedMode = this.modes.filter(m => m.value === value)[0];
      this.filteredLanguages = this.languageService.translate(selectedMode?.languages);
    });
  }

  public initFormStep2(): void {
    const content = this.keywordHighlightPipe.transform(this.email.content || '', this.tagsList, this.colorText);
    const subject = this.keywordHighlightPipe.transform(this.email.subject || '', this.tagsList, this.colorText);

    this.emailStep2Group = this.formBuilder.group({
      subject: new UntypedFormControl({ value: subject, disabled: false }, Validators.required),
      content: new UntypedFormControl({ value: content, disabled: false }, Validators.required),
    });
  }

  private filterLanguages(): void {
    let selectedMode = this.modes.filter(m => m.value === this.email.mode)[0];
    if (!selectedMode) {
      selectedMode = this.modes[0];
      this.emailStep1Group.patchValue({ mode: selectedMode.value });
    }
    this.filteredLanguages = this.languageService.translate(selectedMode.languages);
  }

  private updateBreadcrumbItems(): void {
    this.breadcrumbItems.forEach(item => (item.isActive = !item.isActive));
  }

  public nextStep(): void {
    this.emailStep1Group.markAsSubmitted();
    if (this.emailStep1Group.invalid) {
      return;
    }
    Object.assign(this.email, this.emailStep1Group.value);
    this.updateBreadcrumbItems();

    this.emailTemplateRepository.getAll({ type: this.email.type, language: this.email.language, mode: this.email.mode }).subscribe({
      next: result => {
        const emailTemplates = result.data;
        const emails = (emailTemplates || []).filter((mail: EmailTemplate) => mail.isDefault && mail.type === this.email.type);
        this.step = this.step + 1;
        if (emails.length > 0) {
          const emailTemplate = emails[0];
          this.email.subject = this.email.subject || emailTemplate.subject;
          this.email.content = this.email.content || emailTemplate.content;
          this.hasHtml = true;
          this.emailTemplateRepository.preview(emailTemplate.id, this.me.id).subscribe({
            next: (html: string) => this.initHTML(html),
          });
        }
        this.initFormStep2();
      },
    });
  }

  public saveForm(): void {
    this.save('subject');
    this.save('content');
    if (this.withSharings) {
      this.saveSharingTeams();
    }
    if (this.isDisabled()) {
      this.showMessageError = true;
      return;
    }
    this.onClose.next(this.email);
  }

  private initHTML(emailTemplateHtml: string): void {
    const stringReplace: string = `<span class="${this.classEltContentHTML}" style='position: relative'>${this.email.content}${this.eltOverlay}</span>`;
    let val: string = (emailTemplateHtml || '').includes(this.baliseCustomText)
      ? emailTemplateHtml.replace(this.baliseCustomText, stringReplace)
      : stringReplace;
    this.hasHtml = !!emailTemplateHtml;
    val = this.keywordHighlightPipe.transform(val, this.tagsList, this.colorText, true);
    const html: HTMLElement = this.addActionOnOverlay(EmailingDetailsModalComponent.transformStringToHTML(val));
    this.emailTemplateHtml = html.outerHTML;
    setTimeout(() => this.innerhtml.nativeElement.append(html), 100);
  }

  public addActionOnOverlay(html: HTMLElement): HTMLElement {
    const listEltEditor = html.getElementsByClassName('edit-email-overlay');
    if (listEltEditor && listEltEditor[0]) {
      listEltEditor[0].addEventListener('click', () => (this.showEditContent = true));
    }
    return html;
  }
}
