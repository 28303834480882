<button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-b9b3">
  <span aria-hidden="true" data-cy="SPAN-6025">&times;</span>
</button>
<div class="modal-title mt-5 mb-3" data-cy="DIV-e5bf">
  <p class="h3 mx-auto">{{ resource.title }}</p>
</div>
<div *ngIf="resource?.type?.indexOf('image') >= 0" data-cy="DIV-a1f3"><img     [vtsrc]="resource?.url"
    class="mw-100 p-4"
    appImageLoader
  > <img src="" ></div>
<div class="m-4" data-cy="DIV-b592">
  <div class="row g-2" data-cy="DIV-7222">
    <cc-video-player       *ngIf="resource?.type?.indexOf('video') >= 0"
      class="offset-1 col-10 rounded"
      [options]="{
        fluid: false,
        poster: resource?.previewUrl,
        autoplay: true,
        sources: [
          {
            src: resource?.url,
            type: 'video/mp4'
          }
        ]
      }"
    ></cc-video-player>
  </div>
</div>
<div class="mt-5" data-cy="DIV-207d"></div>
