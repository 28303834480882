import { ActivatedRoute } from '@angular/router';
import { Directive, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../layout/modal/modal.service';
import { CcTableComponent, TableAction, TableColumn } from '@cleverconnect/ngx-bootstrap-table';
import { User } from '../../../auth/user';
import { getCcTableLabels } from '../../table/labels';
import { RemoveSharedCandidatComponent } from '../remove-shared-candidat/remove-shared-candidat.component';
import { Company } from '../../../panel/companies/company';

@Directive()
export class ListCandidatsComponent implements OnInit {
  @ViewChild('actionsTemplate', { static: true }) public actionsTemplate: TemplateRef<any>;
  @ViewChild('stateTemplate', { static: true }) public stateTemplate: TemplateRef<any>;
  @ViewChild('ccTable', { static: true }) public tableUsers: CcTableComponent<User>;
  public columns: TableColumn[];
  public actions: TableAction<User>[];
  public globalSearch: string;
  public usersApiUrl: string;
  public selectedUsers: User[] = [];
  public nbUsers: number;
  public tableLabels = getCcTableLabels(this.translate);

  public me = this.activeRoute.snapshot.data.me;
  public managerSelected: User = this.activeRoute.snapshot.data.managerSelected;
  public titleTab: string;
  public get filter(): { s: string } {
    const searchFilters: any = { $and: [] };

    if (this.globalSearch) {
      searchFilters.$and = this.globalSearch.split(' ').map(s => ({
        $or: [{ id: { $eq: s } }, { email: { $contL: s } }, { firstname: { $contL: s } }, { lastname: { $contL: s } }],
      }));
    }
    return {
      s: JSON.stringify(searchFilters),
    };
  }

  protected company: Company;

  constructor(
    protected readonly datePipe: DatePipe,
    protected readonly translate: TranslateService,
    protected readonly activeRoute: ActivatedRoute,
    protected readonly modalService: ModalService
  ) {}

  protected setTitleTab(): void {
    const val =
      this.managerSelected.firstname && this.managerSelected.lastname
        ? `${this.managerSelected.firstname} ${this.managerSelected.lastname}`
        : this.managerSelected.email;
    this.titleTab = this.translate.instant('SETTINGS.MANAGERS.CANDIDATS.TITLE_TAB', { name: val });
  }

  public ngOnInit(): void {
    this.actions = [
      {
        title: this.translate.instant('SETTINGS.MANAGERS.CANDIDATS.ACTIONS.REMOVE_SHARE'),
        action: 'remove_share',
        icon: "<i class='material-icons md-24'>person_remove</i>",
      },
    ];

    this.columns = [
      {
        title: this.translate.instant('PANEL.USERS.LIST.NOM'),
        field: 'lastname',
        sortable: false,
        formatter: (d, l) => ` ${l.lastname || ''} ${l.firstname || ''}`,
      },
      {
        title: this.translate.instant('SETTINGS.MANAGERS.CANDIDATS.TAB_NAME_CAMPAGNE'),
        field: 'title',
        sortable: false,
        classesTd: ' text-break',
      },
      {
        title: this.translate.instant('SETTINGS.MANAGERS.CANDIDATS.TAB_SHARED_DATE'),
        field: 'createdAt',
        sortable: false,
        classesHeaderTh: 'd-none d-md-table-cell',
        classesTd: 'd-none d-md-table-cell',
        formatter: d => this.datePipe.transform(d, 'shortDate'),
      },
      {
        title: this.translate.instant('PANEL.USERS.LIST.EMAIL'),
        field: 'email',
        sortable: false,
        formatter: (d, l) => `${l.email || ''}`,
        classesTd: ' text-break',
      },
      { title: '', classesTd: 'p-0', template: this.actionsTemplate },
    ];
  }

  private refreshTable(): void {
    this.tableUsers.uncheckAll();
    this.tableUsers.reload();
  }

  public openRemoveShareModal(items): void {
    const modalComponent = this.modalService.show(RemoveSharedCandidatComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered',
      initialState: {
        selectedShared: items,
        idCompany: this.company?.id
      },
    }).content as RemoveSharedCandidatComponent;

    modalComponent.onClose.subscribe(() => {
      this.refreshTable();
    });
  }
}
