import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ElementRef } from '@angular/core';

export class SubmittableFormGroup extends UntypedFormGroup {
  public isSubmitted: boolean = false;

  public isInvalid(input: string | (string | number)[] | AbstractControl, validation?: string) {
    const currentInput = input instanceof AbstractControl ? input : this.get(input);
    if (!validation) {
      return currentInput.invalid && this.isSubmitted;
    }

    return currentInput.hasError(validation) && this.isSubmitted;
  }

  public markAsSubmitted(el?: ElementRef) {
    this.isSubmitted = true;

    if (el && this.invalid) {
      setTimeout(() => {
        const target = el.nativeElement.querySelector(
          'input.ng-invalid, select.ng-invalid, .ng-select.ng-invalid input,input.is-invalid, select.is-invalid, .ng-select.is-invalid input',
        );

        if (target) {
          target.focus();
        }
      });
    }
  }

  public reset(
    value?: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): void {
    this.isSubmitted = false;
    super.reset(value, options);
  }
}
