import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { SharingService } from 'src/app/shared/sharing/sharing.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { Config } from '../../config/config';
import { EntitySharings } from '../../sharing/entities/with-sharing.entity';
import { Form } from './entities/form';
import { FormSearch } from './entities/form-search';
import { FormTypeEnum } from './entities/form-type.enum';
import { IsSharedEntity } from '../../sharing/entities/is-shared-entity.interface';
import { SharingObjectType } from '../../sharing/entities/sharing-object-type.enum';
import { createHttpParams } from '../../requests/http-params';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private config: Config;

  constructor(
    private http: HttpClient,
    configService: DynamicConfigService,
    private translate: TranslateService,
    private sharingService: SharingService
  ) {
    this.config = configService.get<Config>();
  }

  public get(formId: number, formSearch?: FormSearch, withSharings = false): Observable<Form & Partial<EntitySharings>> {
    if (withSharings) {
      return forkJoin(
        this.sharingService.get(SharingObjectType.form, formId.toString()),
        this.http.get<Form>(`${this.config.apiUrl}/forms/${formId}`, { params: { ...formSearch } })
      ).pipe(
        map(data => {
          const res: Form & Partial<EntitySharings> = {
            ...data[1],
            sharings: data[0],
          };
          return res;
        })
      );
    }
    return this.http.get<Form>(`${this.config.apiUrl}/forms/${formId}`, { params: { ...formSearch } });
  }

  public getAll(options: { withArchived?: boolean; withQuestions?: boolean; userId?: string } = {}) {
    return this.http
      .get<[(Form & IsSharedEntity)[], number]>(`${this.config.apiUrl}/forms`, {
        params: createHttpParams(options),
      })
      .pipe(map(f => f[0]));
  }

  public getAllAdministrated(companyId: number, options: { withArchived?: boolean; withQuestions?: boolean; userId?: string } = {}) {
    return this.http
      .get<[(Form & EntitySharings)[], number]>(`${this.config.apiUrl}/panel/companies/${companyId}/forms`, {
        params: createHttpParams(options),
      })
      .pipe(map(f => f[0]));
  }

  public getAllTypes() {
    return Object.keys(FormTypeEnum)
      .map(k => FormTypeEnum[k])
      .map((k: string) => {
        let translationKey = `FORM_TYPE.${k}`;
        translationKey = translationKey.toUpperCase().replace('-', '_');
        return { value: k, label: this.translate.instant(translationKey) };
      });
  }

  public save(form: Form) {
    if (form.id) {
      return this.http.put<Form>(`${this.config.apiUrl}/forms/${form.id}`, form, { headers: this.headers });
    } else {
      return this.http.post<Form>(`${this.config.apiUrl}/forms`, form, { headers: this.headers });
    }
  }

  public archive(formId: number) {
    return this.http.get<Form>(`${this.config.apiUrl}/forms/${formId}/archive`, { headers: this.headers });
  }
}
