import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { AuthUserResolver } from './auth/auth-user.resolver';
import { InterviewThanksComponent } from './interviews/interview-thanks/interview-thanks.component';
import { MenuPageComponent } from './menu-page/menu-page.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const protectedAppModules: Routes = [
  {
    path: 'interviews',
    loadChildren: () => import('./interviews/interviews.module').then(m => m.InterviewsModule),
  },
  {
    path: '',
    component: MenuPageComponent,
    children: [
      {
        path: 'recruitments',
        loadChildren: () => import('./recruitments/recruitments.module').then(m => m.RecruitmentsModule),
      },
      {
        path: 'forms',
        loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'panel',
        loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule),
      },
      //TODO: Acces to user statistics
      /* {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
      },*/
    ],
  },
];

const appRoutes: Routes = [
  { path: '', redirectTo: '404', pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'external/interviews/thanks',
    component: InterviewThanksComponent,
  },
  { path: 'external/interviews/shared', redirectTo: 'share' },
  { path: 'external/interviews', redirectTo: 'interviews' },
  {
    path: 'share',
    loadChildren: () => import('./share/share.module').then(m => m.ShareModule),
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    resolve: { me: AuthUserResolver },
    children: protectedAppModules,
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always',
      enableTracing: false,
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule, HttpClientModule],
})
export class AppRoutingModule {}
