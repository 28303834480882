<div class="d-flex flex-column h-100 w-100" data-cy="DIV-f22c">
  <app-common-header title="Mes médias" [title]="'MEDIATHEQUE.MES_MEDIAS' | translate"></app-common-header>
  <app-flex-fill-container scrollSpyElement="flex-fill-container">
    <div class="col-12 row g-2 mt-0 mt-sm-3 mb-2" data-cy="DIV-1f0c">
      <div class="col-12 col-md-8 ps-0">
        <div class="input-group" data-cy="DIV-cd60">
          <div class="input-group-text bg-lightest-gray" data-cy="DIV-0727">
            <i class="material-icons" data-cy="I-1093">search</i>
          </div>
          <input
            [(ngModel)]="globalSearch"
            class="form-control"
            placeholder="Rechercher un media"
            [placeholder]="'SETTINGS.MEDIAS.USER.FILTER.NAME' | translate"
            data-cy="INPUT-70eb"
          />
        </div>
      </div>
      <div class="col-12 col-md-4 text-start text-md-center" data-cy="DIV-353c">
        <button
          type="button"
          class="btn btn-success"
          (click)="addMedia()"
          [translate]="'SETTINGS.MEDIAS.USER.BUTTON.ADD'"
          data-cy="BUTTON-9be8"
        ></button>
      </div>
    </div>
    <div class="col-12 col-sm-7 row g-2" data-cy="DIV-9a83">
      <div class="mb-3 col-md-6 ps-0" data-cy="DIV-88b9">
        <label
          class="text-secondary form-label"
          for="typeFilter"
          [translate]="'SETTINGS.MEDIAS.USER.FILTER.TYPE'"
          data-cy="LABEL-740b"
        ></label>
        <ng-select
          name="typeFilter"
          [(ngModel)]="typeFilter"
          [items]="resourcesLabel"
          bindValue="value"
          [searchable]="false"
          [clearable]="true"
          placeholder="Tous"
          [placeholder]="'SETTINGS.MEDIAS.USER.FILTER.PLACEHOLDER.TYPE' | translate"
          groupBy="typeI18n"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <span class="align-middle ms-2" data-cy="SPAN-a215"> {{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
      <div class="mb-3 col-md-6" data-cy="DIV-f4ec">
        <label
          class="text-secondary form-label"
          for="languageFilter"
          [translate]="'SETTINGS.MEDIAS.USER.FILTER.LANGUE'"
          data-cy="LABEL-f649"
        ></label>
        <ng-select
          name="languageFilter"
          [items]="languages"
          [(ngModel)]="languageFilter"
          [searchable]="false"
          bindValue="value"
          [clearable]="true"
          placeholder="Tous"
          [placeholder]="'SETTINGS.MEDIAS.USER.FILTER.PLACEHOLDER.LANGUAGE' | translate"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <img height="15" width="22" src="/assets/images/flags/{{ item.value }}.png" [alt]="item.value" />
            <span class="align-middle" data-cy="SPAN-c4d6"> {{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div data-cy="DIV-d99c">
      <h4 class="pt-1 pb-1 mb-0" data-cy="H4-0034">
        {{ 'SETTINGS.MEDIAS.USER.TITLE' | translate }}&nbsp;
        <span *ngIf="nbUserMedia + nbSharedMedia >= 0" class="badge rounded-pill bg-primary py-1" data-cy="SPAN-fba2">
          {{ nbUserMedia + nbSharedMedia }}
        </span>
      </h4>
    </div>

    <tabset type="pills mt-4">
      <tab>
        <ng-template tabHeading>
          {{ 'SETTINGS.MEDIAS.USER.TABLE.USERS.TITLE' | translate }}&nbsp;
          <span class="badge bg-primary rounded-pill py-1" data-cy="SPAN-4793">{{ nbUserMedia }}</span>
        </ng-template>
        <div class="mb-3" data-cy="DIV-bd87"></div>
        <cc-table
          class="last-col-fix align-middle text-nowrap"
          [url]="resourceUrl"
          (dataUpdated)="nbUserMedia = $event.total"
          tableClasses="table-hover align-middle text-nowrap"
          [columns]="columns"
          [debounceTime]="500"
          [search]="filter"
          (clickLine)="editModal($event, false)"
          [labels]="tableLabels"
          #ccTableUser
        ></cc-table>
      </tab>
      <tab>
        <ng-template tabHeading>
          {{ 'SETTINGS.MEDIAS.USER.TABLE.ADMINISTRATORS.TITLE' | translate }}
          &nbsp;
          <span class="badge bg-primary rounded-pill py-1" data-cy="SPAN-a07a">{{ nbSharedMedia }}</span>
        </ng-template>
        <div class="mb-3" data-cy="DIV-a3ae"></div>
        <cc-table
          [url]="resourceUrl"
          (dataUpdated)="nbSharedMedia = $event.total"
          tableClasses="table-hover align-middle text-nowrap"
          [debounceTime]="500"
          [columns]="columnsSharing"
          [search]="filterSharing"
          [labels]="tableLabels"
          #ccTableUser
        ></cc-table>
      </tab>
    </tabset>
  </app-flex-fill-container>
</div>

<ng-template #avatarTemplate let-formatted="formatted">
  <cc-user-thumbnail [thumbnail]="formatted.ownerId"></cc-user-thumbnail>
</ng-template>

<ng-template #languageTemplate let-line="line" class="my-auto">
  <img src="//visiotalent-emails.s3.amazonaws.com/flags/{{ line.language }}.png" width="26" [alt]="line.language" />
</ng-template>

<ng-template #previewTitleTemplate let-line="line">
  <div class="row g-0" data-cy="DIV-9d86">
    <app-resource-viewer
      (click)="$event.stopPropagation()"
      class="col-3 rounded mx-auto d-flex justify-content-center"
      style="height: 40px"
      [resource]="line"
      [mini]="true"
    ></app-resource-viewer>
    <span class="col-12 col-sm-7 ps-2 my-auto" style="word-wrap: break-word" data-cy="SPAN-ec12"> {{ line.title }}</span>
  </div>
</ng-template>

<ng-template #typeTemplate let-formatted="formatted">
  <div class="badge me-1 fw-normal" [ngClass]="formatted.type.color" data-cy="DIV-b4d4">{{ formatted.type.label }}</div>
</ng-template>

<ng-template #teamsListTemplate let-formatted="formatted">
  <div class="badge image-badge fw-normal" data-cy="DIV-63fa">{{ formatted.sharing.title }}</div>
</ng-template>

<ng-template #actionsTemplate let-line="line">
  <div class="position-relative text-center" dropdown container="body" placement="bottom right" data-cy="DIV-ae1b">
    <a
      dropdownToggle
      href="javascript:void(0)"
      (click)="$event.stopPropagation()"
      class="btn btn-link link-secondary text-decoration-none p-1"
      data-cy="A-8ba9"
    >
      <i class="material-icons md-24" data-cy="I-5199">more_vert</i>
    </a>
    <div class="bg-white" data-cy="DIV-f01f">
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
        <li *ngFor="let action of actions">
          <a
            class="dropdown-item text-secondary text-decoration-none"
            href="javascript:void(0)"
            (click)="onUserAction(action.action, line)"
            data-cy="A-262c"
            ><span [innerHTML]="action.icon" data-cy="SPAN-d60b"></span>&nbsp;<span class="material-icons-text" data-cy="SPAN-6a54">{{
              action.title
            }}</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</ng-template>
