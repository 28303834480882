import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RouterModule } from '@angular/router';
import { ToggleSwitchModule } from '@cleverconnect/ngx-toggle-switch';
import { TranslateModule } from '@ngx-translate/core';

import { AnalyticsComponent } from './analytics/analytics.component';
import { BusinessModule } from './business/business.module';
import { LayoutModule } from './layout/layout.module';
import { SharingsAsStringPipe } from './pipes/sharings-as-string.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ButtonsModule,
    ReactiveFormsModule,
    FormsModule,
    CollapseModule,
    ToggleSwitchModule,
    BusinessModule,
    LayoutModule,
    Angular2PromiseButtonModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    TranslateModule,
  ],
  exports: [
    BsDatepickerModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ButtonsModule,
    ReactiveFormsModule,
    FormsModule,
    CollapseModule,
    ToggleSwitchModule,
    BusinessModule,
    LayoutModule,
    Angular2PromiseButtonModule,
    AnalyticsComponent,
    SharingsAsStringPipe,
    TranslateModule,
  ],
  declarations: [AnalyticsComponent, SharingsAsStringPipe],
})
export class SharedModule {}
