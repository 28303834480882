<form [formGroup]="filterGroup" autocomplete="off">
  <div class="col-12 row g-2 mt-0 mt-sm-3 mb-2 align-items-end" data-cy="DIV-60c5">
    <div class="col-12 ps-0 {{ panel ? 'col-md-5' : 'col-md-8'}}" data-cy="DIV-65bd">
      <div class="input-group" data-cy="DIV-eb43">
        <div class="input-group-text bg-lightest-gray" data-cy="DIV-8bf4">
          <i class="material-icons" data-cy="I-2a39">search</i>
        </div>
        <input
          data-cy="INPUT-f18d"
          type="text"
          class="form-control"
          id="search-email"
          placeholder="Rechercher un email"
          [placeholder]="'EMAILING.PLACEHOLDER_RECHERCHER_EMAIL' | translate"
          (input)="filterByText($event)"
        />
      </div>
    </div>
    <div *ngIf="panel" class="col-12 col-md-4 order-2 d-flex">
      <app-select-users-teams
        [isLabelSecondary]="true"
        class="my-auto w-100"
        [users]="users"
        [teams]="teams"
        (select)="filterByUserOrTeam($event?.[0])"
      ></app-select-users-teams>
    </div>
    <div class="col-12 col-md-3 order-2 d-flex" data-cy="DIV-7876">
      <button
        class="btn btn-panel mx-auto"
        [ngClass]="panel ? 'btn-panel' : 'btn-success'"
        type="button"
        [translate]="'EMAILING.CREER_EMAIL'"
        (click)="createEmailEmitter.emit()"
        data-cy="BUTTON-dfd5"
      ></button>
    </div>
    <div class="col-12 col-md-4 order-3" [hidden]="modes.length < 2" data-cy="DIV-3750">
      <div class="mb-3" data-cy="DIV-3f81">
        <label class="text-secondary form-label" for="filterMode" [translate]="'EMAILING.LABEL_MODE'" data-cy="LABEL-c9f9"></label>
        <ng-select
          labelForId="filterMode"
          [items]="modes"
          formControlName="mode"
          [searchable]="false"
          bindValue="value"
          (change)="filterByMode($event)"
          placeholder="{{ 'EMAILING.FILTRER_LANGUE_TOUS' | translate }}"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <span class="align-middle ms-2"> {{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-12 col-md-4 order-3" data-cy="DIV-ddb0">
      <div class="mb-3" data-cy="DIV-455c">
        <label class="text-secondary form-label" for="filterType" [translate]="'EMAILING.LABEL_TYPE_CONTENU'" data-cy="LABEL-6dfb"> </label>
        <ng-select
          labelForId="filterType"
          [items]="emailTypes"
          formControlName="type"
          [searchable]="false"
          bindValue="value"
          (change)="filterByType($event)"
          placeholder="{{ 'EMAILING.FILTRER_LANGUE_TOUS' | translate }}"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <span class="align-middle ms-2"> {{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="col-12 col-md-4 order-4" data-cy="DIV-81ec">
      <div class="mb-3" data-cy="DIV-2a6e">
        <label
          class="text-secondary form-label"
          for="filterLanguage"
          [translate]="'EMAILING.LABEL_LANGUE_MEDIA'"
          data-cy="LABEL-405b"
        ></label>
        <ng-select
          labelForId="filterLanguage"
          [items]="languages"
          formControlName="language"
          [searchable]="false"
          bindValue="value"
          (change)="filterByLanguage($event)"
          placeholder="{{ 'EMAILING.FILTRER_LANGUE_TOUS' | translate }}"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <img height="15" width="22" *ngIf="item.value" [alt]="item.value" src="/assets/images/flags/{{ item.value }}.png" />
            <span class="align-middle ms-2" data-cy="SPAN-e53b"> {{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</form>
