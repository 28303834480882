import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { User } from '@sentry/angular-ivy';

import { Config } from '../config/config';

@Component({
  selector: 'app-chabot',
  templateUrl: './chabot.component.html',
  styleUrls: ['./chabot.component.scss'],
})
export class ChabotComponent {
  public language: string = null;
  public helpDeskLang = ['it', 'en', 'fr', 'de', 'es', 'nl'];
  public me: User;

  constructor(
    private configService: DynamicConfigService,
    private route: ActivatedRoute,
  ) {
    this.me = this.route.snapshot.data.me;
    this.language = this.me.language;
  }

  public redirectToHelp() {
    let lang = 'en';
    if (this.helpDeskLang.includes(this.language)) {
      lang = this.language;
    }
    const url = `${this.configService.get<Config>().helpDeskUrl}${lang}`;
    window.open(url, '_blank');
  }
}
