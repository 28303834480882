import { ActivatedRoute } from '@angular/router';
import {ChangeDetectionStrategy, Component } from '@angular/core';
import { DatePipe } from '@angular/common';

import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';

import { TranslateService } from '@ngx-translate/core';

import { Config } from 'src/app/shared/config/config';


import { ModalService } from '../../../shared/layout/modal/modal.service';

import { ListCandidatsComponent } from '../../../shared/managers/list-candidats/list-candidats.component';

@Component({
  selector: 'app-company-candidats',
  templateUrl: './my-candidats.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyCandidatsComponent extends ListCandidatsComponent {

  constructor(
    protected readonly datePipe: DatePipe,
    protected readonly translate: TranslateService,
    protected readonly activeRoute: ActivatedRoute,
    protected readonly modalService: ModalService,
    configService: DynamicConfigService
  ) {
    super(datePipe, translate, activeRoute, modalService);
    const idManager = activeRoute.snapshot.params['id'];
    this.usersApiUrl = `${configService.get<Config>().apiUrl}/settings/managers/${idManager}/shares`;
    this.setTitleTab();
  }

}
