import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, filter, mergeMap } from 'rxjs/operators';
import { HttpEventType, HttpEvent, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Credential } from './credential/credential';
import { ResourceService } from '../business/resources/resource.service';

@Injectable({
  providedIn: 'root',
})
export class UploadResourceService {
  constructor(private httpClient: HttpClient, private resourceService: ResourceService) {}

  public upload(blob: Blob, name: string, type: string, options?: { [key: string]: string }): Observable<{ id: string; percent: number }> {
    // prettier-ignore
    return this.resourceService
      .post(blob, name, type, options)
      .pipe(mergeMap(resource => this.postBlob(blob, resource)));
  }

  private postBlob(blob: Blob, resource: { id: string; credentials: Credential }) {
    const formData = this.buildFormData(resource.credentials.fields, blob, resource.credentials.conditions.contentType);

    // prettier-ignore
    return this.httpClient
      .post(resource.credentials.url, formData, { reportProgress: true, observe: 'events', headers: { noToken: 'noToken' } })
      .pipe(
        filter((event: HttpEvent<any>) => event.type === HttpEventType.UploadProgress),
      map<HttpUploadProgressEvent, { id: string, percent: number }>(e =>
        ({ id: resource.id, percent: Math.round((e.loaded / e.total) * 100)}))
      );
  }

  private buildFormData(fields: { [key: string]: string }, file: Blob, type: string): FormData {
    const formData = new FormData();

    formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Date', fields['X-Amz-Date']);
    formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
    formData.append('Policy', fields.Policy);
    formData.append('contentType', type);
    formData.append('Content-MD5', fields['Content-MD5']);
    formData.append('key', fields.key);
    formData.append('file', file);

    return formData;
  }
}
