import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chronometer',
})
export class ChronometerPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.round((value % 3600) % 60);

    if (hours > 0) {
      return `${hours < 10 ? '0' : ''}${hours}:${minutes % 60 < 10 ? '0' : ''}${minutes % 60}'${seconds < 10 ? '0' : ''}${seconds}`;
    } else {
      return `${minutes < 10 ? '0' : ''}${minutes}'${seconds < 10 ? '0' : ''}${seconds}`;
    }
  }
}
