import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { CroppieOptions } from 'croppie';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { ModalBaseComponent } from '../modal/modal-base-component';

@Component({
  selector: 'app-image-resizer-modal',
  templateUrl: './image-resizer-modal.component.html',
  styleUrls: ['./image-resizer-modal.component.scss'],
})
export class ImageResizerModalComponent extends ModalBaseComponent<Blob> {
  constructor(bsModalRef: BsModalRef, private toasterService: ToastrService, private readonly translate: TranslateService) {
    super(bsModalRef);
  }

  private _base_options: CroppieOptions = {
    viewport: {
      width: 100,
      height: 100,
      type: 'square',
    },
  };

  private _options: CroppieOptions = this._base_options;
  public get options(): CroppieOptions {
    return this._options;
  }
  public set options(value: CroppieOptions) {
    this._options = { ...this._base_options, ...(value || {}) };
  }

  private croppedImage: Blob;

  public _image: string;
  public set image(v: string | File | File[]) {
    if (v instanceof Array) {
      v = v[0];
    }
    if (v instanceof File) {
      const fr = new FileReader();
      fr.onloadend = _loadEvent => {
        this._image = fr.result.toString();
      };

      fr.readAsDataURL(v);
      return;
    }

    this._image = v;
  }

  result(img: Blob) {
    this.croppedImage = img;
  }

  public onSubmit() {
    if (this.croppedImage) {
      this.close(this.croppedImage);
      return;
    }
    this.toasterService.error(this.translate.instant('MODAL.RESIZER.ERROR.CONTENT'), this.translate.instant('MODAL.RESIZER.ERROR.TITLE'));
  }
}
