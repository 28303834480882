import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyword',
})
export class KeywordHighlightPipe implements PipeTransform {
  openBrace: string = '[';
  closeBrace: string = ']';
  highlightClasses: string = 'badge bg-primary';

  private static escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  private static convertKeyword(keyword: string, colorText: string): string {
    return `<span style="color:${colorText};">${keyword}</span>`;
  }

  private convertKeyword(keyword: string): string {
    return `<span class="${this.highlightClasses}">${keyword}</span>`;
  }

  transform(
    value: string,
    appKeywordHighlight: { [key: string]: string },
    colorText: string = 'deepskyblue',
    hasBadge: boolean = false
  ): string {
    if (!value) {
      return '';
    }

    const keywords = Object.keys(appKeywordHighlight).reduce(
      (acc, k) => ({ ...acc, [`${this.openBrace}${k.toLowerCase()}${this.closeBrace}`]: appKeywordHighlight[k] }),
      {}
    );

    const re = new RegExp(
      Object.keys(keywords)
        .map(k => `${KeywordHighlightPipe.escapeRegExp(k)}`)
        .join('|'),
      'gi'
    );

    return value.replace(re, k => {
      return hasBadge ? this.convertKeyword(keywords[k.toLowerCase()]) : KeywordHighlightPipe.convertKeyword(k, colorText);
    });
  }

  public serialize(value: string, colorText: string = 'deepskyblue'): string {
    if (!value) {
      return '';
    }
    const startbalise = `<span style=\"color:${colorText};\">`;
    const startbaliseColorBlack = '<span style="color:black;">';
    const endBalise = '</span>';
    const balisePStart = '<p>';
    const balisePEnd = '</p>';
    value = this.removeCodeToValue(value, startbalise, endBalise);
    value = this.removeCodeToValue(value, startbaliseColorBlack, endBalise);
    while (value.includes(`${balisePStart}${balisePEnd}`)) {
      value = value.replace(`${balisePStart}${balisePEnd}`, '<br/>');
    }
    value = this.removeCodeToValue(value, balisePStart, balisePEnd);
    return value;
  }

  private removeCodeToValue(value: string, start: string, end: string): string {
    while (value.includes(start)) {
      value = value.replace(start, '').replace(end, start === '<p>' ? '<br/>' : '');
    }
    return value;
  }
}
