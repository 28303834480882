import { SharingObjectType } from 'src/app/shared/sharing/entities/sharing-object-type.enum';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Config } from '../shared/config/config';
import { Observable, of, throwError } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { User } from './user';
import { createHttpParams } from '../shared/requests/http-params';
import { plainToClass } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

/** Services */
import { SentryService } from '../shared/sentry/sentry.service';
import { PostHogService } from '../shared/posthog/posthog.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authRequest: Observable<User>;
  private _userId: number;

  public get config() {
    return this.configService.get<Config>();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storageService: SessionStorageService,
    private http: HttpClient,
    private configService: DynamicConfigService,
    private translateService: TranslateService,
    public injector: Injector,
  ) {}

  public me(): Observable<User> {
    if (this.authRequest) {
      return this.authRequest;
    }

    const sentryService = this.injector.get(SentryService);
    const postHogService = this.injector.get(PostHogService);

    this.authRequest = this.http.get<{ user: User }>(`${this.config.apiUrl}/auth/me`).pipe(
      shareReplay(1),
      tap(r => r || throwError(() => new Error('Invalid authentication'))),
      map(u => {
        const user = plainToClass(User, u);
        const trackingUser = {
          id: user.id,
          email: user.email,
          companyId: user.companyId,
          companyName: user.companyName,
        };

        this.userId = user.id;
        this.translateService.use(user.language);
        sentryService.setUserContext(trackingUser);
        delete trackingUser.email;
        postHogService.identify(this.userId?.toString(), trackingUser);

        return user;
      }),
      catchError(e => {
        if (e && e.status === 401) {
          return throwError(() => new Error('Invalid authentication'));
        } else {
          throwError(() => e);
        }
      }),
    );

    return this.authRequest;
  }

  public allowedActions(objectType: SharingObjectType, objectId: string, params: object = {}): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.apiUrl}/auth/me/allowed-actions/${objectType}/${objectId}`, {
      params: createHttpParams(params),
    });
  }

  public isAuthenticated(): Observable<boolean> {
    return this.me().pipe(
      catchError(() => of(false)),
      map(u => !!u),
    );
  }

  public hasToken(): boolean {
    return !!this.getToken();
  }

  public getToken(): string {
    if (!this.config) {
      return undefined;
    }
    return this.storageService.retrieve(this.config.tokenStorageKey);
  }

  public set userId(value: number) {
    this._userId = value;
  }

  public get userId(): number {
    return this._userId;
  }

  public setToken(token): void {
    this.storageService.store(this.config.tokenStorageKey, token);
  }

  public logout(): void {
    this.document.location.href = `${this.config.authUrl}/${encodeURI('login?logout=true')}`;
  }
}
