import { TooltipConfig } from 'ngx-bootstrap/tooltip';

export function TooltipConfigFactory() {
  return Object.assign(new TooltipConfig(), {
    delay: 200,
    placement: 'top',
    container: 'body',
    adaptivePosition: true,
    boundariesElement: 'window',
  }) as TooltipConfig;
}
