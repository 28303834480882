<form [formGroup]="selectUsersTeamsForm">
  <div data-cy="DIV-879f">
    <label *ngIf="label" for="UsersTeamsFilter" class="form-label" [class.text-secondary]="isLabelSecondary" data-cy="LABEL-497c">{{
      label
    }}</label>
    <ng-select
      formControlName="selectedUsersTeamsId"
      class="customNgSelectTeams"
      labelForId="UsersTeamsFilter"
      (change)="onChange($event)"
      [items]="items | async"
      [searchable]="true"
      [searchFn]="customSearchTeamOrUser"
      [multiple]="multiple"
      [closeOnSelect]="!multiple"
      [loading]="loading"
      [clearSearchOnAdd]="true"
      [selectableGroup]="multiple"
      [selectableGroupAsModel]="false"
      [placeholder]="placeholder"
      [clearable]="clearable"
      [groupBy]="!users || !teams ? null : 'typeI18n'"
    >
      <ng-template *ngIf="!groupResults || !multiple || currentCount.users + currentCount.teams <= 1" ng-label-tmp let-item="item">
        <cc-user-thumbnail *ngIf="enableThumbnail" [thumbnail]="item.avatar"></cc-user-thumbnail>
        <span class="align-middle" [ngClass]="{ 'ms-2': enableThumbnail }" data-cy="SPAN-e77e"> {{ item.title }}</span>
      </ng-template>
      <ng-template ng-multi-label-tmp *ngIf="groupResults && multiple && currentCount.users + currentCount.teams > 1">
        <span *ngIf="currentCount.users" class="ng-value align-middle ms-2" data-cy="SPAN-b873">
          {{ 'COMMON.USERS' | translate: { count: currentCount.users, INTERPOLATION: currentCount.users } }}
          ({{ currentCount.users }})
        </span>
        <span *ngIf="currentCount.teams" class="ng-value align-middle ms-2" data-cy="SPAN-b41b">
          {{ 'COMMON.TEAMS' | translate: { count: currentCount.teams, INTERPOLATION: currentCount.teams } }}
          ({{ currentCount.teams }})
        </span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <cc-user-thumbnail *ngIf="enableThumbnail" [thumbnail]="item.avatar"></cc-user-thumbnail>
        <span class="align-middle" [ngClass]="{ 'ms-2': enableThumbnail }" data-cy="SPAN-8c75"> {{ item.title }}</span>
      </ng-template>
      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <strong>{{ item.typeI18n }}</strong>
        <div *ngIf="multiple" class="float-end form-check" data-cy="DIV-d66e">
          <input
            type="checkbox"
            class="form-check-input"
            id="item-{{ index }}"
            (click)="item$.selected = !item$.selected"
            [(ngModel)]="item$.selected"
            [ngModelOptions]="{ standalone: true }"
            data-cy="INPUT-997f"
          />
          <label class="form-label" for="item-{{ index }}" data-cy="LABEL-a667"></label>
        </div>
      </ng-template>
    </ng-select>
  </div>
</form>
