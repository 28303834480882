import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import { CommonModule, DatePipe } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CcTableModule } from '@cleverconnect/ngx-bootstrap-table';
import { MyManagersComponent } from './my-managers/my-managers.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MyCandidatsComponent } from './my-candidats/my-candidats.component';
import { ManagersRoutingModule } from './managers-routing.module';
import { SharedUserModule } from '../../shared/business/user/shared-user.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserThumbnailModule } from '@cleverconnect/ngx-user-thumbnail';
import { FormsModule } from '@angular/forms';

@NgModule({
  providers: [ DatePipe ],
  imports: [
    CommonModule,
    SharedModule,
    CcTableModule,
    NgSelectModule,
    NgOptionHighlightModule,
    BsDropdownModule,
    TooltipModule,
    TabsModule,
    ManagersRoutingModule,
    SharedUserModule,
    TranslateModule,
    FormsModule,
    UserThumbnailModule
],
  declarations: [
    MyManagersComponent,
    MyCandidatsComponent,
  ]
})
export class ManagerModule {}
