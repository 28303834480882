<form [formGroup]="videoRecordForm" (ngSubmit)="submit()" autocomplete="off">
  <div class="modal-header" data-cy="DIV-be57">
    <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-da74">
      <span aria-hidden="true" data-cy="SPAN-1e8f">&times;</span>
    </button>
    <div class="modal-title" data-cy="DIV-56de">
      <p class="h3" [translate]="'RECORD_MODAL.TITRE'"></p>
      <p class="subtitle" [translate]="'RECORD_MODAL.SOUS_TITRE'"></p>
    </div>
  </div>
  <div class="modal-body" data-cy="DIV-72c1">
    <label class="form-label" *ngIf="step == 'start' && !isRecorderReady" data-cy="LABEL-30ab">
      {{ 'RECORD_MODAL.TITRE_AUTORISATION_MATERIEL' | translate }}
      :
    </label>
    <label class="form-label" *ngIf="step == 'start' && isRecorderReady" data-cy="LABEL-9429">
      {{ 'RECORD_MODAL.TITRE_DEMARRER_ENREGISTREMENT' | translate }} :
    </label>
    <div class="row g-2" data-cy="DIV-32da">
      <div class="col-12 col-md-5 mb-3 mb-md-0" data-cy="DIV-16f8">
        <div #videoRecorder data-cy="DIV-6b4c"></div>
        <div *ngIf="step == 'recording'" data-cy="DIV-3b69">
          <div class="row g-0 align-items-center" data-cy="DIV-55b3">
            <div class="col" data-cy="DIV-b5a7">
              <progressbar
                [animate]="true"
                [striped]="true"
                [value]="elapsedTime"
                [max]="recordingTime * 60"
                [type]="elapsedTime < recordingTime * 60 - 30 ? 'primary' : 'danger'"
                data-cy="PROGRESSBAR-dfcc"
              >
              </progressbar>
            </div>
            <div class="col-auto ps-1" data-cy="DIV-6bb0">
              {{ recordingTime * 60 - elapsedTime | chronometer }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 align-self-end" *ngIf="step == 'start' || step == 'recording'" data-cy="DIV-5b1b">
        <div
          class="alert alert-info m-0"
          *ngIf="!isRecorderReady"
          role="alert"
          [innerHTML]="'RECORD_MODAL.AUTORISATION_MATERIEL' | translate"
          data-cy="DIV-19ef"
        ></div>
        <div
          class="alert alert-info m-0"
          *ngIf="isRecorderReady"
          role="alert"
          [innerHTML]="'RECORD_MODAL.DUREE_ENREGISTREMENT' | translate: { recordingTime: recordingTime }"
          data-cy="DIV-12f4"
        ></div>
      </div>
      <div class="col-12 col-md-7" *ngIf="step == 'recorded'" data-cy="DIV-3f8c">
        <div class="mb-3" data-cy="DIV-50d6">
          <label class="form-label" for="title" data-cy="LABEL-374d">
            <span [translate]="'RECORD_MODAL.NOM_MEDIA'" data-cy="SPAN-e046"></span>
          </label>
          <input
            id="title"
            class="form-control bg-white"
            formControlName="title"
            [class.is-invalid]="videoRecordForm.isInvalid('title')"
            [placeholder]="'RECORD_MODAL.NOM_QUESTIONNAIRE_PLACEHOLDER' | translate"
            placeholder="Ex : Vidéo d'accueil stages"
            data-cy="INPUT-f823"
          />
          <div class="invalid-feedback" [translate]="'RECORD_MODAL.NOM_MEDIA_OBLIGATOIRE'" data-cy="DIV-c937"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" [ngSwitch]="step" data-cy="DIV-6f42">
    <button
      type="button"
      *ngSwitchCase="'start'"
      [class.disabled]="!isRecorderReady"
      class="btn btn-success"
      (click)="startRecord()"
      data-cy="BUTTON-2538"
    >
      <span [translate]="'RECORD_MODAL.LANCER_LENREGISTREMENT'" data-cy="SPAN-a0ce"></span>
      &nbsp;
    </button>
    <button type="button" *ngSwitchCase="'recording'" class="btn btn-success" (click)="stopRecord()" data-cy="BUTTON-4a87">
      <span [translate]="'RECORD_MODAL.ARRETER_LENREGISTREMENT'" data-cy="SPAN-ea25"></span>
      &nbsp;
    </button>
    <button type="button" *ngSwitchCase="'recorded'" class="btn btn-link" (click)="reset()" data-cy="BUTTON-da21">
      <span [translate]="'RECORD_MODAL.RECOMMENCER_LENREGISTREMENT'" data-cy="SPAN-7121"></span>
    </button>
    <button
      type="submit"
      *ngSwitchCase="'recorded'"
      [class.disabled]="!videoRecordForm.valid"
      [disabled]="false"
      class="btn btn-success"
      data-cy="BUTTON-98b8"
    >
      <span [translate]="'RECORD_MODAL.VALIDER_LENREGISTREMENT'" data-cy="SPAN-da31"></span> &nbsp;
    </button>
  </div>
</form>
