<div class="h-100 nav text-white align-middle d-flex flex-column">
  <div class="w-100 text-center">
    <img class="pt-1 m-2" alt="visiolant" src="assets/images/logo-vt-by-cc-white.svg" />
  </div>
  <div class="w-100 wrapper">
    <div class="scrollbar h-100">
      <ul class="pt-2 m-0 h-100 w-100 list-unstyled text-white" *ngIf="menus.length > 0">
        <ng-container *ngFor="let menu of menus">
          <ul class="list-unstyled text-white m-0 pb-1 pt-1 {{ menu.class }} w-100">
            <li
              class="text-decoration-none ps-2 py-1 {{ menu.hidden ? '' : 'text-primary' }}"
              [class.admin]="menu.id === 'ADMIN'"
              [class.admin-active]="menu.id === 'ADMIN' && !menu.hidden"
              (click)="menu.url ? openQuitModal(menu) : showOrHideItem(menu)"
            >
              <i *ngIf="menu.classIcon" class="align-top me-1 d-inline-block {{ menu.classIcon ? menu.classIcon : '' }}"></i>
              <span class="w-75 d-inline-block">{{ menu.title | translate }}</span>
              <i class="material-icons icon-arrow" *ngIf="menu.children.length > 0">{{
                menu.hidden ? 'keyboard_arrow_right' : 'expand_more'
              }}</i>
            </li>
            <ul *ngIf="menu.children" class="list-unstyled ps-3" [hidden]="menu.hidden">
              <ng-container *ngFor="let item of menu.children">
                <li
                  class="text-decoration-none py-1 ps-2 {{ item.hidden ? '' : 'text-primary' }}  {{ item.class }}"
                  (click)="showOrHideItem(menu, item)"
                >
                  <i *ngIf="item.icon" class="material-icons fs-4 align-middle pe-1">{{ item.icon }}</i>
                  <span *ngIf="!item.translate">{{ item.title }}</span>
                  <span *ngIf="item.translate">{{ item.title | translate }}</span>
                  <i class="material-icons icon-arrow" *ngIf="item.children.length > 0">{{
                    item.hidden ? 'keyboard_arrow_right' : 'expand_more'
                  }}</i>
                </li>
                <ul *ngIf="item.children.length > 0" class="list-unstyled ps-3" [hidden]="item.hidden">
                  <ng-container *ngFor="let subItem of item.children">
                    <li
                      class="text-decoration-none py-1 {{ subItem.hidden ? '' : 'text-primary' }} {{ item.class }}"
                      (click)="openQuitModal(subItem)"
                    >
                      {{ subItem.title | translate }}
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
            </ul>
          </ul>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
