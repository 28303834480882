import { Component, Input } from '@angular/core';
import { ModalBaseComponent } from '../modal-base-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmModalParameters } from './confirm-modal-parameters';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends ModalBaseComponent<boolean> {
  public isSubmitting: boolean = false;

  @Input()
  public parameters: ConfirmModalParameters;

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  public onSubmit() {
    this.isSubmitting = true;
    this.onClose.next(true);
  }
}
