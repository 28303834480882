<div class="modal-header" data-cy="DIV-a75f">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-ec3e">
    <span aria-hidden="true" data-cy="SPAN-1491">&times;</span>
  </button>
  <div class="modal-title w-100 text-center" data-cy="DIV-6e5a">
    <p class="h3" [translate]="'EMAILING.DUPLIQUER_AND_SHARE'"></p>
  </div>
</div>

<form [formGroup]="shareForm" (ngSubmit)="submit()" autocomplete="off">
  <div class="modal-body py-4" data-cy="DIV-1065">
    <p class="text-center text-secondary pt-1" [translate]="'EMAILING.DUPLIQUER_AND_SHARE_CONTENT'"></p>
    <div class="mb-3">
      <ng-select
        name="sharingTeams"
        class=""
        formControlName="sharings"
        id="emailingSharings"
        [class.is-invalid]="shareForm.isInvalid('sharings')"
        [items]="teams"
        [hideSelected]="true"
        [multiple]="true"
        [placeholder]="(isCompanyAdministrator ? 'COMMON.TEAMS.ALL' : 'PANEL.COMPANY.CONTENT.EMAILTEMPLATE.SHARE.TEAMS.SELECT') | translate"
        [searchable]="false"
        bindValue="value"
        [closeOnSelect]="false"
        [clearable]="true"
      >
        <ng-template ng-option-tmp let-item="item">
          <span class="align-middle" data-cy="SPAN-853e">{{ item.label }}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="align-middle" (click)="clear(item)" data-cy="SPAN-853e" role="button">{{ item.label }}</span>
        </ng-template>
      </ng-select>
      <div
        *ngIf="shareForm.get('sharings').errors?.required"
        class="invalid-feedback"
        [translate]="'COMMON.ERRORS.REQUIRED'"
        data-cy="DIV-1de8"
      ></div>
    </div>
  </div>

  <div class="modal-footer text-right py-1" data-cy="DIV-879c">
    <button
      class="btn btn-success"
      [class.disabled]="shareForm?.invalid"
      type="submit"
      [translate]="'EMAILING.DUPLIQUER_AND_SHARE'"
      data-cy="A-5105"
    ></button>
  </div>
</form>
