import { User } from '../../../auth/user';
import { UserOptions } from 'src/app/auth/user-options';

export class Team {
  id: number;
  name?: string;
  accountManagerId?: number;
  type?: string;
  options?: UserOptions;
  crmId?: string;
  members: User[];
  administrators: User[];
  companyId?: number;
}
