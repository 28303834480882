import { AfterViewInit, Component, OnInit } from '@angular/core';

import { GoogleAnalyticsProvider } from './providers/google-analytics.provider';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
  private readonly gaUaId: string = 'UA-47466859-9';
  private scriptTag: string = '';

  ngOnInit() {
    const googleAnalyticsProvider = new GoogleAnalyticsProvider();

    this.scriptTag += googleAnalyticsProvider.script(this.gaUaId);
  }

  ngAfterViewInit(): void {
    this.addTarteAuCitron();

    if (!this.scriptTag) {
      return;
    }

    const s: HTMLScriptElement = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = this.scriptTag;
    document.body.appendChild(s);
  }

  private addTarteAuCitron(): void {
    const tarteaucitron = (window as unknown as { tarteaucitron: any }).tarteaucitron;

    if (!tarteaucitron) {
      return;
    }

    if (this.scriptTag) {
      tarteaucitron.user.gtagUa = this.gaUaId;
      (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
    }
  }
}
