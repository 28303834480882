import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageResizerModalComponent } from './image-resizer-modal.component';
import { ImageCropperModule } from '@cleverconnect/ngx-image-cropper';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ImageResizerModalComponent],
    imports: [CommonModule, ImageCropperModule, FormsModule, TranslateModule]
})
export class ImageResizerModalModule {}
