<fieldset class="mb-3 bg-light p-1 pt-4 rounded my-5 position-relative">
  <legend     *ngIf="title"
    class="float-none w-auto m-auto bg-white border border-white rounded-pill px-3 shadow"
  >
    <h6 class="text-secondary m-1 px-3 letter-spacing-2 text-uppercase text-center" data-cy="H6-b672">
      {{title}}
    </h6>
  </legend>

  <ng-content></ng-content>
</fieldset>
