import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserThumbnailModule } from '@cleverconnect/ngx-user-thumbnail';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { DeactivateUserComponent } from './deactivate-user/deactivate-user.component';
import {RemoveSharedCandidatComponent} from '../../managers/remove-shared-candidat/remove-shared-candidat.component';

const components = [
  DeactivateUserComponent,
  ActivateUserComponent,
  RemoveSharedCandidatComponent
];

@NgModule({
  imports: [ TranslateModule, NgSelectModule, NgOptionHighlightModule, FormsModule, CommonModule, UserThumbnailModule ],
  exports: [ ...components ],
  declarations: [...components ],
})
export class SharedUserModule {}
