import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StatusService } from 'src/app/settings/status/status.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { EmailType } from './entities/email-type';
import { EmailTypeEnum } from './entities/email-type.enum';

@Injectable({
  providedIn: 'root',
})
export class EmailTypeService {
  private emailTypes: EmailType[];

  constructor(private translate: TranslateService, private statusService: StatusService) {
    this.emailTypes = Object.keys(EmailTypeEnum)
      .map(statusId => ({ value: statusId, labelKey: `EMAILING_TYPE.${statusId}`.replace('-', '_').toUpperCase() }))
      .map(statusId => ({ value: statusId.value, label: translate.instant(statusId.labelKey) }));
  }

  getAll(): Observable<EmailType[]> {
    return of([...this.emailTypes, { value: 'trigger', label: this.translate.instant(`EMAIL_TYPE.TRIGGER`) }]);
  }

  getWithStatuses(): Observable<EmailType[]> {
    return this.statusService.getAll().pipe(
      map(statuses => statuses.data.map(s => ({ label: s.value, value: s.id.toString() }))),
      map(types => [...this.emailTypes, ...types])
    );
  }
}
