import { Profile } from 'src/app/shared/business/user/profiles/profile';
import { RolesEnum } from 'src/app/auth/roles.enum';

import { Company } from '../panel/companies/company';
import { Team } from '../panel/shared/entities/team';
import { UserOptions } from './user-options';

export class User {
  id: number;
  externalId: number;
  username?: string;
  email: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  avatar?: string;
  language: string;
  logo: string;
  position: string;
  options?: UserOptions;
  mobilePhone?: string;
  roles?: RolesEnum[];
  teams: Team[];
  administratedTeams: Team[];
  company?: Company;
  companyId: number;
  companyName?: string;
  deleted: boolean;
  administratedCompanies: Company[];
  profiles: Profile[];
  accountType?: string;
  recruitmentModes: string[];

  get isMultilingual(): boolean {
    return this.options?.isMultilingual;
  }

  public get isCsm(): boolean {
    return this.roles?.map(r => r.toLowerCase()).some(r => r === RolesEnum.csm || r === RolesEnum.admin) ?? false;
  }

  public get isAdmin(): boolean {
    return this.administratedCompanies?.some(c => c.id === this.company.id) ?? false;
  }

  public get isAdminTeam(): boolean {
    return this.administratedTeams?.some(c => c.companyId === this.company.id) ?? false;
  }
}
