<div class="text-center modal-body" data-cy="DIV-d650">
  <h4 [translate]="'EMAILING.SUPPRIMER_EMAIL_TYPE'" data-cy="H4-1669">Supprimer l'email type</h4>
  <p class="text-secondary" translate>EMAILING.SUPPRIMER_EMAIL_WARNING</p>
  <p>
    <button
      class="btn btn-success"
      (click)="this.deleteConfirmClick()"
      [translate]="'EMAILING.SUPPRIMER_EMAIL'"
      data-cy="BUTTON-c45e"
    ></button>
  </p>
  <p>
    <a href="javascript:void(0)" class="link" (click)="this.close()" [translate]="'EMAILING.SUPPRIMER_EMAIL_ANNULER'" data-cy="A-3c63"></a>
  </p>
</div>
