<accordion class="mb-3">
  <accordion-group     #accordion
    [isOpen]="isOpen"
    [isDisabled]="isDisabled"
  >
    <h5 accordion-heading class="my-1" data-cy="H5-ef93">
      <ng-container>{{title}}</ng-container>
      <i class="text-secondary float-end pull-right material-icons md-24" data-cy="I-1e29">
        <ng-container *ngIf="!accordion.isOpen">keyboard_arrow_down</ng-container>
        <ng-container *ngIf="accordion.isOpen">keyboard_arrow_up</ng-container>
      </i>
    </h5>

    <ng-content></ng-content>
  </accordion-group>
</accordion>
