<div class="modal-header" data-cy="DIV-a75f">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-ec3e">
    <span aria-hidden="true" data-cy="SPAN-1491">&times;</span>
  </button>

  <div class="modal-title w-100 text-center" data-cy="DIV-6e5a">
    <p class="h3">{{ email.title }}</p>
  </div>
</div>

<form autocomplete="off" (ngSubmit)="close()">
  <div class="w-100 d-block" data-cy="DIV-26e6">
    <div class="px-4 py-2 mx-4 div-content-email rounded-2 position-relative overflow-y-auto mb-0" data-cy="DIV-4d10">
      <div class="bg-white rounded p-3 mb-1 small text-dark position-relative mx-auto block-max-width" data-cy="DIV-9496">
        <strong [translate]="'MAIL_TEMPLATE.SUBJECT'" class="d-inline-block"></strong>&nbsp;
        <span
          class="d-inline-block fst-italic"
          *ngIf="email.subject === '' || email.subject === '<br/>'"
          [translate]="'MAIL_TEMPLATE.SUBJECT.EMPTY_STATE'"
          data-cy="SPAN-c665"
        ></span>
        <span class="d-inline-block" [appKeywordHighlight]="tagsList" [content]="email.subject" data-cy="SPAN-ebcc"></span>
      </div>

      <div
        class="rounded mb-3 small text-dark bg-white position-relative block-max-width mx-auto"
        [class.block-is-empty]="email.content === ''"
        [class.p-3]="email.content === ''"
        [class.text-center]="email.content === ''"
        data-cy="DIV-3124"
      >
        <div #innerhtml [appKeywordHighlight]="tagsList" *ngIf="hasHtml" data-cy="DIV-0bc7"></div>
        <ng-container *ngIf="!hasHtml">
          <span
            *ngIf="email.content !== ''"
            class="p-3 d-inline-block block-is-empty"
            [appKeywordHighlight]="tagsList"
            [content]="email.content"
            data-cy="SPAN-d427"
          ></span>
          <span
            *ngIf="email.content === ''"
            class="fst-italic"
            [translate]="'MAIL_TEMPLATE.CONTENT.EMPTY_STATE'"
            data-cy="SPAN-3ba2"
          ></span>
        </ng-container>
      </div>
    </div>
  </div>
</form>
