import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from '../../shared/config/config';
import { MediaType } from './media-type';
import { Resource } from '../../shared/business/resources/resource';
import { Sharing } from 'src/app/shared/sharing/entities/sharing';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediasService {
  private config: Config;

  constructor(private http: HttpClient, dynamicConfigService: DynamicConfigService) {
    this.config = dynamicConfigService.get<Config>();
  }

  public get(mediaId: string) {
    return this.http.get<MediaType>(`${this.config.apiUrl}/resources/${mediaId}`);
  }

  public delete(mediaId: string) {
    return this.http.delete<any>(`${this.config.apiUrl}/resources/${mediaId}`);
  }

  public put(resource: Resource) {
    return this.http.put<Resource>(`${this.config.apiUrl}/resources/${resource.id}`, { resource: resource });
  }

  public putPanel(resource: Resource, companyId: Number) {
    return this.http.put<Resource>(`${this.config.apiUrl}/panel/companies/${companyId}/resources/${resource.id}`, {
      resource: resource,
    });
  }

  public share(share: Partial<Sharing>) {
    return this.http.post<Sharing>(`${this.config.apiUrl}/sharing`, share);
  }

  public duplicate(id: string): Observable<Resource> {
    return this.http.get<Resource>(`${this.config.apiUrl}/resources/${id}/duplicate`);
  }
}
