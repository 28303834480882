<div class="modal-body modal-style text-secondary px-5 py-4 add-user-to-team-modal" data-cy="DIV-b533">
  <button type="button" (click)="cancel()" class="close pull-right" aria-label="Close" data-cy="BUTTON-2b8b">
    <span aria-hidden="true" data-cy="SPAN-be4a">&times;</span>
  </button>
  <div data-cy="DIV-55f4">
    <h4 class="text-body py-2 text-center" [translate]="'SETTINGS.MANAGERS.MODAL_REMOVE_SHARED.TITLE'" data-cy="H4-dfd9"></h4>
    <div class="text-center pb-3" translate [translateParams]="{ count: selectedShared.length }" data-cy="DIV-6580">
      SETTINGS.MANAGERS.MODAL_REMOVE_SHARED.SUBTITLE
    </div>
  </div>
  <ng-select
    name="selectedUsers"
    multiple="true"
    class="ng-select-white"
    [hideSelected]="true"
    [(ngModel)]="selectedShared"
    [items]="[]"
    [searchable]="false"
    [disabled]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="false"
    [placeholder]="'PANEL.USERS.DESACTIVER.CHERCHER_UTILISATEURS' | translate"
    placeholder="Rechercher des utilisateurs"
  >
    <ng-template ng-label-tmp let-item="item">
      <span class="text-primary">{{ item.firstname && item.lastname ? item.firstname + ' ' + item.lastname : item.email }}</span>
    </ng-template>
  </ng-select>
  <div class="buttons-container text-center mt-3" data-cy="DIV-69b6">
    <button
      (click)="removeShare()"
      class="btn btn-panel btn-sm fw-bold"
      [translate]="'SETTINGS.MANAGERS.MODAL_REMOVE_SHARED.SUBMIT'"
      data-cy="BUTTON-d559"
    ></button>
    <br />
    <button
      (click)="close()"
      class="btn btn-link btn-sm text-decoration-none pt-3"
      [translate]="'COMMON.ANNULER'"
      data-cy="BUTTON-151e"
    ></button>
  </div>
</div>
