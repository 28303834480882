import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { StatusComponent } from './status.component';
import { StatusSearchFormComponent } from './status-search-form/status-search-form.component';
import { StatusListComponent } from './status-list/status-list.component';
import { StatusAddModalComponent } from './status-add-modal/status-add-modal.component';
import { CcTableModule } from '@cleverconnect/ngx-bootstrap-table';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
    imports: [CommonModule, SharedModule, TooltipModule, BsDropdownModule,  CcTableModule,
PaginationModule ],
    declarations: [StatusComponent, StatusSearchFormComponent, StatusListComponent, StatusAddModalComponent]
})
export class StatusModule {}
