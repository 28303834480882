import { Config } from 'src/app/shared/config/config';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { User } from '../../../../auth/user';

@Injectable({ providedIn: 'root' })
export class CompanyUserService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private config: Config;

  constructor(
    private http: HttpClient,
    configService: DynamicConfigService,
  ) {
    this.config = configService.get<Config>();
  }

  public get(userId: number, companyId: number): Observable<User> {
    return this.http.get<User>(`${this.getUrl(companyId)}/${userId}`).pipe(map((user: User) => Object.assign(new User(), { ...user })));
  }

  public getAll(companyId: number, search?: CreateQueryParams): Observable<{ data: User[]; total: number }> {
    const query = RequestQueryBuilder.create(search);
    return this.http.get<{ data: User[]; total: number }>(this.getUrl(companyId), { params: query.queryObject });
  }

  public save(user: Partial<User>, companyId: number = user.companyId) {
    return this.http.post<User>(this.getUrl(companyId), user, { headers: this.headers });
  }

  public patch(user: Partial<User>, companyId: number = user.companyId) {
    return this.http.patch<User>(`${this.getUrl(companyId)}/${user.id}`, user, { headers: this.headers });
  }

  public bulk(users: User[], companyId: number): Observable<User[]> {
    return this.http.post<User[]>(`${this.getUrl(companyId)}/bulk`, users, { headers: this.headers });
  }

  private getUrl(companyId: number): string {
    return `${this.config.apiUrl}/panel/companies/${companyId}/users`;
  }

  public changePassword(body: {
    user: { old_password: string; password_confirm: string; new_password: string };
  }): Observable<ChangePasswordResult> {
    return this.http.post<ChangePasswordResult>(`${this.config.apiUrl}/users/password`, body);
  }

  public updateUser(user: Partial<User>, userId: number) {
    return this.http.patch<User>(`${this.config.apiUrl}/users/${userId}`, user);
  }
}

export interface ChangePasswordResult {
  success: boolean;
  error: string;
}
