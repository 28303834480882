<div class="d-flex flex-column h-100 w-100">
  <app-common-header title="Managers" [title]="'SETTINGS.MANAGERS.CANDIDATS.TITLE' | translate"></app-common-header>
  <app-flex-fill-container scrollSpyElement="flex-fill-container">
    <div class="col-12 row gx-3 mt-4 me-0 mb-2 px-0">
      <div class="input-group pe-0">
        <div class="input-group-text bg-lightest-gray">
          <i class="material-icons">search</i>
        </div>
        <input
          [(ngModel)]="globalSearch"
          class="form-control"
          placeholder="Nom de l'utilisateur"
          [placeholder]="'SETTINGS.MANAGERS.CANDIDATS.FILTERS.NOM' | translate"
        />
      </div>
    </div>

    <div *ngIf="selectedUsers?.length == 0">
      <h4 class="pt-4 pb-2">
        {{ titleTab }}&nbsp;
        <span *ngIf="nbUsers !== undefined" class="badge rounded-pill bg-primary py-1"> {{ nbUsers }} </span>
      </h4>
    </div>
    <div>
      <cc-table
        class="last-col-fix"
        [url]="usersApiUrl"
        tableClasses="table-hover align-middle text-nowrap"
        [columns]="columns"
        [search]="filter"
        [checkboxActions]="actions"
        [checkboxHeader]="true"
        (dataUpdated)="nbUsers = $event.total"
        (checkChanged)="selectedUsers = $event"
        (action)="openRemoveShareModal($event.items)"
        [selectionBarTitle]="'SETTINGS.MANAGERS.CANDIDATS_SELECTIONNES' | translate : { count: selectedUsers.length }"
        [labels]="tableLabels"
        #ccTable
      ></cc-table>
    </div>
  </app-flex-fill-container>
</div>

<ng-template #actionsTemplate let-line="line" let-data="data">
  <div class="position-relative text-center">
    <a
      [tooltip]="'SETTINGS.MANAGERS.CANDIDATS.ACTIONS.REMOVE_SHARE' | translate"
      class="btn btn-link link-secondary text-decoration-none p-1"
      href="javascript:void(0)"
      (click)="openRemoveShareModal([line]); $event.stopPropagation()"
    >
      <i class="material-icons md-24">person_remove</i>
    </a>
  </div>
</ng-template>
