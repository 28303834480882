import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Resource } from '../../business/resources/resource';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public content: string;

  @Input()
  public previewUrl: string;

  @Input()
  public destination: string;

  @Input()
  public language: string;

  @Input()
  public id: string;

  @Input()
  public imgMaxWidth: string = 'auto';

  @Input()
  public imgMaxHeight: string = 'auto';

  @Input()
  public resource: Resource;

  @Output()
  public deleteEvent = new EventEmitter();

  @Output()
  public editEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  delete(id) {
    this.deleteEvent.emit(id);
  }

  edit(id) {
    this.editEvent.emit(id);
  }
}
