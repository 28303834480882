<div class="list-search" data-cy="DIV-e657">
  <div class="input-group mb-3" data-cy="DIV-675f">
    <span class="input-group-text" id="basic-addon1" data-cy="SPAN-70cf"> <i class="material-icons" data-cy="I-629b">search</i> </span>
    <input class="form-control" (input)="filter($event.target.value)" [placeholder]="'QUESTIONNAIRE.RECHERCHER_CRITERE' | translate" placeholder="Rechercher un critère" type="text" data-cy="INPUT-86a5">
  </div>
</div>
<div class="list-selector" data-cy="DIV-bb0b">
  <ul     class="selector-parent"
    *ngFor="let criteria of _filteredData"
    [hidden]="criteria.children.length == 0 || !criteria.isListable"
  >
    <span class="parentItem " data-cy="SPAN-c843">{{ criteria.label }}</span>
    <li *ngFor="let type of criteria.children">
      <span (click)="setValue(type)" container="body" tooltip="{{type.description}}" placement="top " data-cy="SPAN-1c23">{{ type.label }}</span>
    </li>
  </ul>
</div>
