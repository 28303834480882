import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { MediasListComponent } from './medias/medias-list/medias-list.component';
import { RoleGuard } from '../auth/role.guard';
import { RolesEnum } from '../auth/roles.enum';
import { SettingsHomeComponent } from './settings-home/settings-home.component';
import { StatusComponent } from './status/status.component';

const settingsRoutes: Routes = [
  { path: '', data: { roles: [RolesEnum.user, RolesEnum.collaborator] }, canActivate: [RoleGuard], component: SettingsHomeComponent },
  { path: 'status', data: { roles: [RolesEnum.user, RolesEnum.collaborator] }, canActivate: [RoleGuard], component: StatusComponent },
  {
    path: 'medias',
    children: [
      { path: '', data: { roles: [RolesEnum.user, RolesEnum.collaborator] }, canActivate: [RoleGuard], component: MediasListComponent },
    ],
  },
  {
    path: 'emailing',
    data: { roles: [RolesEnum.user, RolesEnum.collaborator] },
    canActivate: [RoleGuard],
    component: EmailTemplatesComponent,
  },
  {
    path: 'managers',
    data: { roles: [RolesEnum.user, RolesEnum.collaborator] },
    canActivate: [RoleGuard],
    loadChildren: () => import('./managers/managers.module').then(m => m.ManagerModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(settingsRoutes)],
  providers: [],
  exports: [RouterModule, HttpClientModule],
})
export class SettingsRoutingModule {}
