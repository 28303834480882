import { Injectable } from '@angular/core';
import { Media } from 'src/app/shared/business/resources/media';
import { ModalService } from 'src/app/shared/layout/modal/modal.service';
import { ResourceViewerModalComponent } from './resource-viewer-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ResourceViewerModalService {
  constructor(private modalService: ModalService) {}

  public openModal(resource: Media) {
    const bsModalRef = this.modalService.show(ResourceViewerModalComponent, {
      initialState: { resource },
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered text-center',
    });
    const modalComponent = bsModalRef.content as ResourceViewerModalComponent;
    return modalComponent.onClose;
  }
}
