<div class="modal-header" data-cy="DIV-9de8">
  <h4 class="modal-title m-auto text-center" [translate]="'IMAGE_RESIZER.TITRE'" data-cy="H4-31c3"></h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()" data-cy="BUTTON-ee63">
    <span aria-hidden="true" data-cy="SPAN-24a8">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSubmit()">
  <fieldset>
    <div class="modal-body" data-cy="DIV-9ef2">
      <p class="text-secondary text-center" [translate]="'IMAGE_RESIZER.SOUSTITRE'"></p>

      <cc-image-cropper
        *ngIf="_image"
        [url]="_image"
        [options]="options"
        [style.height]="'300px'"
        (result)="result($event)"
      ></cc-image-cropper>

      <div class="text-center mt-5" data-cy="DIV-dc71">
        <button type="submit" class="btn btn-panel px-5" [translate]="'COMMON.VALIDER'" data-cy="BUTTON-ed23"></button>
        <br />
        <button type="button" class="btn btn-link p-2" (click)="cancel()" [translate]="'COMMON.ANNULER'" data-cy="BUTTON-2e92"></button>
      </div>
    </div>
  </fieldset>
</form>
