import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input } from '@angular/core';

import { ModalBaseComponent } from '../modal/modal-base-component';

@Component({
  selector: 'app-quit',
  templateUrl: './quit.component.html',
  styleUrls: ['./quit.component.scss'],
})
export class QuitComponent extends ModalBaseComponent<string> {
  @Input()
  public redirectUrl: string;

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  public closeModal(): void {
    this.close('true');
  }

  public cancelAction(): void {
    this.close('false');
  }
}
