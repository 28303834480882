<div class="modal-header" data-cy="DIV-e39d">
  <h4 class="modal-title m-auto text-center" data-cy="H4-8770">{{ parameters?.labels?.title }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()" data-cy="BUTTON-f26a">
    <span aria-hidden="true" data-cy="SPAN-b51e">&times;</span>
  </button>
</div>
<form (ngSubmit)="onSubmit()">
  <fieldset [disabled]="isSubmitting">
    <div class="modal-body" data-cy="DIV-2477">
      <p         *ngIf="parameters?.labels?.body"
        class="text-secondary text-center"
        [innerHTML]="parameters?.labels?.body"
      ></p>

      <div class="text-center mt-5" data-cy="DIV-8c4b">
        <button type="submit" class="btn btn-panel px-5" data-cy="BUTTON-3c17">{{ parameters?.labels?.submit }}</button>
        <br>
        <button type="button" class="btn btn-link p-2" (click)="cancel()" data-cy="BUTTON-fac7">{{ parameters?.labels?.cancel }}</button>
      </div>
    </div>
  </fieldset>
</form>
