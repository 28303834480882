import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { forkJoin } from 'rxjs';

import { ModalBaseComponent } from '../../layout/modal/modal-base-component';
import { User } from '../../../auth/user';
import { ManagerService } from '../services/managers.service';
import { Sharing } from '../../sharing/entities/sharing';

@Component({
  selector: 'app-remove-shared-candidat',
  templateUrl: './remove-shared-candidat.component.html',
})
export class RemoveSharedCandidatComponent extends ModalBaseComponent<User> {
  @Input()
  public selectedShared: Sharing[] = [];

  @Input()
  public idCompany: number;
  constructor(
    bsModalRef: BsModalRef,
    private managerService: ManagerService,
    private toasterService: ToastrService,
    private readonly translate: TranslateService
  ) {
    super(bsModalRef);
  }

  public removeShare(): void {
    forkJoin(this.selectedShared.map(val => this.managerService.deleteShareManager(val.id, this.idCompany))).subscribe({
      next: () => {
        this.toasterService.success(this.translate.instant('SETTINGS.MANAGERS.CANDIDATS.ACTIONS.REMOVE_SHARE.SUCCESS'));
        this.close();
      },
      error: () => {
        this.toasterService.error(this.translate.instant('SETTINGS.MANAGERS.CANDIDATS.ACTIONS.REMOVE_SHARE.ERROR'));
        this.close();
      }
    })
  }
}
