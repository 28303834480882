<div class="text-center modal-body" data-cy="DIV-faa3">
  <h4 [translate]="'EMAILING.DESACTIVER_EMAIL_TYPE'" data-cy="H4-ee28"></h4>
  <p class="text-secondary" [translate]="'EMAILING.DESACTIVER_EMAIL_WARNING'"></p>
  <p>
    <button
      class="btn btn-success"
      (click)="this.disableConfirmClick()"
      [translate]="'EMAILING.DESACTIVER_EMAIL_TYPE'"
      data-cy="BUTTON-5124"
    ></button>
  </p>
  <p>
    <a href="javascript:void(0)" class="link" (click)="this.close()" [translate]="'EMAILING.SUPPRIMER_EMAIL_ANNULER'" data-cy="A-ab72"></a>
  </p>
</div>
