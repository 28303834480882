<div class="modal-header" data-cy="DIV-82e1">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-af25">
    <span aria-hidden="true" data-cy="SPAN-6bd0">&times;</span>
  </button>
  <div class="modal-title" data-cy="DIV-4d1d">
    <p       class="h3"
      [translate]="'RESOURCE.MODAL.EDIT.TITLE'"
    ></p>
  </div>
</div>
<form   [formGroup]="resourceGroup"
  (ngSubmit)="onSubmit()"
>
  <div class="modal-body mt-5 d-flex" data-cy="DIV-ba04">
    <section class="flex-fill mb-5 row g-2">
      <div class="col align-self-center text-center" data-cy="DIV-13fe">
        <app-resource-viewer           [resource]="media"
          [isPreviewable]="true"
        ></app-resource-viewer>
      </div>
      <div class="col" data-cy="DIV-f960">
        <div class="row g-2" data-cy="DIV-f6f7">
          <div class="col" data-cy="DIV-233f">
            <div class="mb-3" data-cy="DIV-b478">
              <label class="form-label" for="mediaName" data-cy="LABEL-31ef">
                {{'RESOURCE.MODAL.EDIT.MEDIA.NAME'|translate}} :
              </label>
              <input type="text" class="form-control" id="mediaName" value="{{ media.title }}" formControlName="title" data-cy="INPUT-b1c9">
            </div>
          </div>
        </div>
        <div class="row g-2" data-cy="DIV-9c12">
          <div class="col" *ngIf="media.type !== resourceTypeImage" data-cy="DIV-f2a9">
            <div class="mb-3" data-cy="DIV-9573">
              <label class="form-label" for="mediaType" data-cy="LABEL-62ea">
                {{'RESOURCE.MODAL.EDIT.MEDIA.TYPE'|translate}} :
              </label>
              <select                 class="form-control"
                id="mediaType"
                formControlName="type"
              >
                <option                   *ngFor="let resource of resourceTypes"
                  value="{{ resource.value }}"
                >{{ resource.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row g-2" data-cy="DIV-cf4c">
          <div class="col" data-cy="DIV-6a5c">
            <div class="mb-3" data-cy="DIV-46ae">
              <label class="form-label" for="mediaLanguage" data-cy="LABEL-8fd6">
                {{'RESOURCE.MODAL.EDIT.LANGUE_MEDIA'|translate}}
              </label>
              <ng-select                 id="mediaLanguage"
                formControlName="language"
                [items]="languages"
                bindLabel="label"
                bindValue="value"
                [clearable]="false"
              >
                <ng-template                   ng-option-tmp
                  ng-label-tmp
                  let-item="item"
                >
                  <img                     height="15"
                    width="22"
                    *ngIf="item.value"
                    src="/assets/images/flags/{{ item.value }}.png"
                  >
                  <span class="align-middle" data-cy="SPAN-e670"> {{ item.label }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row g-2" data-cy="DIV-7f5c">
          <div class="col" data-cy="DIV-a088">
            <div class="mb-3" *ngIf="shareable" data-cy="DIV-ec39">
              <label class="form-label" for="mediaShare" data-cy="LABEL-49a1">
                {{'RESOURCE.MODAL.EDIT.MEDIA.SHARE'|translate}}
              </label>
              <ng-select                 id="mediaShare"
                formControlName="share"
                [items]="shareableTo"
                bindLabel="title"
                [clearable]="false"
              >
                <ng-template                   ng-option-tmp
                  ng-label-tmp
                  let-item="item"
                >
                  <cc-user-thumbnail [thumbnail]="item.avatar"></cc-user-thumbnail>
                  <span class="align-middle ms-2" data-cy="SPAN-ee47"> {{ item.title }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="modal-footer" data-cy="DIV-e3cf">
    <div class="col-12 text-end" data-cy="DIV-6e21">
      <button class="btn ps-5 pe-5 pt-2 pb-2 btn-panel" type="submit" [translate]="'RESOURCE.MODAL.EDIT.MEDIA.SUBMIT'" [disabled]="resourceGroup.pristine || resourceGroup.invalid" data-cy="BUTTON-7d04">
        Valider
      </button>
    </div>
  </div>
</form>
