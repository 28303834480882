import { Directive, TemplateRef, ViewContainerRef, ComponentFactoryResolver, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { EmojiPopoverComponent } from './emoji-popover.component';

@Directive({
  selector: '[appEmojiInput]',
})
export class EmojiInputDirective implements OnInit {
  @Input()
  public placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'right';

  @Input()
  public emojiPadding: number = 24;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private templateRef: TemplateRef<any>,
    private elementRef: ElementRef<HTMLInputElement>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.addComponent();
  }

  addComponent(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(EmojiPopoverComponent);
    this.viewContainer.createEmbeddedView(this.templateRef);
    const parent = (this.viewContainer.element.nativeElement as HTMLElement).previousElementSibling as HTMLElement;

    const component = this.viewContainer.createComponent(factory, 1);
    component.instance.placement = this.placement;
    this.setPosition(component.location.nativeElement, parent);

    component.instance.click.subscribe((k: string) => this.insertKey(k));
  }

  setPosition(element: HTMLElement, parent: HTMLElement) {
    const offsetLeft = parent.offsetLeft - parent.parentElement.offsetLeft;
    const offsetWidth = parent.parentElement.offsetWidth - parent.offsetWidth;
    const computedPaddingRight = +window.getComputedStyle(parent).paddingRight.split('px')[0];

    element.style.position = 'absolute';
    element.style.zIndex = parent.style.zIndex + 1;
    element.style.right = offsetWidth - offsetLeft + 2 + 'px';
    element.style.top = parent.offsetTop - parent.parentElement.offsetTop + 'px';
    parent.parentElement.appendChild(element);

    parent.style.paddingRight = computedPaddingRight + this.emojiPadding + 'px';
    parent.parentElement.style.position = 'relative';
  }

  public insertKey(key: string): void {
    const input = this.elementRef.nativeElement.previousElementSibling as HTMLInputElement;
    const selectionStart = input.selectionStart;
    const selectionEnd = input.selectionEnd;

    const text = input.value;
    const updatedValue = text.slice(0, selectionStart) + key + text.slice(selectionEnd);

    this.renderer.setProperty(input, 'value', updatedValue);
    input.focus();
    input.setSelectionRange(selectionStart + key.length, selectionStart + key.length);

    input.dispatchEvent(new Event('input'));
  }
}
