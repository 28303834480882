import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MediaType } from '../media-type';
import { ModalBaseComponent } from '../../../shared/layout/modal/modal-base-component';
import { ResourceService } from '../../../shared/business/resources/resource.service';
import { FormService } from '../../../shared/business/forms/form.service';
import { MediasService } from '../medias.service';
import { RecruitmentService } from '../../../shared/business/recruitments/recruitment.service';
import { ResourceTypeEnum } from '../../../shared/business/resources/resource-type.enum';
import { Recruitment } from '../../../recruitments/entities/recruitment';
import { Form } from '../../../shared/business/forms/entities/form';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medias-delete-modal',
  templateUrl: './medias-delete-modal.component.html',
  styleUrls: ['./medias-delete-modal.component.scss'],
})
export class MediasDeleteModalComponent extends ModalBaseComponent<MediaType> implements OnInit {
  @Input()
  public mediaId;

  @Input()
  public btnConfirmClass: string = 'btn-success';

  public campaignCount = 0;

  public questionCount = 0;

  public media: MediaType;

  public mediaType = 'image';

  @Output()
  private mediaDeletedEvent = new EventEmitter();

  constructor(
    bsModalRef: BsModalRef,
    private resourceService: ResourceService,
    private formService: FormService,
    private mediaService: MediasService,
    private recruitmentService: RecruitmentService,
    private toasterService: ToastrService,
    private translate: TranslateService
  ) {
    super(bsModalRef);
  }

  public async ngOnInit() {
    await this.mediaService.get(this.mediaId).subscribe(m => {
      this.media = m;

      if (this.media.type !== ResourceTypeEnum.image) {
        this.mediaType = 'video';
      }
    });

    await this.recruitmentService.getAll().subscribe((r: Recruitment[]) => {
      this.campaignCount = r.filter((recruitment: Recruitment) => {
        return [recruitment.videoWelcome, recruitment.videoThanks].some(v => v && v.id === this.mediaId);
      }).length;
    });

    await this.formService.getAll({ withQuestions: true }).subscribe((f: Form[]) => {
      f.map((form: Form) => {
        this.questionCount += form.questions.filter(q => q.resourceId === this.mediaId).length;
      });
    });
  }

  public deleteConfirmClick() {
    this.resourceService.delete(this.mediaId).subscribe(() => {
      const content = this.translate.instant('MEDIAS.SUPPRESSION_EFFECTUE_MESSAGE', {
        title: this.media.title,
        VAR_SELECT: this.media.type === 'image' ? 'image' : 'video',
      });

      this.toasterService.success(content, this.translate.instant('MEDIAS.SUPPRESSION_EFFECTUE'));

      this.mediaDeletedEvent.emit(this.mediaId);
      this.close();
    });
  }
}
