import { Angulartics2 } from 'angulartics2';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { LoggingService } from '../logging/logging.service';
import { SentryService } from '../sentry/sentry.service';

@Injectable()
export class ErrorLoggerHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(public injector: Injector) {}

  handleError(error: Error) {
    // This can have useful content for TrackJS (and our developers)
    console.error(error);

    const sentryService = this.injector.get(SentryService);
    sentryService.captureException(error);

    // Send javascript log to server
    const loggingService = this.injector.get(LoggingService);
    loggingService.error(error.message, error);

    // Track the event in Google Analytics
    const analytics = this.injector.get(Angulartics2);
    analytics.exceptionTrack.next({
      fatal: true,
      description: error,
    });
  }
}
