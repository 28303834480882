import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { EmailTemplateRepository } from '../email-template.repository';
import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';

@Component({
  selector: 'app-emailing-delete-modal',
  templateUrl: './emailing-delete-modal.component.html',
  styleUrls: ['./emailing-delete-modal.component.scss'],
})
export class EmailingDeleteModalComponent extends ModalBaseComponent<object> implements OnDestroy {
  @Input()
  public emailId: number;

  @Output()
  public emailDeletedEvent = new EventEmitter();

  constructor(bsModalRef: BsModalRef, private emailTemplateRepository: EmailTemplateRepository) {
    super(bsModalRef);
  }

  public deleteConfirmClick() {
    this.emailTemplateRepository.delete(this.emailId).subscribe(() => this.emailDeletedEvent.emit(this.emailId));
  }

  public ngOnDestroy(): void {
    if (!this.emailDeletedEvent.closed) {
      this.emailDeletedEvent.complete();
    }
    super.ngOnDestroy();
  }
}
