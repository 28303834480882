import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { BsCustomDates } from 'ngx-bootstrap/datepicker/themes/bs/bs-custom-dates-view.component';
import { Injectable } from '@angular/core';

export function daterangepickerConfigFactory(translate: TranslateService) {
  return {
    ...new BsDaterangepickerConfig(),
    isAnimated: true,
    containerClass: 'theme-secondary',
    showPreviousMonth: true,
    adaptivePosition: true,
    selectFromOtherMonth: true,
    dateInputFormat: 'll',
    rangeInputFormat: 'll',
    ranges: [
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
        label: translate.instant('COMMON.DATE_RANGE.7_DERNIERS_JOURS'),
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 14)), new Date()],
        label: translate.instant('COMMON.DATE_RANGE.14_DERNIERS_JOURS'),
      },
      {
        value: [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()],
        label: translate.instant('COMMON.DATE_RANGE.1_DERNIER_MOIS'),
      },
      {
        value: [new Date(new Date().setMonth(new Date().getMonth() - 2)), new Date()],
        label: translate.instant('COMMON.DATE_RANGE.2_DERNIERS_MOIS'),
      },
      {
        value: [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()],
        label: translate.instant('COMMON.DATE_RANGE.3_DERNIERS_MOIS'),
      },
    ] as BsCustomDates[],
  } as BsDaterangepickerConfig;
}

@Injectable()
class FixI18nExtraction {
  constructor(translate: TranslateService) {
    translate.instant('COMMON.DATE_RANGE.7_DERNIERS_JOURS');
    translate.instant('COMMON.DATE_RANGE.14_DERNIERS_JOURS');
    translate.instant('COMMON.DATE_RANGE.1_DERNIER_MOIS');
    translate.instant('COMMON.DATE_RANGE.2_DERNIERS_MOIS');
    translate.instant('COMMON.DATE_RANGE.3_DERNIERS_MOIS');
  }
}
