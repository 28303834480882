<div class="d-flex h-100 w-100" data-cy="DIV-7bff">
  <div class="m-auto col-12 col-md-6 text-center" data-cy="DIV-6541">
    <img       src="/assets/images/403.png"
      class="w-50"
    >
    <div class="mt-5" data-cy="DIV-aeff">
      <h4 [translate]="'UNAUTHORIZED.ACCES_REFUSE'" data-cy="H4-8f45"></h4>
      <p         class="fw-semibold py-3 text-secondary"
        [innerHTML]="'UNAUTHORIZED.AUTORISATIONS_NECESSAIRES'|translate"
      ></p>
      <a *ngIf="showBackLink" class="btn btn-panel" [translate]="'UNAUTHORIZED.REVENIR_A_LACCUEIL'" href="{{ v1Url }}" data-cy="A-eea3"></a>
    </div>
  </div>
</div>
