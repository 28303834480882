export const environment = {
  production: true,
  languages: ['fr', 'en', 'it', 'de', 'es', 'nl', 'da'],
  defaultLanguage: 'en',
  recruitmentModes: [
    { key: 'external', languages: ['fr', 'en', 'it', 'de', 'es', 'nl', 'pt', 'ca', 'zh', 'da', 'hu'] },
    { key: 'school', languages: ['fr', 'en', 'it', 'es'] },
    { key: 'mobility', languages: ['fr', 'en', 'it', 'es', 'pt'] },
    { key: 'motivation', languages: ['fr', 'en'] },
    { key: 'external_informal', languages: ['en', 'de'] },
  ],
  ssoKeys: ['carrefour-es', 'grt', 'uefa', 'macif', 'orange', 'axa', 'visiotalent', 'edf', 'covea'],
};
