import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { environment } from '../environments/environment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, esLocale, frLocale, itLocale, nlLocale, daLocale } from 'ngx-bootstrap/locale';

import ngDeLocale from '@angular/common/locales/de';
import ngEsLocale from '@angular/common/locales/es';
import ngFrLocale from '@angular/common/locales/fr';
import ngItLocale from '@angular/common/locales/it';
import ngNlLocale from '@angular/common/locales/nl';
import ngDaLocale from '@angular/common/locales/da';
import { lastValueFrom } from 'rxjs';
import { DynamicConfigModule } from '@cleverconnect/ngx-dynamic-config';

import { LoggingService } from './shared/logging/logging.service';
import { LoggingModule } from './shared/logging/logging.module';

registerLocaleData(ngDeLocale);
registerLocaleData(ngEsLocale);
registerLocaleData(ngFrLocale);
registerLocaleData(ngItLocale);
registerLocaleData(ngNlLocale);
registerLocaleData(ngDaLocale);

defineLocale('de', deLocale);
defineLocale('es', esLocale);
defineLocale('fr', frLocale);
defineLocale('it', itLocale);
defineLocale('nl', nlLocale);
defineLocale('da', daLocale);

export function getLocale() {
  let language: string = navigator.language || navigator['userLanguage'];
  language = language.split('-')[0];
  return environment.languages.filter(l => l === language).length > 0 ? language : environment.defaultLanguage;
}

export function initLocale(locale: string, translateService: TranslateService) {
  return () => {
    translateService.setDefaultLang(environment.defaultLanguage);
    return lastValueFrom(translateService.use(locale));
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class LogMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private loggingService: LoggingService) {}
  private missingTranslation = new Set<string>();

  handle(params: MissingTranslationHandlerParams) {
    const translateService = params.translateService;
    if (!this.missingTranslation.has(params.key)) {
      this.missingTranslation.add(params.key);
      const warning = `[${translateService.currentLang}] missing translation: ${params.key}`;
      console.warn(warning);
      this.loggingService.warn(warning);
    }
    return translateService.parser.interpolate(
      translateService.parser.getValue(translateService.translations[translateService.defaultLang], params.key),
      params.interpolateParams
    );
  }
}

export function missingTranslationFactory(loggingService: LoggingService) {
  return new LogMissingTranslationHandler(loggingService);
}

@NgModule({
  imports: [
    DynamicConfigModule,
    LoggingModule,
    TranslateModule.forRoot({
      useDefaultLang: false,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationFactory,
        deps: [LoggingService],
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useFactory: getLocale },
    { provide: APP_INITIALIZER, useFactory: initLocale, deps: [LOCALE_ID, TranslateService], multi: true },
  ],
})
export class AppTranslationModule {}
