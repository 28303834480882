import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyManagersComponent } from './my-managers/my-managers.component';
import { MyCandidatsComponent } from './my-candidats/my-candidats.component';
import { ManagerResolver } from '../../shared/managers/resolvers/manager.resolver';

const managersRoutes: Routes = [
  { path: '', component: MyManagersComponent },
  { path: ':id', component: MyCandidatsComponent, resolve: { managerSelected: ManagerResolver } },
];

@NgModule({
  imports: [RouterModule.forChild(managersRoutes)],
  exports: [RouterModule, HttpClientModule],
})
export class ManagersRoutingModule {}
