<div class="btn-group" dropdown data-cy="DIV-b4d5">
  <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle text-uppercase" aria-controls="dropdown-basic" data-cy="BUTTON-1439">
    <img       class="flag"
      src="//visiotalent-emails.s3.amazonaws.com/flags/{{currentLanguage}}.png"
    >{{currentLanguage}} <span class="caret" data-cy="SPAN-1d18"></span>
  </button>
  <ul     id="dropdown-basic"
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right text-uppercase"
    role="menu"
    aria-labelledby="button-basic"
  >
    <li       role="menuitem"
      *ngFor="let language of languages"
    ><a class="dropdown-item" (click)="setLanguage(language)" data-cy="A-6f80">
        <img class="flag" src="//visiotalent-emails.s3.amazonaws.com/flags/{{language}}.png" >{{language}}</a>
    </li>
  </ul>
</div>
