/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Media } from 'src/app/shared/business/resources/media';
import { ResourceStatusEnum } from 'src/app/shared/business/resources/resource-status.enum';

import { ResourceViewerModalService } from './modal-viewer-modal/resource-viewer-modal.service';

@Component({
  selector: 'app-resource-viewer',
  templateUrl: './resource-viewer.component.html',
  styleUrls: ['./resource-viewer.component.scss'],
})
export class ResourceViewerComponent implements OnDestroy, OnInit {
  @Input('isEditable')
  private _isEditable?: boolean;
  public get isEditable(): boolean {
    return this._isEditable ?? this.edit.observed;
  }

  @Input('isDeletable')
  private _isDeletable?: boolean;
  public get isDeletable(): boolean {
    return this._isDeletable ?? this.delete.observed;
  }

  private _resource: Media;
  public get resource(): Media {
    return this._resource;
  }

  @Input()
  public set resource(v: Media) {
    this._resource = v;
    this.setResource();
  }

  @Input()
  public mini = false;

  public isNew = false;

  public isError = false;

  public miniClass = '';

  @Input()
  public isLoading: boolean = false;

  @Input()
  isPreviewable: boolean = true;
  @Output()
  delete = new EventEmitter<void>();
  @Output()
  edit = new EventEmitter<void>();

  public isConverted: boolean = false;

  private timeout: number;

  private tries: number = 0;

  private readonly timeouts = {
    'video-welcome': 5000,
    'video-thanks': 5000,
    'video-question': 5000,
    image: 2500,
  };

  constructor(private resourceViewerModalService: ResourceViewerModalService) {}

  ngOnInit(): void {
    this.miniClass = this.setMiniClass();
  }

  public setMiniClass() {
    let pointerEvent = '';

    if (!this.isPreviewable) {
      pointerEvent = 'pointer-events-disabled ';
    }
    if (this.mini) {
      return pointerEvent + 'h-100 rounded mx-auto';
    }

    return pointerEvent + 'w-100';
  }

  setResource(): void {
    if (!this.resource) {
      return;
    }

    if (this.resource.status === ResourceStatusEnum.converted) {
      this.isConverted = true;
    } else {
      this.isNew = new Date() < new Date(new Date((this.resource as any).createdAt).getTime() + 60 * 60 * 1000);
      this.loadImage(this.resource.previewUrl || this.resource.url);
    }
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  loadImage(url: string) {
    const image = new Image();
    image.src = `${url}`;
    image.onload = this.onLoad.bind(this);
    image.onerror = this.onError.bind(this);
  }

  private onError() {
    this.timeout = window.setTimeout(
      () => this.tries < 10 && this.loadImage(this.resource.previewUrl || this.resource.url),
      (this.timeouts[this.resource.type] || this.timeouts.image) * ++this.tries
    );
  }

  private onLoad() {
    this.timeout = null;
    this.isConverted = true;
  }

  onEdit() {
    this.edit.emit(undefined);
  }

  onDelete() {
    this.delete.emit(undefined);
  }

  isErrored() {
    this.isError = true;
    if (this.mini) {
      this.isNew = false;
      this.isConverted = false;
    }
  }

  openModal(event) {
    if (this.isPreviewable) {
      event.stopPropagation();
    }

    if (!this.isPreviewable || this.isError) {
      return false;
    }

    this.resourceViewerModalService.openModal(this.resource);
    return false;
  }
}
