import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { RolesEnum } from 'src/app/auth/roles.enum';
import { Sharing } from 'src/app/shared/sharing/entities/sharing';
import { SharingRecipientType } from 'src/app/shared/sharing/entities/sharing-recipient-type.enum';
import { UntypedFormControl, Validators } from '@angular/forms';
import { User } from 'src/app/auth/user';

import { CompanyTeamService } from '../../../../panel/companies/company-teams/services/company-team.service';
import { EmailTemplate } from '../entities/email-template';
import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';
import { SubmittableFormGroup } from '../../../form-controls/submittable-form-group';

@Component({
  selector: 'app-duplicate-and-share-mail-modal',
  templateUrl: './emailing-duplicate-and-share-modal.component.html',
  styleUrls: ['./emailing-duplicate-and-share-modal.component.scss'],
})
export class EmailingDuplicateAndShareModalComponent extends ModalBaseComponent<EmailTemplate> implements OnInit {
  public email: EmailTemplate;
  public companyId: number;
  public shareForm!: SubmittableFormGroup;
  public teams: Array<{ value: number; label: string }> = [];
  public isCompanyAdministrator!: boolean;

  constructor(bsModalRef: BsModalRef, private route: ActivatedRoute, private companyTeamService: CompanyTeamService) {
    super(bsModalRef);
  }

  ngOnInit(): void {
    const me = this.route.children[0].snapshot.data.me as User;
    this.companyId = this.companyId || me.companyId;
    this.isCompanyAdministrator =
      me.roles.some(r => r === RolesEnum.csm || r === RolesEnum.admin) || me.administratedCompanies.some(c => c.id == this.companyId);

    this.initTeams();
    this.initForm();
  }

  public submit(): void {
    this.shareForm.markAsSubmitted();
    if (this.shareForm.invalid) {
      return;
    }

    const sharings = this.shareForm.get('sharings').value;
    if (sharings && sharings.length) {
      this.email.sharings = sharings.map(
        s =>
          ({
            recipientId: s,
            recipientType: SharingRecipientType.team,
          } as Sharing)
      );
    } else {
      this.email.sharings = [
        {
          recipientType: SharingRecipientType.company,
          recipientId: this.companyId.toString(),
        } as Sharing,
      ];
    }
    this.close(this.email);
  }

  private initTeams(): void {
    this.companyTeamService.getAll(this.companyId).subscribe(teams => {
      this.teams = teams.data.map(t => ({ value: t.id, label: t.name }));
    });
  }

  private initForm(): void {
    this.shareForm = new SubmittableFormGroup({
      sharings: new UntypedFormControl([], !this.isCompanyAdministrator ? Validators.required : undefined),
    });
  }
}
