import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

import { Team } from '../../../shared/entities/team';
import { User } from '../../../../auth/user';

@Injectable({ providedIn: 'root' })
export class CompanyTeamService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private config: Config;
  private _getAllData$: Observable<{ data: Team[]; total: number }>[] = [];

  constructor(
    private http: HttpClient,
    configService: DynamicConfigService,
  ) {
    this.config = configService.get<Config>();
  }

  public get(teamId: number, companyId: number): Observable<Team> {
    return this.http.get<Team>(`${this.getUrl(companyId)}/${teamId}`);
  }

  public getAllTeams(): Observable<{ data: Team[]; total: number }> {
    return this.http.get<{ data: Team[]; total: number }>(`${this.config.apiUrl}/teams`);
  }

  public getByIds(teamIds: number[]): Observable<Team[]> {
    const params = {};
    params['teamIds'] = teamIds;
    return this.http.get<Team[]>(`${this.config.apiUrl}/teams/search?teamsIds=${teamIds}`);
  }

  public getAll(
    companyId: number,
    filters: { filter?: string[]; or?: string[] } = {},
    join: string[] = [],
  ): Observable<{ data: Team[]; total: number }> {
    const params = {};
    if (filters.or) {
      params['or[]'] = filters.or;
    }
    if (filters.filter) {
      params['filter[]'] = filters.filter;
    }
    if (join) {
      params['join[]'] = join;
    }
    const key = `${companyId}_${JSON.stringify(params)}`;
    if (!this._getAllData$[key])
      this._getAllData$[key] = this.http.get<{ data: Team[]; total: number }>(this.getUrl(companyId), { params }).pipe(shareReplay(1));

    return this._getAllData$[key];
  }

  public save(team: Team) {
    return this.http.post<Team>(this.getUrl(team.companyId), team, { headers: this.headers }).pipe(catchError(err => throwError(err)));
  }

  public bulk(teams: Team[], companyId: number): Observable<Team[]> {
    if (!teams?.length) return of([]);

    return this.http.post<Team[]>(`${this.getUrl(companyId)}/bulk`, teams, { headers: this.headers });
  }

  private getUrl(companyId: number): string {
    return `${this.config.apiUrl}/panel/companies/${companyId}/teams`;
  }

  public updateMembers(team: Team) {
    const apiTeam = new Team();
    apiTeam.members = team.members.map(m => ({ id: m.id }) as User);
    return this.http.patch<Team>(`${this.getUrl(team.companyId)}/${team.id}`, apiTeam, {
      headers: this.headers,
    });
  }

  public delete(teamId: number, companyId: number) {
    return this.http.delete<Team>(`${this.getUrl(companyId)}/${teamId}`);
  }
}
