import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { CompanyTeamService } from 'src/app/panel/companies/company-teams/services/company-team.service';
import { CompanyUserService } from 'src/app/panel/companies/company-users/services/company-user.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, forkJoin, map, of, shareReplay, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CompanyEntity } from './company-entity';
import { SharingRecipientType } from '../../sharing/entities/sharing-recipient-type.enum';

@Component({
  selector: 'app-select-company-entity',
  templateUrl: './select-company-entity.component.html',
  styles: [':host{display: contents}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCompanyEntityComponent),
      multi: true,
    },
  ],
})
export class SelectCompanyEntityComponent implements ControlValueAccessor {
  private _company: number;
  public get company(): number {
    return this._company;
  }
  @Input()
  public set company(value: number) {
    if (this._company == value) {
      return;
    }
    this._company = value;
    this.setObservables();
  }
  @Input() groupResults: boolean = false;
  @Input() clearable: boolean = true;
  @Input() isInvalid: boolean = false;
  @Input() placeholder: string = undefined;

  private _loadTeams: boolean = false;
  public get loadTeams(): boolean {
    return this._loadTeams;
  }
  @Input()
  public set loadTeams(value: boolean) {
    if (this._loadTeams == value) {
      return;
    }
    this._loadTeams = value;
    this.getValues();
  }

  private _loadCompany: boolean = false;
  public get loadCompany(): boolean {
    return this._loadCompany;
  }
  @Input()
  public set loadCompany(value: boolean) {
    if (this._loadCompany == value) {
      return;
    }
    this._loadCompany = value;
    this.getValues();
  }

  private _loadUsers: boolean = false;
  public get loadUsers(): boolean {
    return this._loadUsers;
  }
  @Input()
  public set loadUsers(value: boolean) {
    if (this._loadUsers == value) {
      return;
    }
    this._loadUsers = value;
    this.getValues();
  }

  @Input() multiple: boolean = true;
  @Input() enableThumbnail: boolean = true;
  @Input() group: boolean = true;
  @Input() labelForId: string;
  public loading: boolean = true;
  public items$: Observable<CompanyEntity[]>;
  public value: CompanyEntity | CompanyEntity[];

  private teams$: Observable<CompanyEntity[]>;
  private users$: Observable<CompanyEntity[]>;
  private compagnies$: Observable<CompanyEntity[]>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  private onChange = (_value: CompanyEntity | CompanyEntity[]) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};
  @Output()
  public isLoading: EventEmitter<boolean> = new EventEmitter();

  public touched: boolean = false;

  public isDisabled: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private teamService: CompanyTeamService,
    private userService: CompanyUserService,
  ) {}
  writeValue(obj: CompanyEntity | CompanyEntity[]): void {
    this.items$.subscribe(items => {
      if (!obj) {
        this.value = null;
      } else if (Array.isArray(obj)) {
        this.value = items.filter(i => obj.some(o => i.type == o.type && i.id == o.id));
      } else {
        this.value = items.find(i => i.type == obj.type && i.id == obj.id);
      }
      this.cd.detectChanges();
    });
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public customSearch(search: string, value: CompanyEntity) {
    return ['title'].some(k => value[k].toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  public onInputChange(value: CompanyEntity | CompanyEntity[]) {
    if (!value) {
      this.onChange(null);
    } else if (Array.isArray(value)) {
      this.onChange(value.map(v => ({ id: v.id, type: v.type })));
    } else {
      this.onChange({ id: value.id, type: value.type });
    }
  }

  private getValues() {
    const datas: Observable<CompanyEntity[]>[] = [];

    if (this.loadCompany && this.compagnies$) datas.push(this.compagnies$);
    if (this.loadTeams && this.teams$) datas.push(this.teams$);
    if (this.loadUsers && this.users$) datas.push(this.users$);

    this.loading = true;
    this.isLoading.emit(this.loading);
    this.items$ = forkJoin(datas).pipe(
      map(values => values.reduce((acc, v) => [...acc, ...v], [])),
      tap(() => {
        this.loading = false;
        this.isLoading.emit(this.loading);
      }),
    );
  }

  private setObservables() {
    this.teams$ = this.teamService.getAll(this.company).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      map(({ data }) => data),
      map(teams =>
        teams.map(t => ({
          type: SharingRecipientType.team,
          id: t.id,
          title: t.name,
          avatar: { url: '', label: t.name },
          disabled: false,
        })),
      ),
    );
    this.users$ = this.userService.getAll(this.company).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      map(({ data }) => data),
      map(users =>
        users.map(u => ({
          type: SharingRecipientType.user,
          id: u.id,
          title: `${u.firstname} ${u.lastname}`,
          avatar: { url: u.avatar, label: `${u.firstname} ${u.lastname}` },
          disabled: false,
        })),
      ),
    );
    this.compagnies$ = of([
      {
        id: this.company,
        type: SharingRecipientType.company,
        title: this.translate.instant('COMMON.TEAMS.ALL'),
        avatar: { label: this.translate.instant('COMMON.TEAMS.ALL') },
      },
    ]);
  }
}
