<app-common-header title="Réglages" [title]="'MEDIATHEQUE.REGLAGES' | translate"></app-common-header>

<div class="container" data-cy="DIV-9332">
  <div class="row g-2 mt-5 mb-5" data-cy="DIV-5564">
    <app-tiles
      class="col-12 col-md-6 col-lg-3 mt-3 mb-4"
      title="Médiathèque"
      [title]="'MEDIATHEQUE.TITRE' | translate"
      content="Gérer vos contenus vidéos et images."
      [content]="'MEDIATHEQUE.GERER_VIDEO_IMAGE' | translate"
      destination="medias"
      previewUrl="/assets/images/settings/home/medias.svg"
    ></app-tiles>
    <app-tiles
      class="col-12 col-md-6 col-lg-3 mt-3 mb-4"
      title="Emails Type"
      [title]="'EMAILING.TITRE' | translate"
      content="Gérer vos emails d’invitation, réponses négatives, positives et autres messages."
      [content]="'EMAILING.GERER_EMAILS' | translate"
      destination="emailing"
      previewUrl="/assets/images/settings/home/emails.svg"
    ></app-tiles>
    <app-tiles
      class="col-12 col-md-6 col-lg-3 mt-3 mb-4"
      title="Statuts personnalisés"
      [title]="'STATUTS.TITRE' | translate"
      content="Gérer vos statuts personnalisés"
      [content]="'STATUTS.GERER_STATUTS' | translate"
      destination="status"
      previewUrl="/assets/images/settings/home/status.svg"
    ></app-tiles>
  </div>
</div>
