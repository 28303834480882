<a class="mb-3 row g-2 text-decoration-none" [class.readonly]="readonly" title [routerLink]="readonly ? null : link" [queryParams]="linkParams" queryParamsHandling="merge" data-cy="A-84ce">
  <label class="ps-3 ps-md-5 col-12 col-sm-4 col-form-label fw-normal text-secondary form-label" data-cy="LABEL-c113"> {{ label }}&nbsp;:
  </label>
  <div class="col-12 col-sm-8 pe-3 pe-md-5" data-cy="DIV-a2e4">
    <span class="form-control-plaintext" data-cy="SPAN-f9d4">
      <div *ngIf="!readonly" class="link text-primary" data-cy="DIV-3526">
        <i class="material-icons" data-cy="I-c312">edit</i>
      </div>
      <ng-content></ng-content>
    </span>
  </div>
</a>
