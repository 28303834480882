import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CcTableModule } from '@cleverconnect/ngx-bootstrap-table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxEditorModule } from 'ngx-editor';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { EditorHtmlComponent } from './editor-html/editor-html.component';
import { EmailTemplatesComponent } from '../../../settings/email-templates/email-templates.component';
import { EmailingDeleteModalComponent } from './emailing-delete-modal/emailing-delete-modal.component';
import { EmailingDetailsModalComponent } from './emailing-details-modal/emailing-details-modal.component';
import { EmailingDisableModalComponent } from './emailing-disable-modal/emailing-disable-modal.component';
import { EmailingDuplicateAndShareModalComponent } from './emailing-duplicate-and-share-modal/emailing-duplicate-and-share-modal.component';
import { EmailingPreviewModalComponent } from './emailing-preview-modal/emailing-preview-modal.component';
import { EmailingResetModalComponent } from './emailing-reset-modal/emailing-reset-modal.component';
import { EmailingSearchFormComponent } from './emailing-search-form/emailing-search-form.component';
import { KeywordHighlightPipe } from './keyword-highlight/keyword-highlight.pipe';
import { SharedModule } from '../../shared.module';

const components = [
  EmailingSearchFormComponent,
  EmailingDetailsModalComponent,
  EmailingPreviewModalComponent,
  EmailingDuplicateAndShareModalComponent,
  KeywordHighlightPipe,
  EditorHtmlComponent,
  EmailingDeleteModalComponent,
  EmailingResetModalComponent,
  EmailingDisableModalComponent,
  EmailTemplatesComponent,
];

const modules = [CommonModule, SharedModule, NgxEditorModule, CcTableModule, PaginationModule, BsDropdownModule, TooltipModule, TabsModule];
@NgModule({
  imports: [...modules],
  exports: [...modules, ...components],
  declarations: [...components],
})
export class SharedEmailingModule {}
