<app-popover [content]="sliderFormModal">
  <app-badge-parameter>
    {{title}} :
    <span class="fw-normal text-time align-middle" translate [translateParams]="{count: control.value, INTERPOLATION: control.value|chronometer}" data-cy="SPAN-601c">QUESTIONNAIRE.CHRONOMETER.VALUE</span>
  </app-badge-parameter>
</app-popover>


<ng-template #sliderFormModal>
  <div class="px-2 response-time-form-modal" data-cy="DIV-942b">
    <label class="form-label mt-2" for="formControlRange" data-cy="LABEL-d6ec">{{ title }}</label>
    <div class="small mt-2" *ngIf="description" data-cy="DIV-7615">{{ description }}</div>
    <div class="row g-2 align-items-center my-3 pe-2 mt-0" data-cy="DIV-8812">
      <app-chronometer-slider
        [min]="min"
        [max]="max"
        [step]="step"
        [isUnlimited]="isUnlimited"
        [control]="control"
        [selectedBarOptions]="selectedBarOptions"
      ></app-chronometer-slider>
    </div>
    <div class="small mb-2 mt-3" data-cy="DIV-7616"
         [innerHTML]="tranlateKey | translate">
    </div>
  </div>
</ng-template>
