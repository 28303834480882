import { AuthService } from 'src/app/auth/auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpUserIdInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'USER-ID';
    const userId = this.authService.userId;
    if (userId && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, userId.toString()) });
    }
    return next.handle(req);
  }
}
