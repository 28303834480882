import Generator from 'generate-password-browser';
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubmittableFormGroup } from 'src/app/shared/form-controls/submittable-form-group';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { fromBuffer } from 'file-type/browser';
import { ngfDrop } from 'angular-file';

import { FormEditInput, FormEditInputPassword, FormEditInputPicture, FormModalParameters } from './form-modal-parameters';
import { ImageResizerModalComponent } from '../../image-resizer-modal/image-resizer-modal.component';
import { ModalBaseComponent } from '../modal-base-component';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent extends ModalBaseComponent<any> implements AfterViewInit {
  public requiredValidator = Validators.required;
  private _isSubmitting = false;
  public get isSubmitting(): boolean {
    return this._isSubmitting;
  }
  public set isSubmitting(value: boolean) {
    this._isSubmitting = value;
    if (value) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public disabled = false;

  private _parameters: FormModalParameters;
  public get parameters(): FormModalParameters {
    return this._parameters;
  }
  @Input()
  public set parameters(value: FormModalParameters) {
    this._parameters = value;

    value.inputs.forEach(i => {
      if (i.type === 'select' && i.options && i.options.items$) {
        i.options.items$.subscribe(r => (i.options.items = r));
      }
    });

    this.disabled = value.disabled;

    this.formControl = this.generateSubmittableFormGroup(value && Array.isArray(value.inputs) ? value.inputs : []);

    if (value.defaultValues) {
      this.formControl.patchValue(this.generateDefaultValues(value));
    }
    this.hasNotBtnCancel = value.hasNotBtnCancel;
  }

  public formControl: SubmittableFormGroup;
  public hasNotBtnCancel: boolean = false;

  constructor(
    bsModalRef: BsModalRef,
    private readonly elementRef: ElementRef,
    private readonly modalService: BsModalService,
    private readonly toasterService: ToastrService,
    private readonly translate: TranslateService
  ) {
    super(bsModalRef);
  }

  public onSubmit() {
    this.formControl.markAsSubmitted(this.elementRef);
    if (this.formControl.invalid) {
      return false;
    }

    this.isSubmitting = true;
    this.onClose.next(this.generateResults());
  }

  ngAfterViewInit(): void {
    const element = (this.elementRef.nativeElement as HTMLElement).querySelector('[autofocus]') as HTMLInputElement;
    if (element) {
      setTimeout(() => element.focus(), 50);
    }
  }

  public isRequired(formControl: UntypedFormControl, input: FormEditInput) {
    const control = formControl?.get(input.key);

    return control?.validator?.('' as any)?.required || control?.validator?.(control)?.required;
  }

  public generateSubmittableFormGroup(input: FormEditInput[]): SubmittableFormGroup {
    return new SubmittableFormGroup(
      input
        .filter(v => v.key)
        .reduce(
          (acc, i) => ({
            ...acc,
            [i.key]: this.generateFormControl(i),
          }),
          {}
        )
    );
  }

  private generateDefaultValues(parameters: FormModalParameters) {
    const defaultValues = parameters.defaultValues || {};
    return parameters.inputs.reduce((acc, input) => ({ ...acc, [input.key]: this.generateDefaultValue(input, defaultValues) }), {});
  }

  private generateDefaultValue(input: FormEditInput, defaultValues: object) {
    switch (input.type) {
      case 'checkboxes':
        const defaultValue = defaultValues[input.key] || [];
        return input.options.items.map(i => defaultValue.indexOf(i.key) > -1);
      default:
        return defaultValues[input.key];
    }
  }

  private generateFormControl(input: FormEditInput) {
    switch (input.type) {
      case 'checkboxes':
        return new UntypedFormArray(
          (input.options.items || []).map(_ => new UntypedFormControl()),
          input.validation
        );
      default:
        return new UntypedFormControl(undefined, input.validation);
    }
  }

  private generateResults() {
    return this._parameters.inputs
      .filter(input => !!input.key)
      .reduce((acc, input) => ({ ...acc, [input.key]: this.generateResult(input) }), {});
  }

  private generateResult(input: FormEditInput) {
    switch (input.type) {
      case 'checkboxes':
        return this.formControl
          .get(input.key)
          .value.map((v: boolean, i: number) => (v ? input.options.items[i].key : undefined))
          .filter(v => !!v);
      default:
        return this.formControl.get(input.key).value;
    }
  }

  public customSearch(values: string[]) {
    return (search: string, item: any) => values.some(k => item[k].toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  public editImage(input: FormEditInputPicture) {
    if (!this.formControl.get(input.key).value) {
      return;
    }
    this.upload(this.formControl.get(input.key).value.base, input);
  }

  public deleteImage(input: FormEditInputPicture) {
    this.formControl.get(input.key).patchValue({ url: undefined, base: undefined });
  }

  public async upload(url: string, input: FormEditInputPicture, dragAndDrop?: ngfDrop) {
    if (dragAndDrop) {
      const buffer = await dragAndDrop.file.arrayBuffer();
      const mimeType = (await fromBuffer(buffer)).mime;

      if (!this.acceptType(input.options?.['accept'], mimeType)) {
        this.onUploadInvalidFile();
        return;
      }
    }

    if (input.isEditable === false) {
      const base64 = await this.resizeImage(url, null, 100);
      this.formControl.get(input.key).patchValue({ url: base64, base: url });
      return;
    }

    const modal = this.modalService.show(ImageResizerModalComponent, {
      class: 'modal-dialog-centered modal-secondary',
      initialState: { image: url, options: input.options ? input.options.resizer : {} },
    }).content as ModalBaseComponent<Blob>;

    modal.onClose.subscribe({
      complete: () => {
        if (dragAndDrop) {
          dragAndDrop.lastBaseUrl = dragAndDrop.lastBaseUrl = dragAndDrop.file = null;
          dragAndDrop.files.splice(0, dragAndDrop.files.length);
        }
      },
      next: base64 => this.formControl.get(input.key).patchValue({ url: base64, base: url }),
    });
  }

  public onUploadInvalidFile() {
    this.toasterService.error(this.translate.instant('MODAL.RESIZER.ERROR.CONTENT'), this.translate.instant('MODAL.RESIZER.ERROR.TITLE'));
    return;
  }

  public generatePassword(input: FormEditInputPassword) {
    this.formControl.get(input.key).patchValue(
      Generator.generate({
        length: 12,
        numbers: true,
        symbols: true,
        uppercase: true,
        strict: true,
      })
    );
    return false;
  }

  private acceptType(accept: string, mimetype: string, ext?: string): boolean {
    if (!accept) {
      return true;
    }
    const defs = accept.split(',');
    let regx;
    let acceptRegString;
    for (let x = defs.length - 1; x >= 0; --x) {
      // Escapes dots in mimetype
      acceptRegString = defs[x];
      // trim
      acceptRegString = acceptRegString.replace(/(^\s+|\s+$)/g, '');
      // Escapes stars in mimetype
      acceptRegString = acceptRegString.replace(/\*/g, '.*');
      // let acceptReg = '^((' + acceptRegString
      // acceptReg = acceptReg.replace(/,/g,')|(') + '))$'
      // try by mime
      regx = new RegExp(acceptRegString, 'gi');
      if (mimetype.search(regx) >= 0) {
        return true;
      }
      // try by ext
      if (acceptRegString.substring(0, 1) == '.') {
        acceptRegString = '\\' + acceptRegString; // .substring(1, acceptRegString.length-1)//remove dot at front
        regx = new RegExp(acceptRegString + '$', 'i');
        if ((ext || mimetype).search(regx) >= 0) {
          return true;
        }
      }
    }
    return false;
  }

  private resizeImage(src, maxWidth, maxHeight): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
        let ratio = 1;
        if (maxWidth > 0 && width > 0 && width > maxWidth) {
          ratio = maxWidth / width;
        }
        if (maxHeight > 0 && height > 0 && height > maxHeight) {
          ratio = maxHeight / height;
        }
        height *= ratio;
        width *= ratio;
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        res(ctx.canvas.toDataURL());
      };
      img.onerror = error => rej(error);
    });
  }
}
