import { Directive, Input, ElementRef, Renderer2, OnChanges } from '@angular/core';

@Directive({
  selector: '[appKeywordHighlight]',
})
export class KeywordHighlightDirective implements OnChanges {
  @Input()
  appKeywordHighlight: { [key: string]: string } = {};

  @Input()
  openBrace: string = '[';

  @Input()
  closeBrace: string = ']';

  @Input()
  highlightClasses: string = 'badge bg-primary';

  @Input()
  public content: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    this._highlightLabel();
  }

  private _highlightLabel() {
    if (!this.canHighlight) {
      return;
    }

    const keywords = Object.keys(this.appKeywordHighlight).reduce(
      (acc, k) => ({ ...acc, [`${this.openBrace}${k.toLowerCase()}${this.closeBrace}`]: this.appKeywordHighlight[k] }),
      {}
    );

    const re = new RegExp(
      Object.keys(keywords)
        .map(k => `${this.escapeRegExp(k)}`)
        .join('|'),
      'gi'
    );

    const updatedLabel = this.content.replace(re, k => {
      return this.convertKeyword(keywords[k.toLowerCase()]);
    });

    this.setInnerHtml(updatedLabel);
  }

  private get canHighlight() {
    return !!(this.appKeywordHighlight && Object.keys(this.appKeywordHighlight).length && this.content);
  }

  private escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  private convertKeyword(keyword: string): string {
    return `<span class="${this.highlightClasses}">${keyword}</span>`;
  }

  private setInnerHtml(html) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html);
  }
}
