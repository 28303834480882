import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ActivationEnd, Event, NavigationEnd, Router } from '@angular/router';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from '../shared/config/config';
import { Company } from '../panel/companies/company';
import { User } from '../auth/user';
import { RolesEnum } from '../auth/roles.enum';
import { MenuItem } from './menu-item';
import { Subscription } from 'rxjs';
import { ModalService } from '../shared/layout/modal/modal.service';
import { QuitComponent } from '../shared/layout/quit/quit.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnDestroy {
  public currentRoute: string;
  public menus = [];
  public company: Company;
  public companyIdSelected: number;
  public me: User;
  public routeQueryParams$: Subscription;
  public isEdited = false;

  sub: Subscription;

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.routeQueryParams$) {
      this.routeQueryParams$.unsubscribe();
    }
  }
  constructor(
    private configService: DynamicConfigService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private modalService: ModalService,
  ) {
    this.sub = this.router.events.subscribe({
      next: (e: Event) => {
        if (e instanceof ActivationEnd) {
          this.company = e.snapshot.data['company'] ? e.snapshot.data['company'] : this.company;
          this.me = e.snapshot.data['me'] ? e.snapshot.data['me'] : this.me;
          this.companyIdSelected = e.snapshot.params['companyId'] ? e.snapshot.params['companyId'] : this.companyIdSelected;
          if (!this.company && (this.me.isCsm || this.me.isAdmin || this.me.isAdminTeam)) {
            this.company = this.me.company;
          }
        }

        if (e instanceof NavigationEnd) {
          this.currentRoute = e.url;
          this.initMenu();
          this.menus.forEach(item => this.refreshActiveItem(item));
        }
      },
    });
  }

  public initMenu(): void {
    this.menus = [];
    Object.values(routes).forEach((val: MenuItem) => {
      val.children = [];
      if (!val.idParent && this.hasAccess(val)) {
        this.menus.push(val);
      } else {
        const parent = routes[val.idParent];
        if (this.hasAccess(val)) {
          if (val.id === routes.ADMIN_COMPANY_NAME.id && this.company) {
            val.title = this.company.name;
          }
          parent.children.push(val);
        }
      }
    });
  }

  public hasAccess(item: MenuItem): boolean {
    if (item.id === routes.LIVE.id) {
      return (
        ((this.me.isCsm || this.me.isAdminTeam || this.me.isAdmin) && this.me.options.liveEnabled) ||
        !(this.me.isCsm || this.me.isAdminTeam || this.me.isAdmin)
      );
    }

    if (item.id === routes.ADMIN.id && !this.company) {
      return false;
    }

    if (!item.roles || item.roles.length === 0) {
      return true;
    }

    return item.roles.some((valPage: RolesEnum) => this.me.roles.includes(valPage));
  }

  public refreshActiveItem(item: MenuItem): void {
    item.hidden = true;
    if (item.url && Array.isArray(item.url) && !item.strictUrl && this.currentRoute.startsWith(`/${this.editUrl(item.url).join('/')}`)) {
      item.hidden = false;
      this.refreshActiveItemParent(item);
    } else if (item.url && Array.isArray(item.url) && item.strictUrl && this.currentRoute === `/${this.editUrl(item.url).join('/')}`) {
      item.hidden = false;
      this.refreshActiveItemParent(item);
    } else if (!item.children || item.children.length === 0) {
      return;
    } else {
      item.children.forEach(val => this.refreshActiveItem(val));
    }
  }

  public refreshActiveItemParent(item: MenuItem): void {
    if (!item.idParent) {
      return;
    }
    const routeParent = routes[item.idParent];
    routeParent.hidden = false;
    this.refreshActiveItemParent(routeParent);
  }

  public showOrHideItem(menu: MenuItem, ssMenu?: MenuItem): void {
    this.selectItem(menu, ssMenu);
    this.openQuitModal(ssMenu, menu);
  }

  public selectItem(menu: MenuItem, ssMenu?: MenuItem): void {
    if (ssMenu) {
      menu.hidden = false;
      ssMenu.hidden = !ssMenu.hidden;
    } else {
      menu.hidden = !menu.hidden;
    }
  }

  public openQuitModal(ssMenu?: MenuItem, menu?: MenuItem): void {
    if (!ssMenu || !ssMenu.url) {
      return;
    }

    const urlCheckBeforeQuit = [
      'recruitments/create/customization',
      'recruitments/create/settings',
      'forms/create/questions',
      'forms/create/settings',
      'parameters?isEdited=true',
    ];
    if (urlCheckBeforeQuit.some(elm => this.router.url.includes(elm))) {
      const modalComponent = this.modalService.show(QuitComponent, {
        backdrop: 'static',
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          redirectUrl: 'dashboard',
        },
      }).content as QuitComponent;
      modalComponent.onClose.subscribe({
        next: close => {
          if (close === 'true') {
            this.goToUrl(ssMenu);
          } else {
            this.selectItem(menu, ssMenu);
          }
        },
      });
    } else {
      this.goToUrl(ssMenu);
    }
  }

  public goToUrl(item: MenuItem): void {
    this.isEdited = false;
    if (!item || !item.url) {
      return;
    }

    if (Array.isArray(item.url)) {
      this.router.navigate(this.editUrl(item.url));
      return;
    }

    const config = this.configService.get<Config>();
    if (item.id === routes.LIVE.id && this.me.options.liveEnabled) {
      this.document.location.href = `${config.liveUrl}`;
      return;
    }
    this.document.location.href = `${config.v1Url}/${item.url}`;
  }

  public editUrl(urlRoutes: string[]): Array<string> {
    let url = [];
    Object.assign(url, urlRoutes);
    if (!this.me.isCsm && url.includes(idCompanyLink) && url.includes('companies')) {
      url = url.filter((val: string) => val !== idCompanyLink && val !== 'companies');
    }

    if (url.includes(idCompanyLink)) {
      const index = url.indexOf(idCompanyLink);
      url[index] = `${this.companyIdSelected || this.me.company.id}`;
    }

    return url;
  }
}

export const idCompanyLink = ':companyId';
export const routes: any = {
  TDB: {
    id: 'TDB',
    class: 'border-bottom-grey',
    url: `home`,
    title: 'NAVIGATION.TDB',
    classIcon: 'icon_svg icon_svg_home',
    roles: [],
    translate: true,
  } as MenuItem,
  DIFF: {
    id: 'DIFF',
    class: 'border-bottom-grey',
    title: 'NAVIGATION.DIFF',
    children: [],
    classIcon: 'visiotalent-icon visiotalent-icon-profile',
    translate: true,
  } as MenuItem,
  LIVE: {
    id: 'LIVE',
    class: 'border-bottom-grey',
    url: 'products/live',
    title: 'NAVIGATION.LIVE',
    classIcon: 'visiotalent-icon visiotalent-icon-camera',
    translate: true,
  } as MenuItem,
  ADMIN: { id: 'ADMIN', class: 'border-bottom-grey', title: 'NAVIGATION.ADMIN', children: [], translate: true } as MenuItem,
  MY_CAMPAIGNS: {
    id: 'MY_CAMPAIGNS',
    url: `dashboard`,
    title: 'NAVIGATION.DIFF.CAMPAIGNS',
    idParent: 'DIFF',
    translate: true,
    roles: [RolesEnum.csm, RolesEnum.admin, RolesEnum.user],
  } as MenuItem,
  MEW_CAMPAIGNS: {
    id: 'MEW_CAMPAIGNS',
    url: ['recruitments', 'create'],
    title: 'NAVIGATION.DIFF.NEW_CAMPAIGNS',
    roles: [RolesEnum.csm, RolesEnum.admin, RolesEnum.user],
    idParent: 'DIFF',
    translate: true,
  } as MenuItem,
  SHARED_CAMPAIGNS: {
    id: 'SHARED_CAMPAIGNS',
    url: `dashboard/shared_recruitments`,
    title: 'NAVIGATION.DIFF.SHARED_CAMPAIGNS',
    idParent: 'DIFF',
    translate: true,
  } as MenuItem,
  CLOSED_CAMPAIGNS: {
    id: 'CLOSED_CAMPAIGNS',
    url: `dashboard/closed_recruitments`,
    title: 'NAVIGATION.DIFF.CLOSED_CAMPAIGNS',
    roles: [RolesEnum.csm, RolesEnum.admin, RolesEnum.user],
    idParent: 'DIFF',
    translate: true,
  } as MenuItem,
  ANALYTICS: {
    id: 'ANALYTICS',
    url: `analytics`,
    title: 'PANEL.COMPANY.ACCUEIL.STATISTICS.TITRE',
    idParent: 'DIFF',
    translate: true,
  } as MenuItem,
  PERSO: { id: 'PERSO', title: 'CAMPAGNE.PERSONNALISATION', children: [], idParent: 'DIFF', translate: true } as MenuItem,
  FEATURES: {
    id: 'FEATURES',
    url: 'settings/preferences/features',
    title: 'NAVIGATION.DIFF.PERSO.FEATURES',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  NOTIF: {
    id: 'NOTIF',
    url: 'settings/preferences/notifications',
    title: 'NAVIGATION.DIFF.PERSO.NOTIF',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  MY_EMAILS: {
    id: 'MY_EMAILS',
    url: ['settings', 'emailing'],
    title: 'NAVIGATION.DIFF.PERSO.MY_EMAILS',
    roles: [RolesEnum.csm, RolesEnum.admin, RolesEnum.user],
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  MY_MEDIA: {
    id: 'MY_MEDIA',
    url: ['settings', 'medias'],
    title: 'MEDIATHEQUE.MES_MEDIAS',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  MY_EVAL: {
    id: 'MY_EVAL',
    url: 'settings/customization/criterions',
    title: 'NAVIGATION.DIFF.PERSO.MY_EVAL',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  INTEGRATIONS: {
    id: 'INTEGRATIONS',
    url: 'settings/customization/integrations/',
    title: 'NAVIGATION.DIFF.PERSO.INTEGRATIONS',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  MY_FORMS_COLLAB: {
    id: 'MY_FORMS_COLLAB',
    url: 'settings/mcqs_collaborators/',
    title: 'NAVIGATION.DIFF.PERSO.MY_FORMS_COLLAB',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  MY_STATUS: {
    id: 'MY_STATUS',
    url: ['settings', 'status'],
    title: 'STATUS.MES_STATUTS_PERSONALISES',
    idParent: 'PERSO',
    translate: true,
  } as MenuItem,
  MY_FORMS: {
    id: 'MY_FORMS',
    url: ['forms'],
    title: 'FORMS.MES_QUESTIONNAIRES',
    idParent: 'PERSO',
    translate: true,
    roles: [RolesEnum.csm, RolesEnum.admin, RolesEnum.user],
  } as MenuItem,
  MY_MANAGERS: {
    id: 'MY_MANAGERS',
    url: ['settings', 'managers'],
    title: 'SETTINGS.MANAGERS.TITLE',
    idParent: 'PERSO',
    roles: [RolesEnum.csm, RolesEnum.admin, RolesEnum.user],
    translate: true,
  } as MenuItem,
  ADMIN_COMPANIES: {
    id: 'ADMIN_COMPANIES',
    url: ['panel', 'companies'],
    strictUrl: true,
    class: 'li-list-company',
    title: 'NAVIGATION.ADMIN.ADMIN_COMPANIES',
    icon: 'sync_alt',
    translate: true,
    idParent: 'ADMIN',
    roles: [RolesEnum.csm, RolesEnum.admin],
  } as MenuItem,
  ADMIN_COMPANY_NAME: {
    id: 'ADMIN_NAME_COMPANY',
    class: 'li-name-company',
    title: ':companyName',
    idParent: 'ADMIN',
    translate: false,
  } as MenuItem,
  ADMIN_USERS: {
    id: 'ADMIN_USER',
    url: ['panel', 'companies', idCompanyLink, 'users'],
    title: 'PANEL.USERS.TITLE',
    idParent: 'ADMIN',
    translate: true,
    roles: [RolesEnum.csm, RolesEnum.admin],
  } as MenuItem,
  ADMIN_MANAGERS: {
    id: 'ADMIN_MANAGERS',
    url: ['panel', 'companies', idCompanyLink, 'managers'],
    title: 'NAVIGATION.ADMIN.ADMIN_MANAGERS',
    translate: true,
    idParent: 'ADMIN',
  } as MenuItem,
  ADMIN_TEAMS: {
    id: 'ADMIN_TEAMS',
    url: ['panel', 'companies', idCompanyLink, 'teams'],
    title: 'PANEL.COMPANY.ACCUEIL.TEAMS.TITRE',
    idParent: 'ADMIN',
    translate: true,
  } as MenuItem,
  ADMIN_ADMINISTRATORS: {
    id: 'ADMIN_ADMINISTRATORS',
    url: ['panel', 'companies', idCompanyLink, 'administrators'],
    title: 'PANEL.COMPANY.ACCUEIL.ADMINISTRATEURS.TITRE',
    translate: true,
    idParent: 'ADMIN',
    roles: [RolesEnum.csm, RolesEnum.admin],
  } as MenuItem,
  ADMIN_SETTINGS: {
    id: 'ADMIN_SETTINGS',
    url: ['panel', 'companies', idCompanyLink, 'parameters'],
    title: 'PANEL.COMPANY.ACCUEIL.PARAMETRES.TITRE',
    translate: true,
    idParent: 'ADMIN',
    roles: [RolesEnum.csm, RolesEnum.admin],
  } as MenuItem,
  ADMIN_SECU: {
    id: 'ADMIN_SECU',
    url: ['panel', 'companies', idCompanyLink, 'security'],
    title: 'PANEL.COMPANY.ACCUEIL.SECURITE.TITRE',
    translate: true,
    idParent: 'ADMIN',
    roles: [RolesEnum.csm, RolesEnum.admin],
  } as MenuItem,
  ADMIN_CONTAINS: {
    id: 'ADMIN_CONTAINS',
    title: 'PANEL.COMPANY.ACCUEIL.CONTENT.TITRE',
    idParent: 'ADMIN',
    children: [],
    translate: true,
  } as MenuItem,
  ADMIN_CONTAINS_CAMPAIGNS: {
    id: 'ADMIN_CONTAINS_CAMPAIGNS',
    url: ['panel', 'companies', idCompanyLink, 'contents', 'campaigns'],
    translate: true,
    title: 'PANEL.COMPANY.CONTENT.CAMPAIGN.TITRE',
    idParent: 'ADMIN_CONTAINS',
    children: [],
  } as MenuItem,
  ADMIN_CONTAINS_MEDIAS: {
    id: 'ADMIN_CONTAINS_MEDIAS',
    url: ['panel', 'companies', idCompanyLink, 'contents', 'medias'],
    translate: true,
    title: 'MEDIATHEQUE.TITRE',
    idParent: 'ADMIN_CONTAINS',
    children: [],
  } as MenuItem,
  ADMIN_CONTAINS_PROFILS: {
    id: 'ADMIN_CONTAINS_PROFILS',
    url: ['panel', 'companies', idCompanyLink, 'contents', 'profiles'],
    translate: true,
    title: 'PANEL.USER.PROFILS.TITLE',
    idParent: 'ADMIN_CONTAINS',
    children: [],
  } as MenuItem,
  ADMIN_CONTAINS_FORMS: {
    id: 'ADMIN_CONTAINS_FORMS',
    url: ['panel', 'companies', idCompanyLink, 'contents', 'forms'],
    translate: true,
    title: 'PANEL.COMPANY.CONTENT.FORMS.TITRE',
    idParent: 'ADMIN_CONTAINS',
    children: [],
  } as MenuItem,
  ADMIN_CONTAINS_EMAILS: {
    id: 'ADMIN_CONTAINS_EMAILS',
    url: ['panel', 'companies', idCompanyLink, 'contents', 'emailing'],
    translate: true,
    title: 'NAVIGATION.ADMIN.ADMIN_CONTAINS.EMAILS',
    idParent: 'ADMIN_CONTAINS',
    children: [],
  } as MenuItem,
  ADMIN_STATS: {
    id: 'ADMIN_STATS',
    url: ['panel', 'companies', idCompanyLink, 'statistics'],
    title: 'PANEL.COMPANY.ACCUEIL.STATISTICS.TITRE',
    translate: true,
    idParent: 'ADMIN',
  } as MenuItem,
};
