<div class="d-flex flex-column h-100 w-100">
  <app-common-header title="Managers" [title]="'SETTINGS.MANAGERS.TITLE' | translate"></app-common-header>
  <app-flex-fill-container scrollSpyElement="flex-fill-container">
    <div class="col-12 row gx-3 mt-4 me-0 px-0">
      <div class="input-group pe-0">
        <div class="input-group-text bg-lightest-gray">
          <i class="material-icons">search</i>
        </div>
        <input
          [(ngModel)]="globalSearch"
          class="form-control"
          placeholder="Nom de l'utilisateur"
          [placeholder]="'SETTINGS.MANAGERS.FILTERS.NOM' | translate"
        />
      </div>
    </div>

    <div class="col-4 row gx-3 my-2 me-0">
      <label [translate]="'SETTINGS.MANAGERS.STATE_SEARCH.LABEL'" class="form-label"></label>
      <ng-select
        name="statesUserFilter"
        [items]="statesUser"
        [(ngModel)]="stateUserFilter"
        [searchable]="true"
        bindValue="value"
        [clearable]="true"
        placeholder="Tous"
        [placeholder]="'SETTINGS.MANAGERS.STATE_SEARCH.ALL' | translate"
      >
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <span class="align-middle ms-2"> {{ item.label }}</span>
        </ng-template>
      </ng-select>
    </div>
    <div *ngIf="selectedUsers?.length == 0">
      <h4 class="pt-4 pb-2">
        {{ 'SETTINGS.MANAGERS.TITLE' | translate }}&nbsp;
        <span *ngIf="nbUsers !== undefined" class="badge rounded-pill bg-primary py-1"> {{ nbUsers }} </span>
      </h4>
    </div>
    <div>
      <cc-table
        class="last-col-fix"
        [url]="usersApiUrl"
        tableClasses="table-hover align-middle text-nowrap"
        [columns]="columns"
        [search]="filter"
        [checkboxActions]="actions"
        [checkboxHeader]="true"
        (dataUpdated)="nbUsers = $event.total"
        (checkChanged)="selectedUsers = $event"
        (clickLine)="navigateToCandidats($event)"
        (action)="onUserAction($event.action, $event.items)"
        [selectionBarTitle]="'SETTINGS.MANAGERS.MANAGERS_SELECTIONNES' | translate : { count: selectedUsers.length }"
        [labels]="tableLabels"
        #ccTable
      ></cc-table>
    </div>
  </app-flex-fill-container>
</div>

<ng-template #actionsTemplate let-line="line" let-data="data">
  <div class="position-relative text-center" dropdown container="body" placement="bottom right">
    <a
      dropdownToggle
      href="javascript:void(0)"
      (click)="$event.stopPropagation()"
      class="btn btn-link link-secondary text-decoration-none p-1"
    >
      <i class="material-icons md-24">more_vert</i>
    </a>
    <div class="bg-white">
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
        <li>
          <a
            class="dropdown-item text-secondary text-decoration-none"
            href="javascript:void(0)"
            (click)="navigateToCandidats(line)"
            [translate]="'SETTINGS.MANAGERS.ACTIONS.SHARED_CANDIDAT'"
          ></a>
        </li>
        <li *ngFor="let action of actions">
          <a
            class="dropdown-item text-secondary text-decoration-none"
            href="javascript:void(0)"
            (click)="onUserAction(action.action, [line])"
            *ngIf="!action.isVisible || action.isVisible([line])"
            >{{ action.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #stateTemplate let-line="line" let-data="data">
  <i *ngIf="!line.deleted" class="md-24 material-icons text-success"> fiber_manual_record </i>
  <i *ngIf="line.deleted" class="md-24 material-icons"> pause_circle_filled </i>
</ng-template>
