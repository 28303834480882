import { Component, OnInit, Input } from '@angular/core';
import { ModalBaseComponent } from '../../modal/modal-base-component';
import { Resource } from 'src/app/shared/business/resources/resource';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MediasService } from 'src/app/settings/medias/medias.service';
import { LanguageService, LanguageType } from 'src/app/shared/business/languages/language.service';
import { ResourceService, ResourceType } from 'src/app/shared/business/resources/resource.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ResourceStatusEnum } from 'src/app/shared/business/resources/resource-status.enum';
import { ResourceTypeEnum } from 'src/app/shared/business/resources/resource-type.enum';
import { Sharing } from 'src/app/shared/sharing/entities/sharing';
import { SharingObjectType } from 'src/app/shared/sharing/entities/sharing-object-type.enum';
import { SharingAuthorization } from 'src/app/shared/sharing/entities/sharing-authorization.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resource-media-edit-modal',
  templateUrl: './resource-media-edit-modal.component.html',
  styleUrls: ['./resource-media-edit-modal.component.scss'],
})
export class ResourceMediaEditModalComponent extends ModalBaseComponent<Resource> implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  options: any;

  public shareableTo: { type: string; id: number; title: string }[];
  public panel: boolean = false;
  public shareable: boolean = false;
  public resourceTypeImage = ResourceTypeEnum.image;

  public media: Resource;
  public oldShare: { type: string; id: number; title: string };

  public isMediaConverted: boolean;

  public resourceGroup: UntypedFormGroup;
  public languages: LanguageType[];
  public resourceTypes: ResourceType[];

  constructor(
    bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private mediaService: MediasService,
    private languageService: LanguageService,
    private resourceService: ResourceService,
    private toasterService: ToastrService,
    private translate: TranslateService
  ) {
    super(bsModalRef);
  }

  ngOnInit() {
    this.panel = this.options.panel || false;
    this.shareable = this.options.shareable || false;
    this.media = this.resource;
    this.languages = this.languageService.getCandidateLanguages();
    this.resourceTypes = this.resourceService.getTypesFor(this.media);
    this.shareableTo = this.options.shareableTo;
    if (this.panel && this.shareable && this.media.sharing) {
      this.oldShare = this.shareableTo.find(element => {
        return element.id === +this.media.sharing.recipientId && element.type === this.media.sharing.recipientType;
      });
    }

    if (!this.oldShare && this.shareableTo) {
      this.oldShare = this.shareableTo[0];
    }

    let formGroupObject: any = {
      title: [this.media.title, Validators.required],
      type: [this.media.type, Validators.required],
      language: [this.media.language, Validators.required],
    };

    if (this.oldShare) {
      formGroupObject = { ...formGroupObject, share: [this.oldShare, Validators.required] };
    }

    this.resourceGroup = this.formBuilder.group(formGroupObject);

    if (this.options.isNew) {
      this.resourceGroup.controls.title.markAsDirty();
    }

    this.isMediaConverted = this.media.status === ResourceStatusEnum.converted;
  }

  async onSubmit() {
    this.media.title = this.resourceGroup.value.title;
    this.media.type = this.resourceGroup.value.type;
    this.media.language = this.resourceGroup.value.language;
    this.media.from = 'upload';
    this.media.status = undefined;
    if (this.panel && this.options.shareable) {
      if (this.resourceGroup.value.share) {
        let sharingId = null;
        if (this.media.sharing) {
          sharingId = this.media.sharing.id;
        }

        let share: Partial<Sharing> = {
          objectId: this.media.id,
          objectOwnerId: this.media.ownerId,
          objectType: SharingObjectType.resource,
          recipientId: this.resourceGroup.value.share.id.toString(),
          recipientType: this.resourceGroup.value.share.type,
          creatorId: this.media.ownerId,
        };

        if (sharingId) {
          share = { ...share, id: sharingId };
        }

        this.mediaService.share(share).subscribe();
      }

      this.mediaService.putPanel(this.media, this.options.company.id).subscribe(() => {
        this.notifToaster();
        this.close();
      });
    } else {
      this.mediaService.put(this.media).subscribe(() => {
        this.notifToaster();
        this.close();
      });
    }
  }

  private notifToaster() {
    const content = this.translate.instant('MEDIAS.MODIFICATION_EFFECTUE_MESSAGE', {
      title: this.media.title,
      VAR_SELECT: this.media.type === 'image' ? 'image' : 'video',
    });

    this.toasterService.success(content, this.translate.instant('MEDIAS.MODIFICATION_EFFECTUE'));
  }
}
