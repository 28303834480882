import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { VideoRecordModalComponent } from '../video-record-modal/video-record-modal.component';
import { Observable, Subscription } from 'rxjs';
import { Resource } from '../../../business/resources/resource';
import { SubmittableFormGroup } from '../../../form-controls/submittable-form-group';
import { ResourceTypeEnum } from '../../../business/resources/resource-type.enum';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '../../modal/modal.service';
import { UploadResourceService } from '../../../upload/upload-resource.service';
import { ResourceService } from '../../../business/resources/resource.service';
import { ModalBaseComponent } from '../../modal/modal-base-component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resource-media-upload-modal',
  templateUrl: './resource-media-upload-modal.component.html',
  styleUrls: ['./resource-media-upload-modal.component.scss'],
})
export class ResourceMediaUploadModalComponent extends ModalBaseComponent<Resource> implements OnInit {
  public title: string;
  public subtitle: string;
  public resources: Resource[] = [];
  public resourceSelectorForm: SubmittableFormGroup;
  public resourceId: any;
  public type: ResourceTypeEnum;
  public isLoading: boolean = true;
  public isModalVisible: boolean = false;
  public uploads: { observable: Observable<{ id: string; percent: number }>; percent: number; id: string }[] = [];

  public uploadConfiguration: {
    accept?: string;
    maxSize?: number;
    extensions: string[];
  };

  public validUploadDrag: boolean;
  public files: File[] = [];
  public invalidFiles: File[] = [];
  public imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
  public videoExtensions = ['mov', 'mp4', 'mpg', 'webm'];

  constructor(
    bsModalRef: BsModalRef,
    private router: Router,
    @Inject(forwardRef(() => UploadResourceService)) private uploadService: UploadResourceService,
    @Inject(forwardRef(() => ResourceService)) private resourceService: ResourceService,
    @Inject(forwardRef(() => ModalService)) private modalService: ModalService,
    private toasterService: ToastrService,
    private translate: TranslateService
  ) {
    super(bsModalRef);
  }

  ngOnInit() {
    this.uploadConfiguration = {
      extensions: this.imageExtensions.concat(this.videoExtensions),
      accept: 'image/*,video/*',
      maxSize: 100,
    };

    this.resourceSelectorForm = new SubmittableFormGroup({ resource: new UntypedFormControl(this.resourceId, Validators.required) });
    // this.updateResources();
  }

  public updateResources() {
    return new Promise((resolve, reject) =>
      this.resourceService
        .getAll({ type: this.type })
        .pipe(map(r => r.data.filter(v => !this.type || this.type === v.type)))
        .subscribe({
          next: r => {
            this.resources = r;
            setTimeout(() => resolve(r));
          },
          error: e => reject(e),
          complete: () => (this.isLoading = false),
        })
    );
  }

  public onSubmit() {
    this.resourceSelectorForm.markAsSubmitted();
    if (this.resourceSelectorForm.invalid) {
      return;
    }
    const resourceId = this.resourceSelectorForm.get('resource').value;
    const resource = this.resources.find(r => r.id === resourceId);
    this.close(resource);
  }

  public upload() {
    this.invalidFiles = [];
    this.uploads = this.files.map(f => ({
      observable: this.uploadService.upload(f, f.name.toLowerCase(), this.getFileType(f.name)),
      percent: 0,
      id: undefined,
    }));
    this.files = [];
    const resourceControl = this.resourceSelectorForm.get('resource');

    this.uploads.forEach(u => {
      const subscription = u.observable.subscribe(
        p => {
          u.percent = p.percent;
          u.id = p.id;
        },
        () => this.onFileUploaded(u.observable, subscription),
        () => {
          const id = u.id;
          this.updateResources().then(() => {
            resourceControl.patchValue(id);
            this.onFileUploaded(u.observable, subscription);
          });
        }
      );
    });
  }

  private getFileExtension(fileName: string): string {
    const fileSplit = fileName.split('.');
    return fileSplit[fileSplit.length - 1];
  }

  private getFileType(fileName: string): string {
    if (this.imageExtensions.indexOf(this.getFileExtension(fileName)) > -1) {
      return ResourceTypeEnum.image;
    }

    return ResourceTypeEnum.videoQuestion;
  }

  public openRecordVideoModal() {
    const bsModalRef = this.modalService.show(VideoRecordModalComponent, {
      class: 'modal-lg modal-secondary modal-dialog-centered',
    });
    const modalComponent = bsModalRef.content as VideoRecordModalComponent;
    this.isModalVisible = true;
    modalComponent.onClose.subscribe({
      next: file => {
        this.files = [file];
        this.upload();
      },
      complete: () => {
        this.isModalVisible = false;
      },
    });
  }

  private async onFileUploaded(observable: Observable<{ id: string; percent: number }>, subscription: Subscription) {
    const upload = this.uploads.find(u => u.observable === observable);
    const index = this.uploads.indexOf(upload);
    this.uploads.splice(index, 1);
    subscription.unsubscribe();

    await this.resourceService.get(upload.id).subscribe(
      r => {
        const content = this.translate.instant('MEDIAS.CREATION_EFFECTUE_MESSAGE', {
          title: r.title,
          VAR_SELECT: r.type === 'image' ? 'image' : 'video',
        });

        this.toasterService.success(content, this.translate.instant('MEDIAS.CREATION_EFFECTUE'));

        if (this.uploads.length === 0) {
          this.close(r);
        }
      },
      () => {
        this.toasterService.error(
          this.translate.instant('MEDIAS.CREATION_ERROR.CONTENT'),
          this.translate.instant('MEDIAS.CREATION_ERROR.TITLE')
        );
        this.close(undefined);
      }
    );
  }

  public join(array: Array<string>, separator: string) {
    return array.join(separator);
  }
}
