import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  constructor(
    private readonly cookieService: CookieService,
    private translateService: TranslateService,
  ) {}

  public currentLanguage: string;
  public languages: string[];

  public ngOnInit(): void {
    this.languages = environment.languages;
    this.currentLanguage = this.getLanguage().split('_')[0].split('-')[0];
  }

  public setLanguage(language: string) {
    this.currentLanguage = language;
    this.cookieService.set('language', language);
    this.translateService.use(language);
  }

  public getLanguage() {
    const savedLanguage = this.cookieService.get('language');
    return savedLanguage || navigator.language || navigator['userLanguage'];
  }
}
