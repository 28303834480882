import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ListSelectableItem } from './list-selectable-item';

@Component({
  selector: 'app-list-selectable',
  templateUrl: './list-selectable.component.html',
  styleUrls: ['./list-selectable.component.scss'],
})
export class ListSelectableComponent implements OnChanges {
  @Input() datas: ListSelectableItem[];
  @Output() selectedItem = new EventEmitter<object>();
  public _filteredData: ListSelectableItem[];

  ngOnChanges() {
    this._filteredData = this.datas;
  }

  setValue(item) {
    this.selectedItem.emit(item);
  }

  doFilter(item, search) {
    const item_formated = item.label.toLowerCase();
    if (item_formated.indexOf(search.toLowerCase()) !== -1) {
      return true;
    }
  }

  public filter(event) {
    this._filteredData = this.datas.map(parent => {
      const newParent = { ...parent };
      newParent.children = parent.children.filter(data => this.doFilter(data, event));
      return newParent;
    });
  }
}
