import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-foldable-accordion',
  templateUrl: './foldable-accordion.component.html',
  styleUrls: ['./foldable-accordion.component.scss'],
})
export class FoldableAccordionComponent {
  @Input()
  public isOpen: boolean = true;
  @Input()
  public isDisabled: boolean = false;
  @Input()
  public title: string = '';
}
