<ng-container *ngIf="!isLoading && isConverted; else isNotConverted">
  <img
    *ngIf="resource?.type?.indexOf('video') === -1"
    [ngClass]="miniClass"
    [vtsrc]="resource?.previewUrl"
    appImageLoader
    (click)="openModal($event)"
  />
  <img
    *ngIf="resource?.type?.indexOf('video') !== -1"
    [ngClass]="miniClass"
    [vtsrc]="resource?.previewUrl"
    appImageLoader
    (error)="isErrored()"
  />
  <div class="play-button" *ngIf="resource?.type?.indexOf('video') !== -1 && !isError" (click)="openModal($event)" data-cy="DIV-839c"></div>
</ng-container>

<ng-template #isNotConverted>
  <ng-container *ngIf="!mini; else isMini">
    <div *ngIf="isNew; else isNotNew" class="image-conversion flex-column d-flex justify-content-between" data-cy="DIV-e88c">
      <div class="text-center px-2 pt-1 text-secondary" data-cy="DIV-e503">
        <small data-cy="SMALL-f1fd"><em [translate]="'RESSOURCE.CONVERSION_EN_COURS'"></em></small>
      </div>
      <div class="text-center p-1" data-cy="DIV-5f7c">
        <div class="spinner-border text-primary" role="status" data-cy="DIV-f5a0"></div>
      </div>
      <div class="text-center px-3 pb-1 invisible" data-cy="DIV-b73c">
        <small data-cy="SMALL-2fe4"><em [translate]="'RESSOURCE.CONVERSION_EN_COURS'"></em></small>
      </div>
    </div>

    <ng-template #isNotNew class="image-conversion flex-column d-flex justify-content-center">
      <div class="text-center" data-cy="DIV-3607">
        <img class="mw-100 mh-100 p-1 p-md-4" src="/assets/images/missing.png" alt="" />
      </div>
    </ng-template>
  </ng-container>
  <ng-template #isMini>
    <div *ngIf="isNew; else isNotNew" data-cy="DIV-2166">
      <div class="spinner-border text-primary" role="status" data-cy="DIV-65d5"></div>
    </div>

    <ng-template #isNotNew>
      <img width="40" height="40" src="/assets/images/missing.png" alt="" />
    </ng-template>
  </ng-template>
</ng-template>

<div class="actions" data-cy="DIV-fa50">
  <button
    [tooltip]="'QUESTIONNAIRE.TOOLTIP.DELETE' | translate"
    type="button"
    *ngIf="isDeletable"
    class="btn btn-img text-white"
    (click)="onDelete()"
    data-cy="BUTTON-2fa3"
  >
    <i class="material-icons" data-cy="I-9fdb">delete</i>
  </button>
  <button
    [tooltip]="'QUESTIONNAIRE.TOOLTIP.CHANGE' | translate"
    type="button"
    *ngIf="isEditable"
    class="btn btn-img text-white"
    (click)="onEdit()"
    data-cy="BUTTON-63b7"
  >
    <i class="material-icons" data-cy="I-fe44">edit</i>
  </button>
</div>
