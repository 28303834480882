import { BsModalService } from 'ngx-bootstrap/modal';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { Injectable, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { finalize, tap } from 'rxjs/operators';

import { AsyncLabel, ModalBaseParameters } from './modal-base-parameters';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ConfirmModalParameters } from './confirm-modal/confirm-modal-parameters';
import { FormModalComponent } from './form-modal/form-modal.component';
import { FormModalParameters } from './form-modal/form-modal-parameters';
import { ModalBaseComponent } from './modal-base-component';

@Injectable({
  providedIn: 'root',
})
export class ModalService extends BsModalService {
  constructor(
    private readonly translate: TranslateService,
    public toasterService: ToastrService,
    renderedFactory: RendererFactory2,
    clf: ComponentLoaderFactory,
  ) {
    super(renderedFactory, clf, {});
  }

  public form(parameters: FormModalParameters): Observable<any> {
    return this.openModal(FormModalComponent, parameters);
  }

  public confirm(parameters: ConfirmModalParameters): Observable<any> {
    return this.openModal(ConfirmModalComponent, parameters);
  }

  private openModal(component: new (...args: any[]) => any, parameters: ModalBaseParameters) {
    parameters.labels = {
      ...{
        submit: this.translate.instant('COMMON.CONFIRM'),
        cancel: this.translate.instant('COMMON.ANNULER'),
      },
      ...parameters.labels,
    };
    const modalClass = parameters.style === 'scrollable' ? 'modal-dialog-scrollable' : 'modal-dialog-centered';
    const backdrop = parameters.backdrop || 'static';
    const modalRef = this.show(component, {
      class: `modal-lg modal-secondary ${modalClass}`,
      backdrop,
      initialState: { parameters },
    });

    const onSubmit$ = new Subject<any>();

    (modalRef.content as ModalBaseComponent<any>).onClose.subscribe({
      next: result =>
        parameters.onSubmit
          ? parameters
              .onSubmit(result)
              .pipe(finalize(() => (modalRef.content.isSubmitting = false)))
              .subscribe({ next: v => onSubmit$.next(v), error: e => this._popError(parameters, e) })
          : onSubmit$.next(result),
      complete: () => onSubmit$.complete(),
    });

    return onSubmit$.pipe(
      tap(r => {
        this._popSuccess(parameters, r);
        modalRef.hide();
      }),
    );
  }

  private _popError(parameters: ModalBaseParameters, err: any) {
    if (parameters.labels.error) {
      this.toasterService.error(
        this.getTranslation(parameters.labels.error.body, err),
        this.getTranslation(parameters.labels.error.title, err),
      );
    }
  }

  private _popSuccess(parameters: ModalBaseParameters, r: any) {
    if (parameters.labels.success) {
      this.toasterService.success(
        this.getTranslation(parameters.labels.success.body, r),
        this.getTranslation(parameters.labels.success.title, r),
      );
    }
  }

  private getTranslation(translation: AsyncLabel, params: any) {
    return !translation ? '' : typeof translation === 'function' ? translation(params) : translation;
  }
}
