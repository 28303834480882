import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @Input() content: any;

  private _context: object;
  public get context(): object {
    return this._context;
  }
  @Input()
  public set context(v: object) {
    this._context = { ...v, popover: this };
  }

  @Output() shown = new EventEmitter();
  @Output() hidden = new EventEmitter<object>();

  public params = {
    isOpen: false,
    isBackgroundVisible: false,
  };

  public hide() {
    this.params.isOpen = false;
  }

  public show() {
    this.params.isOpen = true;
  }

  public onShown() {
    this.params.isBackgroundVisible = true;
    this.shown.emit();
  }

  public onHidden() {
    this.params.isBackgroundVisible = false;
    this.hidden.emit(this.context);
  }
}
