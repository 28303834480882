import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, debounceTime, tap } from 'rxjs';
import { Component, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/auth/user';

import { ModalService } from '../../../shared/layout/modal/modal.service';
import { StatusAddModalComponent } from '../status-add-modal/status-add-modal.component';

@Component({
  selector: 'app-status-search-form',
  templateUrl: './status-search-form.component.html',
  styleUrls: ['./status-search-form.component.scss'],
})
export class StatusSearchFormComponent {
  private _me: User;
  public get me(): User {
    return this._me;
  }

  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toasterService: ToastrService
  ) {
    this._me = this.route.snapshot.data.me;
    this.search$.pipe(debounceTime(200)).subscribe(v => this.search.emit(v));
  }

  @Output()
  public search = new EventEmitter();

  @Output()
  public statusCreated = new EventEmitter();

  private search$ = new BehaviorSubject<string>('');

  public filterByValue(value: string) {
    this.search$.next(value);
  }

  public openCreateStatusModal() {
    const modalRef = this.modalService.show(StatusAddModalComponent, {
      class: 'modal-dialog-centered',
      initialState: {},
    });

    modalRef.content.statusCreatedEvent
      .pipe(
        tap(s =>
          this.toasterService.success(
            this.translate.instant('STATUS.STATUT_PERSO_CREE', { statusValue: s.value }),
            this.translate.instant('STATUS.NOUVEAU_STATUT_PERSO')
          )
        )
      )
      .subscribe(s => this.statusCreated.emit(s));
  }
}
