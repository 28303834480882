import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from 'src/app/shared/layout/common-footer/breadcrumb-item';
import { CcTableComponent, TableColumn } from '@cleverconnect/ngx-bootstrap-table';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { EmailTemplate } from 'src/app/shared/business/emailing/entities/email-template';
import { EmailTemplateRepository } from 'src/app/shared/business/emailing/email-template.repository';
import { EmailTemplateService } from 'src/app/shared/business/emailing/email-template.service';
import { EmailTypeService } from 'src/app/shared/business/emailing/email-type.service';
import { EmailingSearchDto } from 'src/app/shared/business/emailing/emailing-search-form/emailing-search.dto';
import { Observable, Subscription, mergeMap, of, tap } from 'rxjs';
import { RecruitmentModeService } from 'src/app/shared/business/recruitments/recruitment-mode/recruitment-mode.service';
import { TranslateService } from '@ngx-translate/core';
import { UserThumbnail } from '@cleverconnect/ngx-user-thumbnail';
import { getCcTableLabels } from 'src/app/shared/table/labels';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
})
export class EmailTemplatesComponent implements OnInit, OnDestroy {
  @ViewChild('ccTable', { static: true }) public tableEmailTemplates!: CcTableComponent<EmailTemplate>;
  @ViewChild('actionsTemplate', { static: true }) public actionsTemplate!: TemplateRef<unknown>;
  @ViewChild('stateTemplate', { static: true }) public stateTemplate!: TemplateRef<unknown>;
  @ViewChild('dateTemplate', { static: true }) public dateTemplate!: TemplateRef<unknown>;
  @ViewChild('languageTemplate', { static: true }) public languageTemplate!: TemplateRef<unknown>;
  @ViewChild('avatarTemplate', { static: true }) public avatarTemplate!: TemplateRef<unknown>;
  @ViewChild('mailTypeTemplate', { static: true }) public mailTypeTemplate!: TemplateRef<unknown>;
  @ViewChild('modeTemplate', { static: true }) public modeTemplate: TemplateRef<unknown>;

  public search: EmailingSearchDto = {};
  public columns: TableColumn[];
  public breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem(this.translate.instant('MEDIATHEQUE.REGLAGES'), '../..'),
    new BreadcrumbItem(this.translate.instant('EMAILING.MES_EMAILS_TYPES'), '.'),
  ];

  public emailTypes: { [key: string]: string };
  public tableLabels = getCcTableLabels(this.translate);
  public me = this.activeRoute.snapshot.data.me;
  private routeQueryParams$: Subscription;

  public statusUrl = '';
  public mailTemplateCount?: number;
  public colorMode = {
    external: 'bg-pastel-blue',
    mobility: 'bg-pastel-yellow',
    school: 'bg-pastel-orange',
    external_informal: 'bg-pastel-purple',
    motivation: 'bg-light-blue',
  };

  constructor(
    private readonly translate: TranslateService,
    private readonly configService: DynamicConfigService,
    private readonly emailTemplateService: EmailTemplateService,
    private readonly emailTemplateRepository: EmailTemplateRepository,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly recruitmentModeService: RecruitmentModeService,
    emailTypeService: EmailTypeService
  ) {
    this.statusUrl = `${this.configService.get<Config>().apiUrl}/email/templates`;
    emailTypeService.getWithStatuses().subscribe(types => {
      this.emailTypes = types.reduce((acc, t) => ({ ...acc, [t.value]: t.label }), {});
      this.routeQueryParams$ = this.activeRoute.queryParams.subscribe(params => {
        if (params)
          if (params['action']) {
            this.emailTemplateRepository
              .get(params['templateId'])
              .pipe(mergeMap(t => this.action(params['action'], new EmailTemplate({ ...t, ...params }))))
              .subscribe({
                complete: () => {
                  this.router.navigate(['.'], { relativeTo: this.activeRoute });
                },
              });
          }
      });
    });
  }

  ngOnInit(): void {
    this.columns = [
      {
        field: 'isActive',
        title: this.translate.instant('EMAILING.ACTIVATION'),
        template: this.stateTemplate,
      },
      { field: 'title', title: this.translate.instant('EMAILING.INTITULE') },
      {
        field: 'createdAt',
        title: this.translate.instant('EMAILING.DATE_CREATION'),
        classesHeaderTh: 'd-none d-md-table-cell',
        classesTd: 'd-none d-md-table-cell',
        template: this.dateTemplate,
      },
      {
        field: 'mode',
        title: this.translate.instant('PANEL.COMPANY.CONTENT.CAMPAIGN.TABLE.MODE'),
        template: this.modeTemplate,
        formatter: (_, mail) => {
          return this.recruitmentModeService.get(mail.mode);
        },
      },

      { field: 'language', title: this.translate.instant('EMAILING.LANGUE'), template: this.languageTemplate },
      {
        field: 'type',
        title: this.translate.instant('EMAILING.DECLENCHEUR'),
        template: this.mailTypeTemplate,
      },
      {
        title: this.translate.instant('FORM.CREE_PAR'),
        field: 'owner',
        classesTd: 'py-1',
        template: this.avatarTemplate,
        formatter: owner =>
          ({
            url: owner ? owner.avatar : 'assets/images/person.svg',
            label: owner ? `${owner.firstname || ''} ${owner.lastname || ''}` : undefined,
          } as UserThumbnail),
      },
      { title: '', classesTd: 'p-0', template: this.actionsTemplate },
    ];
  }

  ngOnDestroy(): void {
    this.routeQueryParams$?.unsubscribe();
  }

  public action(action: string, emailTemplate?: EmailTemplate): Observable<EmailTemplate | void> {
    let action$: Observable<EmailTemplate | void>;
    switch (action) {
      case 'preview':
        action$ = this.emailTemplateService.preview(emailTemplate);
        break;
      case 'create':
      case 'edit':
        action$ = this.emailTemplateService.createOrEdit(emailTemplate).pipe(tap(() => this.tableEmailTemplates.reload()));
        break;
      case 'duplicate':
        action$ = this.emailTemplateService.duplicate(emailTemplate).pipe(tap(() => this.tableEmailTemplates.reload()));
        break;
      case 'toggle':
        action$ = this.emailTemplateService.toggle(emailTemplate).pipe(tap(() => this.tableEmailTemplates.reload()));
        break;
      case 'delete':
        action$ = this.emailTemplateService.delete(emailTemplate).pipe(tap(() => this.tableEmailTemplates.reload()));
        break;
      default:
        action$ = of(undefined);
    }
    action$.subscribe();
    return action$;
  }

  public generateParams(params: { [key: string]: unknown }) {
    const newParams = { ...params, withOwner: true };
    Object.keys(newParams)
      .filter(k => newParams[k] === undefined || newParams[k] === null)
      .forEach(k => delete newParams[k]);

    return newParams;
  }
}
