import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { OnDestroy, Optional, Directive } from '@angular/core';

@Directive()
export abstract class ModalBaseComponent<T> implements OnDestroy {
  public onClose: Subject<T> = new Subject();

  constructor(@Optional() protected bsModalRef: BsModalRef) {}

  public close(value?: T) {
    this.onClose.next(value);
    this.bsModalRef.hide();
  }

  public cancel() {
    this.bsModalRef.hide();
  }

  public ngOnDestroy(): void {
    if (!this.onClose.closed) {
      this.onClose.complete();
    }
  }
}
