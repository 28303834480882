import { Injectable } from '@angular/core';
import { Observable, catchError, first, map, of, switchMap, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { EmailTemplate } from './entities/email-template';
import { EmailTemplateRepository } from './email-template.repository';
import { EmailingDeleteModalComponent } from './emailing-delete-modal/emailing-delete-modal.component';
import { EmailingDetailsModalComponent } from './emailing-details-modal/emailing-details-modal.component';
import { EmailingDisableModalComponent } from './emailing-disable-modal/emailing-disable-modal.component';
import { EmailingDuplicateAndShareModalComponent } from './emailing-duplicate-and-share-modal/emailing-duplicate-and-share-modal.component';
import { EmailingPreviewModalComponent } from './emailing-preview-modal/emailing-preview-modal.component';
import { ModalService } from '../../layout/modal/modal.service';
import { SharedEntity } from '../../sharing/entities/shared-entity';
import { SharingObjectType } from '../../sharing/entities/sharing-object-type.enum';
import { SharingService } from '../../sharing/sharing.service';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService {
  constructor(
    private readonly emailTemplateRepository: EmailTemplateRepository,
    private readonly translate: TranslateService,
    private readonly modalService: ModalService,
    private readonly toasterService: ToastrService,
    private readonly sharingService: SharingService
  ) {}

  public duplicateAndShare(emailTemplate: EmailTemplate, companyId?: number): Observable<EmailTemplate> {
    emailTemplate.sharings = [];
    emailTemplate.id = undefined;
    emailTemplate.isDefault = false;
    const modalRef = this.modalService.show(EmailingDuplicateAndShareModalComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered',
      initialState: { email: new EmailTemplate({ ...emailTemplate }), companyId },
    });

    return modalRef.content.onClose.pipe(
      switchMap((email: EmailTemplate) =>
        this.emailTemplateRepository
          .create(email)
          .pipe(switchMap((e: EmailTemplate) => this.share({ ...e, sharings: email.sharings }).pipe(map(() => e))))
      ),
      tap(email => {
        this.toasterService.success(
          this.translate.instant('EMAIL.DUPLICATION_PARTAGE_EFFECTUE_MESSAGE', { title: email.title }),
          this.translate.instant('DUPLICATION_PARTAGE_EFFECTUE')
        );
        modalRef.hide();
      })
    );
  }

  public createOrEdit(emailTemplate?: EmailTemplate, companyId?: number, withSharings: boolean = false): Observable<EmailTemplate> {
    const modalRef = this.modalService.show(EmailingDetailsModalComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered mw-100 mx-5',
      initialState: {
        email: emailTemplate ? new EmailTemplate(emailTemplate) : null,
        withSharings,
        companyId,
      },
    });

    return modalRef.content.onClose.pipe(
      switchMap((email: EmailTemplate) => {
        const service$: Observable<EmailTemplate> = email.id
          ? this.emailTemplateRepository.patch(email.id, email)
          : this.emailTemplateRepository.create(email);

        return service$.pipe(
          switchMap(e => (withSharings ? this.share({ ...e, sharings: email.sharings }) : of(undefined)).pipe(map(() => e))),
          tap(email => {
            this.toasterService.success(
              this.translate.instant(email.id ? 'EMAIL.MODIFICATION_EFFECTUE_MESSAGE' : 'EMAIL.CREATION_EFFECTUE_MESSAGE', {
                title: email.title,
              }),
              this.translate.instant(email.id ? 'EMAIL.MODIFICATION_EFFECTUE' : 'EMAIL.CREATION_EFFECTUE')
            );
            modalRef.hide();
          }),
          catchError(err => {
            this.toasterService.error(this.translate.instant(email.id ? 'EMAIL.MODIFICATION_ERREUR' : 'EMAIL.CREATION_ERREUR'));
            throw err;
          })
        );
      })
    );
  }

  public share(emailTemplate: EmailTemplate & SharedEntity) {
    return this.sharingService.delete(SharingObjectType.mailTemplate, emailTemplate.id).pipe(
      switchMap(() =>
        this.sharingService.share(
          emailTemplate?.sharings?.map(s => ({
            recipientType: s.recipientType,
            recipientId: s.recipientId.toString(),
            objectId: emailTemplate.id.toString(),
            objectType: SharingObjectType.mailTemplate,
            objectOwnerId: emailTemplate.ownerId.toString(),
          }))
        )
      )
    );
  }

  public duplicate(email: EmailTemplate): Observable<EmailTemplate> {
    const emailCopy = new EmailTemplate({ ...email, id: undefined });
    if (emailCopy?.isDefault) {
      emailCopy.title = `${this.translate.instant('EMAILING.LABEL_COPY')} ${emailCopy.title}`;
    }
    return this.createOrEdit(emailCopy, null, false);
  }

  public toggle(email: EmailTemplate): Observable<EmailTemplate> {
    if (!email.isActive) {
      return this.emailTemplateRepository.patch(email.id, { isActive: true }).pipe(
        first(),
        tap(e => this.handleToggleResponse(e))
      );
    }

    const modalRef = this.modalService.show(EmailingDisableModalComponent, {
      class: 'modal-dialog-centered',
      initialState: { emailId: email.id },
    });

    return modalRef.content.emailDisabledEvent.pipe(
      first(),
      tap(e => {
        this.handleToggleResponse(e);
        modalRef.hide();
      })
    );
  }

  private handleToggleResponse(email: EmailTemplate): void {
    const isActiveKey = email.isActive ? 'ACTIVATION' : 'DESACTIVATION';
    const toasterContent = this.translate.instant(`EMAIL.${isActiveKey}_EFFECTUE_MESSAGE`, { title: email.title });
    const toasterTitle = this.translate.instant(`EMAIL.${isActiveKey}_EFFECTUE`);

    this.toasterService.success(toasterContent, toasterTitle);
  }

  public delete(email: EmailTemplate): Observable<void> {
    if (email.isDefault) {
      return;
    }
    const modalRef = this.modalService.show(EmailingDeleteModalComponent, {
      class: 'modal-dialog-centered',
      initialState: { emailId: email.id },
    });

    return modalRef.content.emailDeletedEvent.pipe(
      tap(() => {
        this.toasterService.success(
          this.translate.instant('EMAIL.SUPPRESSION_EFFECTUE_MESSAGE', { title: email.title }),
          this.translate.instant('EMAIL.SUPPRESSION_EFFECTUE')
        );
        modalRef.hide();
      })
    );
  }

  public preview(email: EmailTemplate): Observable<EmailTemplate> {
    return this.modalService.show(EmailingPreviewModalComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered',
      initialState: {
        email,
      },
    }).content.onClose;
  }
}
