import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { CompanyUserService } from '../../../../panel/companies/company-users/services/company-user.service';
import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';
import { User } from '../../../../auth/user';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
})
export class ActivateUserComponent extends ModalBaseComponent<User> implements OnInit {
  @Input() public users: User[] = [];
  public companyId: number;
  public subscription: Subscription;

  @Input()
  public selectedUsers: User[] = [];

  @Input() public subtitle: string = 'PANEL.USERS.ACTIVER.SUBTITLE';
  @Input() public successLabel: string = 'PANEL.USERS.ACTIVER.SUCCESS';

  constructor(
    bsModalRef: BsModalRef,
    private companyUser: CompanyUserService,
    private toasterService: ToastrService,
    private readonly translate: TranslateService
  ) {
    super(bsModalRef);
  }

  public ngOnInit(): void {
    this.users = this.filteredUsers();
    this.selectedUsers = this.selectedUsers.filter(su => su.deleted);
  }

  private filteredUsers(): User[] {
    return this.users.filter(u => u.deleted && !this.selectedUsers.some(su => su.id === u.id));
  }

  public isInSelectedInit(email: string) {
    return this.users.some(u => u.email === email);
  }

  public customSearchUser(search: string, user: User) {
    return ['firstname', 'lastname', 'email'].some(k => user[k]?.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  public activateUsers(): void {
    const updatedUsers = this.selectedUsers.map(u => ({ ...u, deleted: false } as User));
    this.subscription = this.companyUser.bulk(updatedUsers, this.companyId).subscribe(() => {
      this.toasterService.success(this.translate.instant(this.successLabel, { length: this.selectedUsers.length }));
      this.close();
    });
  }
}
