import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LoggingService } from './logging.service';
import { DynamicConfigModule } from '@cleverconnect/ngx-dynamic-config';

@NgModule({
  imports: [CommonModule, HttpClientModule, DynamicConfigModule],
  providers: [LoggingService],
})
export class LoggingModule {}
