import { Component, ViewChild } from '@angular/core';

import { ModalService } from '../../shared/layout/modal/modal.service';
import { Status } from './status';
import { StatusAddModalComponent } from './status-add-modal/status-add-modal.component';
import { StatusListComponent } from './status-list/status-list.component';
import { StatusService } from './status.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  @ViewChild('statusListComponent', { static: true }) public statusListComponent: StatusListComponent;

  public searchBy = '';

  constructor(private statusService: StatusService, private modalService: ModalService) {}

  public onCreate() {
    this.reloadTable();
  }

  public onDelete(statusId: number) {
    this.statusService.delete(statusId).subscribe(() => this.reloadTable());
  }

  public onEdit(status: Status): void {
    const modal = this.modalService.show(StatusAddModalComponent, {
      class: 'modal-dialog-centered',
      initialState: { status },
    });
    modal.content.statusUpdatedEvent.subscribe({
      next: () => this.reloadTable(),
    });
  }

  private reloadTable() {
    this.statusListComponent.tableStatus.reload();
  }
}
