import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcePipe } from './resources/resource.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { McqOptionsComponent } from 'src/app/forms/shared/mcq-options/mcq-options.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  declarations: [ResourcePipe, McqOptionsComponent],
  exports: [ResourcePipe, McqOptionsComponent],
})
export class BusinessModule {}
