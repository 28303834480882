import * as Sentry from '@sentry/angular-ivy';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Injectable } from '@angular/core';
import { User } from 'src/app/auth/user';
import { filter } from 'rxjs';

import packageJson from '../../../../package.json';
import { Config } from '../config/config';

@Injectable({ providedIn: 'root' })
export class SentryService {
  private enabled = false;
  public version: string = packageJson.version;

  constructor(configService: DynamicConfigService) {
    configService
      .load<Config>()
      .pipe(filter((fConfig: Config) => !!fConfig.sentryUrl))
      .subscribe((config: Config) => {
        Sentry.init({
          environment: config.environment,
          dsn: config.sentryUrl,
          release: this.version.replace('.0-SNAPSHOT', '-SNAPSHOT'),
          initialScope: {
            tags: { context: config.context.toUpperCase() },
          },
          integrations: [new Sentry.Replay()],
        });
        this.enabled = true;
      });
  }

  setUserContext(user: Partial<User>) {
    if (!this.enabled) {
      return;
    }

    Sentry.setUser(user);
  }

  captureException(err: Error) {
    if (!this.enabled) {
      return;
    }

    Sentry.captureException(err);
  }

  sendEvent(event: string) {
    if (!this.enabled) {
      return;
    }

    Sentry.captureMessage(event);
  }
}
