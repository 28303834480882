import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-emoji-popover',
  templateUrl: './emoji-popover.component.html',
  styleUrls: ['./emoji-popover.component.scss'],
})
export class EmojiPopoverComponent {
  public emojis = [
    { key: '😀', label: 'Grinning Face' },
    { key: '😁', label: 'Beaming Face With Smiling Eyes' },
    { key: '😂', label: 'Face With Tears of Joy' },
    { key: '🤣', label: 'Rolling on the Floor Laughing' },
    { key: '😃', label: 'Grinning Face With Big Eyes' },
    { key: '😄', label: 'Grinning Face With Smiling Eyes' },
    { key: '😅', label: 'Grinning Face With Sweat' },
    { key: '😆', label: 'Grinning Squinting Face' },
    { key: '😉', label: 'Winking Face' },
    { key: '😊', label: 'Smiling Face With Smiling Eyes' },
    { key: '😋', label: 'Face Savoring Food' },
    { key: '😎', label: 'Smiling Face With Sunglasses' },
    { key: '😍', label: 'Smiling Face With Heart - Eyes' },
    { key: '😘', label: 'Face Blowing a Kiss' },
    { key: '😗', label: 'Kissing Face' },
    { key: '😙', label: 'Kissing Face With Smiling Eyes' },
    { key: '😚', label: 'Kissing Face With Closed Eyes' },
    { key: '🙂', label: 'Slightly Smiling Face' },
    { key: '🤗', label: 'Hugging Face' },
    { key: '🤩', label: 'Star - Struck' },
    { key: '🤔', label: 'Thinking Face' },
    { key: '🤨', label: 'Face With Raised Eyebrow' },
    { key: '😐', label: 'Neutral Face' },
    { key: '😑', label: 'Expressionless Face' },
    { key: '😶', label: 'Face Without Mouth' },
    { key: '🙄', label: 'Face With Rolling Eyes' },
    { key: '😏', label: 'Smirking Face' },
    { key: '😣', label: 'Persevering Face' },
    { key: '😥', label: 'Sad but Relieved Face' },
    { key: '😮', label: 'Face With Open Mouth' },
    { key: '🤐', label: 'Zipper - Mouth Face' },
    { key: '😯', label: 'Hushed Face' },
    { key: '😪', label: 'Sleepy Face' },
    { key: '😫', label: 'Tired Face' },
    { key: '😴', label: 'Sleeping Face' },
    { key: '😌', label: 'Relieved Face' },
    { key: '😛', label: 'Face With Tongue' },
    { key: '😜', label: 'Winking Face With Tongue' },
    { key: '😝', label: 'Squinting Face With Tongue' },
    { key: '🤤', label: 'Drooling Face' },
    { key: '😒', label: 'Unamused Face' },
    { key: '😓', label: 'Downcast Face With Sweat' },
    { key: '😔', label: 'Pensive Face' },
    { key: '😕', label: 'Confused Face' },
    { key: '🙃', label: 'Upside - Down Face' },
    { key: '🤑', label: 'Money - Mouth Face' },
    { key: '😲', label: 'Astonished Face' },
    { key: '🙁', label: 'Slightly Frowning Face' },
    { key: '😖', label: 'Confounded Face' },
    { key: '😞', label: 'Disappointed Face' },
    { key: '😟', label: 'Worried Face' },
    { key: '😤', label: 'Face With Steam From Nose' },
    { key: '😢', label: 'Crying Face' },
    { key: '😭', label: 'Loudly Crying Face' },
    { key: '😦', label: 'Frowning Face With Open Mouth' },
    { key: '😧', label: 'Anguished Face' },
    { key: '😨', label: 'Fearful Face' },
    { key: '😩', label: 'Weary Face' },
    { key: '🤯', label: 'Exploding Head' },
    { key: '😬', label: 'Grimacing Face' },
    { key: '😰', label: 'Anxious Face With Sweat' },
    { key: '😱', label: 'Face Screaming in Fear' },
    { key: '😳', label: 'Flushed Face' },
    { key: '🤪', label: 'Zany Face' },
    { key: '😵', label: 'Dizzy Face' },
    { key: '😡', label: 'Pouting Face' },
    { key: '😠', label: 'Angry Face' },
    { key: '🤬', label: 'Face With Symbols on Mouth' },
    { key: '😷', label: 'Face With Medical Mask' },
    { key: '🤒', label: 'Face With Thermometer' },
    { key: '🤕', label: 'Face With Head - Bandage' },
    { key: '🤢', label: 'Nauseated Face' },
    { key: '🤮', label: 'Face Vomiting' },
    { key: '🤧', label: 'Sneezing Face' },
    { key: '😇', label: 'Smiling Face With Halo' },
    { key: '🤠', label: 'Cowboy Hat Face' },
    { key: '🤡', label: 'Clown Face' },
    { key: '🤥', label: 'Lying Face' },
    { key: '🤫', label: 'Shushing Face' },
    { key: '🤭', label: 'Face With Hand Over Mouth' },
    { key: '🧐', label: 'Face With Monocle' },
    { key: '🤓', label: 'Nerd Face' },
    { key: '😈', label: 'Smiling Face With Horns' },
    { key: '👿', label: 'Angry Face With Horns' },
    { key: '👹', label: 'Ogre' },
    { key: '👺', label: 'Goblin' },
    { key: '💀', label: 'Skull' },
    { key: '☠', label: 'Skull and Crossbones' },
    { key: '👻', label: 'Ghost' },
    { key: '👽', label: 'Alien' },
    { key: '👾', label: 'Alien Monster' },
    { key: '🤖', label: 'Robot Face' },
    { key: '💩', label: 'Pile of Poo' },
  ];

  @Input()
  public placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'left';

  @Output()
  public click: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onClick(key: string) {
    this.click.emit(key);
  }
}
