import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecruitmentMode } from './recruitment-mode';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecruitmentModeService {
  private modes: RecruitmentMode[] = [];

  constructor(private translate: TranslateService) {
    this.modes = environment.recruitmentModes.map(m => {
      const mode = `RECRUITMENT_MODE.${m.key.toUpperCase()}`;
      return { languages: m.languages, value: m.key, label: this.translate.instant(mode) };
    });
  }

  getAll(): RecruitmentMode[] {
    return this.modes;
  }

  get(mode: string): RecruitmentMode {
    return this.modes.filter(m => m.value === mode)[0];
  }
}
