import { HttpParams } from '@angular/common/http';

export function createHttpParams(params: { [key: string]: any }): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(key => {
    const param = params[key];
    if (!param) {
      return;
    }

    if (Array.isArray(param)) {
      param.forEach(p => (httpParams = httpParams.append(`${key}[]`, p)));
    } else {
      httpParams = httpParams.set(key, param.toString());
    }
  });

  return httpParams;
}
