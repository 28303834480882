<div class="position-relative mx-2" dropdown placement="bottom right">
  <button class="btn btn-light px-1 d-flew border flew-row align-items-center mh-names {{ menuClasses }}" dropdownToggle type="button">
    <i
      *ngIf="!me.avatar"
      class="p-1 me-1 material-icons md-24 rounded-circle fw-bold border border-3"
      data-cy="I-e309"
      [ngClass]="isWhite === false ? 'border-white text-white' : 'border-primary text-primary'"
      >perm_identity</i
    >
    <img *ngIf="me.avatar" class="user-avatar" src="{{ me.avatar }}" alt="user avatar" data-cy="I-e300987" />
    <span class="m-0 fw-semibold fs-7 fs--7" *ngIf="me.lastname" [ngClass]="isWhite === false ? 'text-white' : ''"
      >{{ me.firstname }} {{ me.lastname }}</span
    >
    <span
      class="m-0 fw-semibold fs-7"
      *ngIf="!me.lastname"
      [ngClass]="isWhite === false ? 'text-white' : ''"
      [translate]="'PRIVATE_SHARE.HEADER.UNKNOWN.USER'"
    ></span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
    <li *ngFor="let item of items">
      <a href="javascript:void(0)" class="dropdown-item text-decoration-none" (click)="action.emit(item)">
        {{ item.translate | translate }}
      </a>
    </li>
  </ul>
</div>
