<div class="card h-100 p-relative" [class.linked]="destination" data-cy="DIV-b512">
  <div class="card-img-top text-center py-3" data-cy="DIV-065e">
    <img       *ngIf="previewUrl && !resource"
      src="{{previewUrl}}"
      [style.maxWidth]="imgMaxWidth"
      [style.maxHeight]="imgMaxHeight"
    >
    <app-resource-viewer       *ngIf="resource"
      class="col-7 col-sm-4 col-lg-10 d-block"
      [resource]="resource"
      [style.maxWidth]="imgMaxWidth"
      [style.maxHeight]="imgMaxHeight"
      [isPreviewable]="false"
    ></app-resource-viewer>
  </div>
  <div class="card-body" data-cy="DIV-ab92"><a *ngIf="destination" [routerLink]="destination" href="javascript:void(0)" class="stretched-link text-reset text-decoration-none" data-cy="A-0044">
      <h5 class="card-title text-truncate" data-cy="H5-560e">
        {{title}}</h5>
    </a>
    <h5 *ngIf="!destination" class="card-title text-truncate" data-cy="H5-d82b">
      {{title}}
    </h5>
    <div class="card-text pt-2 text-muted" data-cy="DIV-a7f1">
      {{content}}
      <div class="row g-2" data-cy="DIV-3809">
        <div class="col-6" *ngIf="language" data-cy="DIV-f38f">
          <img             src="//visiotalent-emails.s3.amazonaws.com/flags/{{language}}.png"
            class="language-flag"
          >
        </div>
        <div class="col text-end tile-action" data-cy="DIV-d74e">
          <button *ngIf="editEvent.observers.length" type="button" class="btn btn-img text-secondary" (click)="edit(id)" [tooltip]="'COMMON.EDITER' | translate" data-cy="BUTTON-62cd">
            <i class="material-icons" data-cy="I-851f">edit</i>
          </button>
          <button *ngIf="deleteEvent.observers.length" type="button" class="btn btn-img text-secondary" (click)="delete(id)" [tooltip]="'COMMON.SUPPRIMER' | translate" data-cy="BUTTON-0360">
            <i class="material-icons" data-cy="I-f4f9">delete</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
