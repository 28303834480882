import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CcTableModule } from '@cleverconnect/ngx-bootstrap-table';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { EmailingModule } from './email-templates/email-templates.module';
import { MediasListComponent } from './medias/medias-list/medias-list.component';
import { MediasModule } from './medias/medias.module';
import { SettingsHomeComponent } from './settings-home/settings-home.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StatusModule } from './status/status.module';
import { ManagerModule } from './managers/managers.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    SettingsRoutingModule,
    MediasModule,
    EmailingModule,
    ManagerModule,
    StatusModule,
    CcTableModule,
    TabsModule,
    BsDropdownModule,
  ],
  providers: [DatePipe],
  declarations: [ SettingsHomeComponent, MediasListComponent],
})
export class SettingsModule {}
