import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';

@Component({
  selector: 'app-emailing-reset-modal',
  templateUrl: './emailing-reset-modal.component.html',
  styleUrls: ['./emailing-reset-modal.component.scss'],
})
export class EmailingResetModalComponent extends ModalBaseComponent<object> implements OnDestroy {
  @Output() public resetEvent = new EventEmitter();

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  public ngOnDestroy(): void {
    if (!this.resetEvent.closed) {
      this.resetEvent.complete();
    }
    super.ngOnDestroy();
  }
}
