import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalBaseComponent } from '../../../shared/layout/modal/modal-base-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StatusService } from '../status.service';
import { Status } from '../status';

@Component({
  selector: 'app-status-add-modal',
  templateUrl: './status-add-modal.component.html',
})
export class StatusAddModalComponent extends ModalBaseComponent<object> implements OnInit {
  public formGroup: UntypedFormGroup;

  @Input()
  public status: Status = null;

  @Output()
  public statusCreatedEvent = new EventEmitter();

  @Output()
  public statusUpdatedEvent = new EventEmitter();

  constructor(bsModalRef: BsModalRef, private formBuilder: UntypedFormBuilder, private statusService: StatusService) {
    super(bsModalRef);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      statusName: [this.status ? this.status.value : '', Validators.required],
    });
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }

    if (this.status && this.status.id) {
      this.status.value = this.formGroup.controls.statusName.value;
      return this.updateStatus(this.status);
    }

    const status = new Status();
    status.value = this.formGroup.controls.statusName.value;

    return this.createStatus(status);
  }

  private async createStatus(status: Status) {
    return this.statusService.create(status).subscribe(s => {
      this.close();
      this.statusCreatedEvent.emit(s);
    });
  }

  private async updateStatus(status: Status) {
    return this.statusService.update(status).subscribe(s => {
      this.close();
      this.statusUpdatedEvent.emit(s);
    });
  }
}
