import { AccountTypesFactory } from 'src/app/panel/shared/account-types.factory';
import { CommonModule } from '@angular/common';
import { CompanyUserActionsService } from 'src/app/panel/companies/company-users/services/company-user-actions.service';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharePopinAccountComponent } from './share-popin-account.component';

@NgModule({
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, TranslateModule],
  providers: [AccountTypesFactory, CompanyUserActionsService],

  declarations: [SharePopinAccountComponent],
  exports: [SharePopinAccountComponent],
})
export class SharePopinAccountModule {}
