import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from '../config/config';
import { map, concatAll } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, public configService: DynamicConfigService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    const keepToken = !request.headers.has('noToken');
    const config = this.configService.get<Config>();

    const isLocal = config && config.isLocal;

    if (keepToken) {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      if (!isLocal) {
        request = request.clone({
          withCredentials: true,
        });
      }
    }

    request = request.clone({
      headers: request.headers.delete('noToken'),
    });

    return next.handle(request);
  }
}
