import { Injectable } from '@angular/core';
import { Resource } from 'src/app/shared/business/resources/resource';
import { ModalService } from 'src/app/shared/layout/modal/modal.service';
import { MediasDeleteModalComponent } from './medias-delete-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MediasDeleteModalService {
  constructor(private modalService: ModalService) {}

  public openModal(resource?: Resource, options?: any) {
    const initialState = { ...options, mediaId: resource.id };

    const bsModalRef = this.modalService.show(MediasDeleteModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-secondary modal-dialog-centered',
    });
    const modalComponent = bsModalRef.content as MediasDeleteModalComponent;
    return modalComponent.onClose;
  }
}
