export class BreadcrumbItem {
  public url!: string;
  public title!: string;
  public isActive!: boolean;

  constructor(title?: string, url?: string, isActive?: boolean) {
    this.title = title;
    this.url = url;
    this.isActive = isActive;
  }
}
