import { ActivatedRoute } from '@angular/router';
import { CcTableComponent, TableAction, TableColumn } from '@cleverconnect/ngx-bootstrap-table';
import { Company } from 'src/app/panel/companies/company';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Config } from 'src/app/shared/config/config';
import { DatePipe } from '@angular/common';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { LanguageService, LanguageType } from 'src/app/shared/business/languages/language.service';
import { MediasDeleteModalService } from 'src/app/settings/medias/medias-delete-modal/medias-delete-modal.service';
import { Resource } from 'src/app/shared/business/resources/resource';
import { ResourceMediaEditModalService } from 'src/app/shared/layout/resource/resource-media-edit-modal/resource-media-edit-modal.service';
import { ResourceMediaUploadModalService } from 'src/app/shared/layout/resource/resource-media-upload-modal/resource-media-upload-modal.service';
import { ResourceService } from 'src/app/shared/business/resources/resource.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/auth/user';
import { UserThumbnail } from '@cleverconnect/ngx-user-thumbnail';
import { getCcTableLabels } from 'src/app/shared/table/labels';

@Component({
  selector: 'app-company-contents-medias',
  templateUrl: './medias-list.component.html',
  styleUrls: ['./medias-list.component.scss'],
})
export class MediasListComponent implements OnInit {
  @ViewChild('avatarTemplate', { static: true }) public avatarTemplate: TemplateRef<unknown>;
  @ViewChild('languageTemplate', { static: true }) public languageTemplate: TemplateRef<unknown>;
  @ViewChild('previewTitleTemplate', { static: true }) public previewTitleTemplate: TemplateRef<unknown>;
  @ViewChild('teamsListTemplate', { static: true }) public teamsListTemplate: TemplateRef<unknown>;
  @ViewChild('usersTemplate', { static: true }) public usersTemplate: TemplateRef<unknown>;
  @ViewChild('typeTemplate', { static: true }) public typeTemplate: TemplateRef<unknown>;
  @ViewChild('modeTemplate', { static: true }) public modeTemplate: TemplateRef<unknown>;
  @ViewChild('actionsTemplate', { static: true }) public actionsTemplate: TemplateRef<unknown>;
  @ViewChild('ccTableUser', { static: true }) public tableUsers: CcTableComponent<Resource>;

  public company: Company;
  public columns: TableColumn[];
  public columnsSharing: TableColumn[];
  public resourceUrl: string;
  public nbUserMedia: number;
  public nbSharedMedia: number;
  public tableLabels = getCcTableLabels(this.translate);

  public languages: LanguageType[] = [];
  public resourcesLabel: { label: string; value: string }[] = [];

  public user: User;
  public users: { id: number; title: string; avatar }[];

  public globalSearch: string;
  public userFilter: string;

  public displayShared: { label: string; value: string }[] = [
    {
      label: this.translate.instant('MEDIAS.SELECT.SHARED.YES'),
      value: 'me',
    },
    {
      label: this.translate.instant('MEDIAS.SELECT.SHARED.NO'),
      value: 'other',
    },
  ];

  public languageFilter: string;
  public typeFilter: { value: string; label: string };

  public actions: TableAction<Resource>[] = [
    {
      title: this.translate.instant('COMMON.MODIFIER'),
      action: 'edit',
      icon: "<i class='material-icons md-24'>edit</i>",
    },
    {
      title: this.translate.instant('SETTINGS.MEDIAS.USER.MEDIAS.ACTION.DELETE'),
      action: 'delete',
      icon: "<i class='material-icons md-24'>delete</i>",
    },
  ];

  constructor(
    private readonly datePipe: DatePipe,
    private readonly translate: TranslateService,
    private readonly languagesService: LanguageService,
    private route: ActivatedRoute,
    private configService: DynamicConfigService,
    private resourceService: ResourceService,
    private resourceMediaUploadModalService: ResourceMediaUploadModalService,
    private resourceMediaEditModalService: ResourceMediaEditModalService,
    private mediasDeleteModalService: MediasDeleteModalService
  ) {
    this.user = this.route.snapshot.data.me;
    this.resourceUrl = `${this.configService.get<Config>().apiUrl}/resources`;
  }

  async ngOnInit() {
    this.languages = await this.languagesService.getCandidateLanguages();
    this.resourcesLabel = this.resourceService.getAllTypes();

    const columnTitle: TableColumn = {
      title: this.translate.instant('SETTINGS.MEDIAS.USER.TABLE.MEDIA_NAME'),
      field: 'title',
      template: this.previewTitleTemplate,
    };

    const columnThumb: TableColumn = {
      title: this.translate.instant('SETTINGS.MEDIAS.USER.TABLE.CREATED_BY'),
      field: 'ownerId',
      classesTd: 'py-1',
      template: this.avatarTemplate,
      formatter: (_, { owner }) =>
        ({
          url: owner ? owner.avatar : 'assets/images/person.svg',
          label: owner ? `${owner.firstname || ''} ${owner.lastname || ''}` : undefined,
        } as UserThumbnail),
    };

    const columnType: TableColumn = {
      title: this.translate.instant('SETTINGS.MEDIAS.USER.TABLE.TYPE'),
      field: 'type',
      template: this.typeTemplate,
      formatter: (_, l) => ({ label: this.resourcesLabel.find(r => r.value === l.type).label, color: this.badgeColorMode(l.type) }),
    };

    const columnCreatedAt: TableColumn = {
      title: this.translate.instant('SETTINGS.MEDIAS.USER.TABLE.CREATED_AT'),
      field: 'createdAt',
      classesHeaderTh: 'd-none d-md-table-cell',
      classesTd: 'd-none d-md-table-cell',
      formatter: d => this.datePipe.transform(d, 'shortDate'),
    };

    const columnLanguage: TableColumn = {
      title: this.translate.instant('SETTINGS.MEDIAS.USER.TABLE.LANGUAGE'),
      field: 'language',
      template: this.languageTemplate,
    };

    const columnActions = { title: '', classesTd: 'p-0', template: this.actionsTemplate };

    this.columns = [columnTitle, columnThumb, columnType, columnCreatedAt, columnLanguage, columnActions];
    this.columnsSharing = [columnTitle, columnThumb, columnType, columnCreatedAt, columnLanguage];
  }

  public get filter() {
    const searchFilters = { $and: [] };

    if (this.globalSearch) {
      searchFilters.$and = this.globalSearch
        .trim()
        .split(' ')
        .map(s => ({ $and: [{ title: { $contL: s } }] }));
    }

    if (this.languageFilter) {
      searchFilters.$and.push({ language: this.languageFilter });
    }

    if (this.typeFilter) {
      searchFilters.$and.push({ type: this.typeFilter });
    }

    searchFilters.$and.push({ ownerId: this.user.id });

    return {
      s: JSON.stringify(searchFilters),
      join: ['owner'],
    };
  }

  public get filterSharing() {
    const searchFilters = { $and: [] };

    if (this.globalSearch) {
      searchFilters.$and = this.globalSearch
        .trim()
        .split(' ')
        .map(s => ({ $and: [{ title: { $contL: s } }] }));
    }

    if (this.languageFilter) {
      searchFilters.$and.push({ language: this.languageFilter });
    }

    if (this.typeFilter) {
      searchFilters.$and.push({ type: this.typeFilter });
    }

    searchFilters.$and.push({ ownerId: { $ne: this.user.id } });
    return {
      s: JSON.stringify(searchFilters),
      join: ['owner'],
    };
  }

  public badgeColorMode(type) {
    const colorMode = {
      image: 'image-badge',
      'video-welcome': 'welcome-badge',
      'video-thanks': 'thanks-badge',
      'video-question': 'question-video-badge',
    };
    return colorMode[type];
  }

  public imgError(event) {
    event.target.src = 'https://visiotalent-emails.s3.amazonaws.com/flags/vt.png';
  }

  public addMedia() {
    this.resourceMediaUploadModalService.openResourceModal().subscribe({
      next: resource => {
        this.editModal(resource, true);
      },
    });
  }

  public editModal(event, isNew = false) {
    if (+event.ownerId !== +this.user.id) {
      return;
    }
    const options = { isNew };
    this.resourceMediaEditModalService.openModal(event, options).subscribe({
      next: () => {
        this.tableUsers.reload();
      },
    });
  }

  public delete(event) {
    this.mediasDeleteModalService.openModal(event).subscribe({
      next: () => {
        this.tableUsers.reload();
      },
    });
  }

  public onUserAction(action: string, resource: Resource) {
    switch (action) {
      case 'edit':
        this.editModal(resource, false);
        break;
      case 'delete':
        this.delete(resource);
        break;
    }
  }
}
