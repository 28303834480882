import { Component, LOCALE_ID, Inject } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { PostHogService } from './shared/posthog/posthog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    localeService: BsLocaleService,
    @Inject(LOCALE_ID) locale: string,
    private readonly postHogService: PostHogService,
  ) {
    localeService.use(locale);
    angulartics2GoogleAnalytics.startTracking();
    this.postHogService.enable();
  }
}
