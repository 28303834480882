import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Team } from 'src/app/panel/shared/entities/team';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/auth/user';

import { SelectUsersTeams } from './select-users-teams';
import { SharingRecipientType } from '../../sharing/entities/sharing-recipient-type.enum';

@Component({
  selector: 'app-select-users-teams',
  templateUrl: './select-users-teams.component.html',
  styleUrls: ['./select-users-teams.component.scss'],
})
export class SelectUsersTeamsComponent implements OnDestroy, OnInit {
  @Input() multiple: boolean = false;
  @Input() isLabelSecondary: boolean = false;
  @Input() restrictToOneType: boolean = false;
  @Input() groupResults: boolean = false;
  @Input() label: string = this.translate.instant('PANEL.USERS.FILTERS.USERTEAM');
  @Input() placeholder: string = this.translate.instant('PANEL.COMPANY.CONTENT.CAMPAIGN.FILTER.PLACEHOLDER.USERTEAM');
  @Input() users: Promise<{ data: User[] }>;
  @Input() teams: Promise<{ data: Team[] }>;
  @Input() companyId: string;
  @Input() enableThumbnail = true;
  @Input() selectedValues: { type: string; id: string }[];
  @Input() clearable = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<SelectUsersTeams[] | SelectUsersTeams>();

  public loading: boolean = true;
  public selectUsersTeamsForm: UntypedFormGroup;
  public items: Promise<SelectUsersTeams[]>;
  public currentCount: { users: number; teams: number } = { teams: 0, users: 0 };

  constructor(private translate: TranslateService, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.selectUsersTeamsForm = this.fb.group({
      selectedUsersTeamsId: [],
    });

    const sources = [];
    if (this.users) {
      sources.push(this.users.then(u => this.formatedUsers(u)));
    }
    if (this.teams) {
      sources.push(this.teams.then(t => this.formatedTeams(t)));
    }
    if (this.companyId) {
      sources.unshift(
        Promise.resolve({
          id: this.companyId,
          type: 'company',
          title: this.translate.instant('COMMON.TEAMS.ALL'),
          avatar: { label: this.translate.instant('COMMON.TEAMS.ALL') },
        })
      );
    }

    this.items = Promise.all(sources)
      .then(res => {
        const flat = res.flat();
        if (this.selectedValues && this.selectedValues.length) {
          const selected = flat.filter(f => this.selectedValues.some(s => s.id.toString() === f.id.toString() && s.type === f.type));
          if (selected) {
            this.selectUsersTeamsForm.get('selectedUsersTeamsId').patchValue(this.multiple ? selected : selected[0]);
          }
        }
        this.loading = false;
        return flat;
      })
      .catch(e => {
        console.error(e);
        return [];
      });
  }

  ngOnDestroy(): void {
    this.select.unsubscribe();
  }

  public onChange(results: SelectUsersTeams[] | SelectUsersTeams) {
    if (Array.isArray(results)) {
      this.currentCount.teams = results.filter(u => u.type === 'team').length;
      this.currentCount.users = results.filter(u => u.type === 'user').length;
      if (this.restrictToOneType) {
        this.items = this.items.then(items =>
          items.map(i => {
            i.disabled = (i.type === 'user' && this.currentCount.teams > 0) || (i.type === 'team' && this.currentCount.users > 0);
            return i;
          })
        );
      }
      this.select.emit(results);
    } else if (results) {
      this.select.emit([results]);
    } else {
      this.select.emit([]);
    }
  }

  public customSearchTeamOrUser(search: string, user) {
    return ['title'].some(k => user[k].toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  private formatedUsers(users: { data: User[] }): SelectUsersTeams[] {
    return !users
      ? []
      : users.data.map(user => {
          return {
            type: SharingRecipientType.user,
            typeI18n: this.translate.instant('PANEL.COMPANY.CONTENTS.CAMPAIGNS.USERS'),
            id: user.id,
            title: `${user.firstname} ${user.lastname}`,
            avatar: { url: user.avatar, label: `${user.firstname} ${user.lastname}` },
            disabled: false,
          };
        });
  }

  private formatedTeams(teams: { data: Team[] }): SelectUsersTeams[] {
    return !teams
      ? []
      : teams.data.map(team => {
          return {
            type: SharingRecipientType.team,
            typeI18n: this.translate.instant('PANEL.COMPANY.CONTENTS.CAMPAIGNS.TEAMS'),
            id: team.id,
            title: team.name,
            avatar: { url: '', label: team.name },
            disabled: false,
          };
        });
  }
}
