import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { take, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from 'src/app/shared/config/config';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class AuthUserResolver implements Resolve<User> {
  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: DynamicConfigService,
    private location: Location,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.me().pipe(
      take(1),
      map(p => {
        if (!p && this.configService.get<Config>().redirectOnError) {
          this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
        }
        return p;
      }),
      catchError(err => {
        if (this.configService.get<Config>().redirectOnError) {
          this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
          return throwError(err);
        }
      }),
    );
  }
}
