<div data-cy="DIV-0372">
  <section [class.scroll]="hasScrolled" class="position-relative header text-center text-white w-100 {{ backgroundClasses }}">
    <div class="d-flex flex-wrap content align-items-center" data-cy="DIV-4494">
      <div class="col-10 col-sm-auto flex-grow-1" data-cy="DIV-b583">
        <h3 class="mb-0" data-cy="H3-b161">{{ title }}</h3>
      </div>
      <div class="col-2" data-cy="DIV-d1ba">
        <div data-cy="DIV-4d56" *ngIf="hasMenu">
          <app-account-menu-selector
            [me]="me"
            [items]="itemsMenuHeader"
            (action)="runActionItem($event)"
            [menuClasses]="colorBackground"
          ></app-account-menu-selector>
        </div>
      </div>
    </div>
  </section>
</div>
