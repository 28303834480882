import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NotificationsInterceptor implements HttpInterceptor {
  constructor(private toasterService: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse && !req.params.has('no-toast-on-error')) {
          switch (err.status) {
            case 0:
              this.toasterService.error('une erreur est survenue');
              break;
            case 500:
              this.toasterService.error(err.error.message || 'une erreur est survenue');
              break;
          }
        }
        return throwError(err);
      })
    );
  }
}
