<div [formGroup]="form" data-cy="DIV-b759">
  <ngx-editor-menu [editor]="editor" [toolbar]="[]" [customMenuRef]="customMenu"> </ngx-editor-menu>
  <ngx-editor [editor]="editor" [formControlName]="content" [placeholder]="placeholder" (click)="changeColorBlack()"> </ngx-editor>

  <ng-template #customMenu>
    <div class="header d-flex flex-row w-100 pe-auto" data-cy="DIV-8556">
      <div class="bg-primary text-white p-2 border border-primary d-flex flex-row w-25" role="button" (click)="reset()" data-cy="DIV-59a7">
        <span aria-hidden="true" class="px-2" data-cy="SPAN-83a8">&times;</span>
        <span [translate]="'EMAILING.EDITOR.RESET'" data-cy="SPAN-7409"></span>
      </div>
      <ng-select
        class="pt-1 bg-primary border-0 rounded-0 pe-auto select-menu w-50"
        role="button"
        [items]="tags"
        [placeholder]="'EMAILING.EDITOR.ATTRIBUTS' | translate"
        [searchable]="false"
        [clearable]="false"
      >
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <span class="align-middle ms-2 w-100 d-block" (click)="addTag(item)" data-cy="SPAN-dcb3"> {{ item.label }}</span>
        </ng-template>
      </ng-select>
      <span
        class="bg-primary text-white p-2 border border-primary w-25 text-center"
        role="button"
        (click)="saveEvent.emit()"
        [translate]="'EMAILING.EDITOR.SAVE'"
        data-cy="SPAN-3d47"
      ></span>
    </div>
  </ng-template>
</div>
