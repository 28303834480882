import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { catchError, map, take } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Config } from '../../config/config';
import { User } from '../../../auth/user';
import { ManagerService } from '../services/managers.service';

@Injectable({ providedIn: 'root' })
export class ManagerResolver implements Resolve<User> {
  constructor(
    private managerService: ManagerService,
    private router: Router,
    private configService: DynamicConfigService,
    private location: Location
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userId = +route.paramMap.get('id');

    return this.managerService.getManagerById(userId).pipe(
      take(1),
      map(p => {
        return p;
      }),
      catchError(err => {
        if (this.configService.get<Config>().redirectOnError) {
          this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
          return throwError(err);
        }
      })
    );
  }
}
