<div class="modal-header" data-cy="DIV-a75f">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-ec3e">
    <span aria-hidden="true" data-cy="SPAN-1491">&times;</span>
  </button>

  <div class="modal-title w-100 text-center" data-cy="DIV-6e5a">
    <p class="h3" [translate]="email.id ? 'EMAILING.TITRE_MODAL_EDIT_EMAIL' : 'EMAILING.TITRE_MODAL_ADD_EMAIL'"></p>
  </div>
</div>
<form *ngIf="step === 1" [formGroup]="emailStep1Group" (ngSubmit)="nextStep()" autocomplete="off">
  <div class="modal-body block-max-width mx-auto" data-cy="DIV-e237">
    <div class="mb-3" data-cy="DIV-1d3d">
      <label class="text-secondary form-label" for="emailingName" data-cy="LABEL-a6eb">
        {{ 'EMAILING.LABEL_NOM_EMAIL_TYPE' | translate }} :
      </label>
      <input
        id="emailingName"
        type="text"
        class="form-control"
        [class.is-invalid]="emailStep1Group.isSubmitted && emailStep1Group.get('title').errors?.required"
        tabindex="1"
        formControlName="title"
        data-cy="INPUT-acb9"
      />
      <div
        *ngIf="emailStep1Group.isSubmitted && emailStep1Group.get('title').errors?.required"
        class="invalid-feedback d-block"
        [translate]="'COMMON.ERRORS.REQUIRED'"
        data-cy="DIV-a7c5"
      ></div>
      <span class="label-info" data-cy="SPAN-37ff">{{ 'EMAILING.LABEL_NOT_VISIBLE_BY_CANDIDAT' | translate }}</span>
    </div>

    <div class="mb-3" data-cy="DIV-14db" *ngIf="modes.length > 1">
      <label class="text-secondary form-label" for="emailingName" data-cy="LABEL-48b0"> {{ 'EMAILING.LABEL_MODE' | translate }} : </label>
      <ng-select name="emailMode" formControlName="mode" [items]="modes" [searchable]="false" bindValue="value" [clearable]="false">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <span class="align-middle ms-2" data-cy="SPAN-a563"> {{ item.label }}</span>
        </ng-template>
      </ng-select>
    </div>
    <div class="d-flex flex-row w-100" data-cy="DIV-9ad9">
      <div class="mb-3 flex-grow-1 me-1" data-cy="DIV-8530">
        <label class="text-secondary form-label" data-cy="LABEL-c438"> {{ 'EMAILING.LABEL_LANGUE_MEDIA' | translate }} : </label>
        <ng-select
          #agreeSelect
          name="emailLanguage"
          formControlName="language"
          [items]="filteredLanguages"
          [searchable]="false"
          bindValue="value"
          [clearable]="false"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <img height="15" width="22" *ngIf="item.value" [alt]="item.value" src="/assets/images/flags/{{ item.value }}.png" />
            <span class="align-middle ms-2" data-cy="SPAN-e53b"> {{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
      <div class="mb-3 flex-grow-1 ms-1" *ngIf="hasType" data-cy="DIV-5153">
        <label class="text-secondary form-label" data-cy="LABEL-494c"> {{ 'EMAILING.LABEL_TYPE_CONTENU' | translate }} : </label>
        <ng-select
          *ngIf="baseTypes.length > 0"
          name="emailBaseTypes"
          formControlName="type"
          [items]="baseTypes"
          [searchable]="false"
          bindValue="value"
          [clearable]="false"
        >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <span class="align-middle ms-2" data-cy="SPAN-9aa0">{{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="mb-3" *ngIf="emailStep1Group.value.type == 'trigger' && customTypes.length > 0" data-cy="DIV-f771">
      <label class="text-secondary form-label" for="emailingTrigger" data-cy="LABEL-daa5">
        {{ 'EMAILING.LABEL_TYPE_CONTENU' | translate }} :
      </label>
      <ng-select
        *ngIf="customTypes.length > 0"
        id="emailingTrigger"
        name="emailingTrigger"
        formControlName="statusId"
        [items]="customTypes"
        [searchable]="false"
        bindValue="value"
        [clearable]="false"
      >
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <span class="align-middle" data-cy="SPAN-6625"> {{ item.label }}</span>
        </ng-template>
      </ng-select>
    </div>
    <div class="mb-3" *ngIf="withSharings" data-cy="DIV-92a6">
      <label class="text-secondary form-label" for="emailingSharings" data-cy="LABEL-3c3b">
        {{ 'EMAILING.PARTAGER_EMAIL' | translate }} :
      </label>
      <ng-select
        name="sharingTeams"
        formControlName="sharings"
        id="emailingSharings"
        [class.is-invalid]="emailStep1Group.isInvalid('sharings')"
        [items]="teams"
        [hideSelected]="true"
        [multiple]="true"
        [placeholder]="(isCompanyAdministrator ? 'COMMON.TEAMS.ALL' : 'PANEL.COMPANY.CONTENT.EMAILTEMPLATE.SHARE.TEAMS.SELECT') | translate"
        [searchable]="false"
        [closeOnSelect]="false"
        bindValue="value"
        [clearable]="true"
      >
        <ng-template ng-option-tmp let-item="item">
          <span class="align-middle" data-cy="SPAN-853e">{{ item.label }}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="align-middle" (click)="clear(item)" data-cy="SPAN-853e" role="button">{{ item.label }}</span>
        </ng-template>
      </ng-select>
      <div
        *ngIf="emailStep1Group.get('sharings').errors?.required"
        class="invalid-feedback"
        [translate]="'COMMON.ERRORS.REQUIRED'"
        data-cy="DIV-1de8"
      ></div>
    </div>
  </div>
  <div class="modal-footer p-0" data-cy="DIV-10a3">
    <app-common-footer class="m-0 w-100" [breadcrumbItems]="breadcrumbItems" [buttons]="buttons"> </app-common-footer>
  </div>
</form>
<form *ngIf="step === 2 && email" [formGroup]="emailStep2Group" autocomplete="off" (ngSubmit)="saveForm()">
  <div class="w-100 d-block" data-cy="DIV-26e6">
    <div class="px-4 py-2 mx-4 div-content-email rounded-2 position-relative overflow-y-auto mb-0" data-cy="DIV-4d10">
      <div
        class="bg-white rounded p-3 mb-1 small text-dark position-relative mx-auto block-max-width"
        *ngIf="!showEditSubject"
        data-cy="DIV-9496"
      >
        <strong [translate]="'MAIL_TEMPLATE.SUBJECT'" class="d-inline-block"></strong>&nbsp;
        <span
          class="d-inline-block fst-italic"
          *ngIf="email.subject === '' || email.subject === '<br/>'"
          [translate]="'MAIL_TEMPLATE.SUBJECT.EMPTY_STATE'"
          data-cy="SPAN-c665"
        ></span>
        <span class="d-inline-block" [appKeywordHighlight]="tagsList" [content]="email.subject" data-cy="SPAN-ebcc"></span>
        <div *ngIf="!showEditContent" class="edit-email-overlay w-100" (click)="showEditSubject = true" data-cy="DIV-c365">
          <i class="material-icons text-success w-100" data-cy="I-b2c3">create</i>
        </div>
      </div>

      <div class="editor border border-primary mb-3 block-max-width mx-auto" *ngIf="showEditSubject" data-cy="DIV-1671">
        <app-editor-html
          [form]="emailStep2Group"
          [tags]="tags"
          [colorText]="colorText"
          content="subject"
          (resetEvent)="reset('subject')"
          (saveEvent)="save('subject')"
        >
        </app-editor-html>
      </div>
      <div
        class="rounded mb-3 small text-dark bg-white position-relative block-max-width mx-auto"
        [class.block-is-empty]="email.content === ''"
        [class.p-3]="email.content === ''"
        [class.text-center]="email.content === ''"
        *ngIf="!showEditContent"
        data-cy="DIV-3124"
      >
        <div #innerhtml [appKeywordHighlight]="tagsList" *ngIf="hasHtml" data-cy="DIV-0bc7"></div>
        <ng-container *ngIf="!hasHtml">
          <span
            *ngIf="email.content !== ''"
            class="p-3 d-inline-block block-is-empty"
            [appKeywordHighlight]="tagsList"
            [content]="email.content"
            data-cy="SPAN-d427"
          ></span>
          <span
            *ngIf="email.content === ''"
            class="fst-italic"
            [translate]="'MAIL_TEMPLATE.CONTENT.EMPTY_STATE'"
            data-cy="SPAN-3ba2"
          ></span>
          <div *ngIf="!showEditSubject" class="edit-email-overlay w-100" (click)="showEditContent = true" data-cy="DIV-2421">
            <i class="material-icons text-success w-100" data-cy="I-eb71">create</i>
          </div>
        </ng-container>
      </div>
      <div class="editor border border-primary mb-3 block-max-width mx-auto" *ngIf="showEditContent" data-cy="DIV-2856">
        <app-editor-html
          [form]="emailStep2Group"
          [tags]="tags"
          [colorText]="colorText"
          content="content"
          (resetEvent)="reset('content')"
          (saveEvent)="save('content')"
        >
        </app-editor-html>
      </div>
    </div>
    <p *ngIf="showMessageError" class="invalid-feedback d-block mx-auto text-center">
      <strong>{{ messageError }}</strong>
    </p>
  </div>
  <div class="modal-footer p-0" data-cy="DIV-3c75">
    <app-common-footer class="m-0 w-100" [breadcrumbItems]="breadcrumbItems" [buttons]="buttons"> </app-common-footer>
  </div>
</form>

<ng-template #buttons>
  <button *ngIf="step === 1" type="submit" class="btn btn-success" [class.disabled]="emailStep1Group.invalid" data-cy="BUTTON-f281">
    <span [translate]="'COMMON.SUIVANT'" data-cy="SPAN-57e7"></span> &nbsp;
    <i class="material-icons" data-cy="I-a3b5">arrow_forward</i>
  </button>
  <button *ngIf="step === 2" class="btn btn-link" type="button" aria-label="return" (click)="step = 1" data-cy=" BUTTON-ec3e2">
    &#8249; {{ 'COMMON.RETOUR' | translate }}
  </button>
  <button *ngIf="step === 2" type="submit" class="btn btn-success" [disabled]="isDisabled()" data-cy="BUTTON-1002">
    <span [translate]="'COMMON.TERMINER'" data-cy="SPAN-69ef"></span> &nbsp;
    <ng-container *ngIf="!isDisabled()"><i class="material-icons" data-cy="I-8dd1">check_circle</i></ng-container>
  </button>
</ng-template>
