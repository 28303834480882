import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { EmailTemplate } from 'src/app/shared/business/emailing/entities/email-template';
import { EmailTemplateRepository } from 'src/app/shared/business/emailing/email-template.repository';
import { EmailTypeEnum } from 'src/app/shared/business/emailing/entities/email-type.enum';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class EmailResolverService implements Resolve<object> {
  constructor(
    private emailTemplateRepository: EmailTemplateRepository,
    private router: Router,
    private configService: DynamicConfigService,
    private location: Location
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): EmailTemplate | Observable<EmailTemplate> {
    const emailId = route.paramMap.get('id') as 'create' | number;

    if (emailId === 'create') {
      const email = new EmailTemplate();

      email.content = '';
      email.subject = '';
      email.language = 'fr';
      email.type = EmailTypeEnum.invitation;
      email.isDefault = false;
      return email;
    }

    return this.emailTemplateRepository.get(emailId).pipe(
      take(1),
      map(p => {
        if (!p && this.configService.get<Config>().redirectOnError) {
          this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
        }
        return p;
      }),
      catchError(err => {
        if (this.configService.get<Config>().redirectOnError) {
          if (err.status === 401 || err.status === 403) {
            this.router.navigate(['/unauthorized']).then(() => this.location.replaceState(state.url));
          } else {
            this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
          }
          return throwError(err);
        }
      })
    );
  }
}
