import { Injectable } from '@angular/core';
import { ModalService } from '../../modal/modal.service';
import { ResourceMediaEditModalComponent } from './resource-media-edit-modal.component';
import { Resource } from 'src/app/shared/business/resources/resource';

@Injectable({
  providedIn: 'root',
})
export class ResourceMediaEditModalService {
  constructor(private modalService: ModalService) {}

  public openModal(resource?: Resource, options?: any) {
    const initialState = { resource, options };

    const bsModalRef = this.modalService.show(ResourceMediaEditModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered',
    });
    const modalComponent = bsModalRef.content as ResourceMediaEditModalComponent;
    return modalComponent.onClose;
  }
}
