import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';

import { LoggingMessage } from './logging-message';
import { Config } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private _config: Observable<Config>;

  protected get config(): Observable<Config> {
    if (!this._config) {
      return (this._config = this.configService.load<Config>());
    }
    return this._config;
  }

  private previousLogs = new Set<string>();

  constructor(
    private readonly http: HttpClient,
    private readonly configService: DynamicConfigService,
  ) {}

  public debug(message: string): void {
    this.log('DEBUG', message);
  }

  public info(message: string): void {
    this.log('INFO', message);
  }

  public warn(message: string, error?: any): void {
    this.log('WARN', message, error);
  }

  public error(message: string, error?: any): void {
    this.log('ERROR', message, error);
  }

  private async log(level: string, message: string, error?: any): Promise<void> {
    try {
      let log = {
        applicationName: 'vtfrontacc',
        level,
        message,
        location: window.location.href,
      } as LoggingMessage;

      if (error) {
        log = Object.assign(log, {
          script: error.fileName,
          line: error.lineNumber,
          column: error.columnNumber,
          stack: error.stack || error.toString(),
        }) as LoggingMessage;
      }

      const hash = JSON.stringify(log);
      if (this.previousLogs.has(hash)) {
        return;
      }
      this.previousLogs.add(hash);

      const config = await lastValueFrom(this.config);

      // log on the server
      return lastValueFrom(this.http.post(`${config.apiUrl}/log-javascript`, log)).then(
        data => data,
        err => {
          console.log('Unexpected error occurs while logging error on server', error, err);
          return err;
        },
      );
    } catch (err) {
      console.error('Unexpected error during logging', error, err);
    }
  }
}
