import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { take, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ResourceService } from '../../shared/business/resources/resource.service';
import { Resource } from '../../shared/business/resources/resource';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from 'src/app/shared/config/config';

@Injectable()
export class MediaResolverService implements Resolve<Resource> {
  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private configService: DynamicConfigService,
    private location: Location
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const mediaId = route.paramMap.get('id');

    return this.resourceService.get(mediaId).pipe(
      take(1),
      map(p => {
        if (!p && this.configService.get<Config>().redirectOnError) {
          this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
        }
        return p;
      }),
      catchError(err => {
        if (this.configService.get<Config>().redirectOnError) {
          if (err.status === 401 || err.status === 403) {
            this.router.navigate(['/unauthorized']).then(() => this.location.replaceState(state.url));
          } else {
            this.router.navigate(['/404']).then(() => this.location.replaceState(state.url));
          }
          return throwError(err);
        }
      })
    );
  }
}
