<div class="text-center pt-3 pb-3" data-cy="DIV-57d2">
  <ng-container *ngIf="!status">
    <p       class="h1"
      [translate]="'STATUS.NOUVEAU_STATUT'"
    ></p>
    <p       class="text-secondary"
      [translate]="'STATUS.COMMENT_NOMME_NOUVEAU_STATUT'"
    ></p>
  </ng-container>
  <ng-container *ngIf="status">
    <p       class="h1"
      [translate]="'STATUS.RENOMMER_STATUT'"
    ></p>
    <p       class="text-secondary"
      [translate]="'STATUS.COMMENT_NOMME_STATUT'"
    ></p>
  </ng-container>
  <form     [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
  >
    <div class="row g-2 mb-3" data-cy="DIV-0ffa">
      <div class="col-8 mx-auto" data-cy="DIV-4c98">
        <input formControlName="statusName" placeholder="Nom du statut" [placeholder]="'STATUS.NOM_STATUT' | translate" class="form-control" data-cy="INPUT-31bf">
      </div>
    </div>
    <p>
      <button class="btn ps-2 pe-2 btn-success" type="submit" [disabled]="!formGroup.valid" [translate]="'STATUS.CREER_STATUT'" *ngIf="!status" data-cy="BUTTON-d072"></button>
      <button class="btn ps-2 pe-2 btn-success" type="submit" [disabled]="!formGroup.valid" [translate]="'STATUS.VALIDER'" *ngIf="status" data-cy="BUTTON-7c1b"></button>
    </p>
  </form>
  <p>
    <a href="javascript:void(0)" class="link" (click)="this.close()" [translate]="'COMMON.ANNULER'" data-cy="A-81c6"></a>
  </p>
</div>
