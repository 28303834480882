import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BreadcrumbItem } from '../../../layout/common-footer/breadcrumb-item';
import { EmailTemplate } from '../entities/email-template';
import { EmailTemplateRepository } from '../email-template.repository';
import { KeywordHighlightPipe } from '../keyword-highlight/keyword-highlight.pipe';
import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './emailing-preview-modal.component.html',
  styleUrls: ['./emailing-preview-modal.component.scss'],
})
export class EmailingPreviewModalComponent extends ModalBaseComponent<EmailTemplate> implements OnInit {
  constructor(
    bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private emailTemplateRepository: EmailTemplateRepository
  ) {
    super(bsModalRef);
  }

  public email!: EmailTemplate;
  public emailTemplateHtml: string;

  public emailStep2Group!: UntypedFormGroup;

  public hasHtml: boolean = false;
  public colorText: string = 'deepskyblue';

  public hasType: boolean = true;

  public tagsList = {
    recruitment_title: this.translate.instant('MAIL_TAG.RECRUITMENT_TITLE'),
    end_date: this.translate.instant('MAIL_TAG.END_DATE'),
    recruiter_fonction: this.translate.instant('MAIL_TAG.RECRUITER_FONCTION'),
    company_name: this.translate.instant('MAIL_TAG.COMPANY_NAME'),
    gender: this.translate.instant('MAIL_TAG.GENDER'),
    firstname: this.translate.instant('MAIL_TAG.FIRSTNAME'),
    lastname: this.translate.instant('MAIL_TAG.LASTNAME'),
    questions_to_prepare: this.translate.instant('MAIL_TAG.QUESTIONS_TO_PREPARE'),
    live_date: this.translate.instant('MAIL_TAG.LIVE_DATE'),
  };
  public tags: { label: string; value: string }[] = [];

  public breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem(this.translate.instant('EMAILING.TITRE_MODAL_ADD_EMAIL'), '', true),
    new BreadcrumbItem(this.translate.instant('COMMON.EDITION'), '', false),
  ];

  private keywordHighlightPipe: KeywordHighlightPipe = new KeywordHighlightPipe();

  @ViewChild('innerhtml') innerhtml!: ElementRef;
  private baliseCustomText: string = '[email-custom-text]';
  private classEltContentHTML: string = 'editor-content d-block';

  private static transformStringToHTML(str: string): HTMLElement {
    return new DOMParser().parseFromString(str, 'text/html').body;
  }

  ngOnInit(): void {
    this.initArrayTags();
    this.hasHtml = true;
    this.initForm();
    this.emailTemplateRepository.preview(this.email.id, null, null).subscribe({
      next: (html: string) => this.initHTML(html),
    });
    return;
  }

  private initArrayTags(): void {
    this.tags = Object.keys(this.tagsList).map(k => {
      return { value: k, label: this.tagsList[k] };
    });
  }

  public initForm(): void {
    const content = this.keywordHighlightPipe.transform(this.email.content || '', this.tagsList, this.colorText);
    const subject = this.keywordHighlightPipe.transform(this.email.subject || '', this.tagsList, this.colorText);

    this.emailStep2Group = this.formBuilder.group({
      subject: new UntypedFormControl({ value: subject, disabled: false }, Validators.required),
      content: new UntypedFormControl({ value: content, disabled: false }, Validators.required),
    });
  }

  private initHTML(emailTemplateHtml: string): void {
    const stringReplace: string = `<span class="${this.classEltContentHTML}" style='position: relative'>${this.email.content}</span>`;
    let val: string = (emailTemplateHtml || '').includes(this.baliseCustomText)
      ? emailTemplateHtml.replace(this.baliseCustomText, stringReplace)
      : stringReplace;
    this.hasHtml = !!emailTemplateHtml;
    val = this.keywordHighlightPipe.transform(val, this.tagsList, this.colorText, true);
    const html: HTMLElement = EmailingPreviewModalComponent.transformStringToHTML(val);
    this.emailTemplateHtml = html.outerHTML;
    setTimeout(() => this.innerhtml.nativeElement.append(html), 1);
  }
}
