import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '../shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent {
  public readonly currentUrl: string;
  public readonly showBackLink: boolean = true;
  public readonly v1Url: string;

  constructor(route: ActivatedRoute, private configService: DynamicConfigService, authService: AuthService) {
    const config = this.configService.get<Config>();

    this.currentUrl = route.snapshot.queryParams.url;
    this.showBackLink = !authService.hasToken();
    this.v1Url = `${config.v1Url}home`;
  }
}
