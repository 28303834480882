<section class="mt-3">
  <div class="row g-2" data-cy="DIV-fc44">
    <h4 class="pt-1 pb-1" data-cy="H4-2543">
      {{ 'STATUS.MES_STATUTS_PERSONALISES' | translate }}&nbsp;
      <span class="badge bg-primary rounded-pill py-1" data-cy="SPAN-c372">{{ statusCount != undefined ? statusCount : '-' }}</span>
    </h4>
  </div>

  <cc-table
    class="last-col-fix"
    [url]="statusUrl"
    (dataUpdated)="statusCount = $event.total"
    tableClasses="table-hover align-middle text-nowrap"
    [columns]="columns"
    [debounceTime]="500"
    [search]="filter"
    [labels]="tableLabels"
    [requestParamsFormatter]="generateParams"
    #ccTable
  ></cc-table>
</section>

<ng-template #dateTemplate let-formatted="formatted">
  {{ formatted['createdAt'] | date : 'shortDate' }}
</ng-template>

<ng-template #actionsTemplate let-line="line" let-data="data">
  <div class="position-relative text-center" dropdown container="body" placement="bottom right" data-cy="DIV-86e0">
    <a
      dropdownToggle
      href="javascript:void(0)"
      (click)="$event.stopPropagation()"
      class="btn btn-link link-secondary text-decoration-none p-1"
      data-cy="A-8faa"
    >
      <i class="material-icons md-24">more_vert</i>
    </a>
    <div class="bg-white">
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
        <li role="menuitem">
          <a
            class="dropdown-item text-secondary"
            [translate]="'STATUS.RENOMMER'"
            (click)="onEdit(line)"
            data-cy="A-8e1f"
            href="javascript:void(0)"
          ></a>
        </li>
        <li role="menuitem">
          <a
            class="dropdown-item text-danger"
            [translate]="'COMMON.SUPPRIMER'"
            (click)="onDelete(line)"
            data-cy="SPAN-01a9"
            href="javascript:void(0)"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
