import { Directive, Renderer2, ElementRef, Input, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appImageLoader]',
})
export class ImageLoaderDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  private transparentPixel: string =
    // tslint:disable-next-line:max-line-length
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAC0CAQAAACXxM65AAAA9klEQVR42u3QMQEAAAwCoNm/9FroAxHIUREFokUjWrRoRItGtGjRiBaNaNGiES0a0aJFI1o0okWLRrRoRIsWjWjRiBYtGtGiES1aNKJFI1q0aESLRrRo0YgWjWjRohEtGtGiRSNaNKJFi0a0aESLFo1o0YgWLRrRohEtWjSiRSNatGhEi0a0aNGIFo1o0aIRLRrRokUjWjSiRYtGtGhEixaNaNGIFi0a0aIRLVo0okUjWrRoRItGtGjRiBaNaNGiES0a0aJFI1o0okWLRrRoRIsWjWjRiBYtGtGiES1aNKJFI1q0aESLRrRo0YgWjWjRohEtGtEbD76MALW4UnceAAAAAElFTkSuQmCC';
  private image: HTMLImageElement;

  private _src: string;
  public get vtsrc(): string {
    return this._src;
  }
  @Input()
  public set vtsrc(src: string) {
    this._src = src;
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.transparentPixel);
    this.renderer.removeClass(this.el.nativeElement, 'error');
    this.renderer.removeClass(this.el.nativeElement, 'loaded');
    this.renderer.addClass(this.el.nativeElement, 'image-loader');
    this.renderer.addClass(this.el.nativeElement, 'loading');
    if (src && typeof src === 'string') {
      this.loadImage(src);
    }
  }

  @Output()
  public load: EventEmitter<undefined> = new EventEmitter();
  @Output()
  public error: EventEmitter<undefined> = new EventEmitter();

  loadImage(url) {
    this.image = new Image();
    this.image.src = url;
    this.image.onload = this.onLoad.bind(this);
    this.image.onerror = this.onError.bind(this);
  }

  private onError() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.transparentPixel);
    this.renderer.removeClass(this.el.nativeElement, 'loading');
    this.renderer.addClass(this.el.nativeElement, 'error');
    this.error.emit();
  }

  private onLoad() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this._src);
    this.renderer.removeClass(this.el.nativeElement, 'loading');
    this.renderer.addClass(this.el.nativeElement, 'loaded');
    this.load.emit();
  }
}
