<ng-select   (change)="onInputChange($event)"
  [items]="items$ | async"
  [(ngModel)]="value"
  [searchable]="true"
  [searchFn]="customSearch"
  [multiple]="multiple"
  [closeOnSelect]="!multiple"
  [loading]="loading"
  [clearSearchOnAdd]="true"
  [selectableGroup]="true"
  [selectableGroupAsModel]="false"
  [placeholder]="placeholder"
  [clearable]="clearable"
  [labelForId]="labelForId"
  [groupBy]="group ? 'type' : null"
  [class.is-invalid]="isInvalid"
>
  <ng-template     ng-label-tmp
    let-item="item"
  >
    <cc-user-thumbnail       size="17"
      *ngIf="enableThumbnail"
      [thumbnail]="item.avatar"
    ></cc-user-thumbnail>
    <span class="align-middle" [ngClass]="{ 'ms-2': enableThumbnail }" data-cy="SPAN-bfc0"> {{ item.title }}</span>
  </ng-template>

  <ng-template     ng-multi-label-tmp
    let-items="items"
    let-clear="clear"
  >
    <div class="ng-value" *ngFor="let item of items | slice: 0:3" data-cy="DIV-4e41">
      <span class="ng-value-label" data-cy="SPAN-07c5">
        <cc-user-thumbnail           size="17"
          *ngIf="enableThumbnail"
          [thumbnail]="item.avatar"
        ></cc-user-thumbnail> {{ item.title }}
      </span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true" data-cy="SPAN-be1b">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 3" data-cy="DIV-8d2a">
      <span class="ng-value-label" data-cy="SPAN-706f">{{ items.length - 3 }} more...</span>
    </div>
  </ng-template>

  <ng-template     ng-option-tmp
    let-item="item"
  >
    <cc-user-thumbnail       size="22"
      *ngIf="enableThumbnail"
      [thumbnail]="item.avatar"
    ></cc-user-thumbnail>
    <span class="align-middle" data-cy="SPAN-07f1"> {{ item.title }}</span>
  </ng-template>

  <ng-template     ng-optgroup-tmp
    let-item="item"
    let-item$="item$"
    let-index="index"
  >
    <input style="vertical-align: middle" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" data-cy="INPUT-3fb5"> {{ item.title }}
    <span style="vertical-align: middle" data-cy="SPAN-6873">{{ 'PANEL.COMPANY.CONTENTS.CAMPAIGNS.' + item.type.toUpperCase() + 'S' | translate }}</span>
    <span class="ml-1 badge badge-secondary" data-cy="SPAN-dc54">{{ item.total }}</span>
  </ng-template>
</ng-select>
