import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationResult } from 'src/app/shared/requests/pagination-result';
import { take } from 'rxjs/operators';

import { EmailTemplate } from './entities/email-template';
import { SearchEmailTemplateDto } from './dtos/search-email-template.dto';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateRepository {
  private config: Config;

  constructor(private readonly http: HttpClient, configService: DynamicConfigService) {
    this.config = configService.get<Config>();
  }

  public getAll(search: SearchEmailTemplateDto = {}): Observable<PaginationResult<EmailTemplate>> {
    Object.keys(search)
      .filter(k => search[k] === undefined || search[k] === null)
      .forEach(k => delete search[k]);
    return this.http.get<PaginationResult<EmailTemplate>>(`${this.config.apiUrl}/email/templates`, { params: { ...search } });
  }

  public preview(id: number, ownerId?: number, profileId?: number): Observable<string> {
    const params: { profileId?: number; ownerId?: number } = {};
    if (profileId) {
      params.profileId = profileId;
    }
    if (ownerId) {
      params.ownerId = ownerId;
    }

    return this.http.get(`${this.config.apiUrl}/email/templates/${id}/preview`, {
      params,
      responseType: 'text',
    });
  }

  public get(id: number, ownerId?: number, profileId?: number) {
    const params: { [param: string]: string | boolean | number } = {};

    if (profileId) {
      params.profileId = profileId;
    }
    if (ownerId) {
      params.ownerId = ownerId;
    }

    return this.http
      .get<EmailTemplate>(`${this.config.apiUrl}/email/templates/${id}`, {
        params,
      })
      .pipe(take(1));
  }

  public patch(id: number, email: Partial<EmailTemplate>) {
    return this.http.patch<EmailTemplate>(`${this.config.apiUrl}/email/templates/${id}`, email);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.config.apiUrl}/email/templates/${id}`);
  }

  public create(email: EmailTemplate): Observable<EmailTemplate> {
    email.id = undefined;
    email.isDefault = false;
    return this.http.post<EmailTemplate>(`${this.config.apiUrl}/email/templates/`, email);
  }
}
