import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MediaType } from 'src/app/settings/medias/media-type';
import { ModalBaseComponent } from '../../../modal/modal-base-component';
import { Media } from 'src/app/shared/business/resources/media';

@Component({
  selector: 'app-resource-viewer-modal',
  templateUrl: './resource-viewer-modal.component.html',
  styleUrls: ['./resource-viewer-modal.component.scss'],
})
export class ResourceViewerModalComponent extends ModalBaseComponent<MediaType> {
  @Input()
  public resource: Media;

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }
}
