<div class="modal-body modal-style text-secondary px-5 py-4 text-center" data-cy="DIV-3be0">
  <ng-container class="text-center">
    <h3 data-cy="H3-98f6">{{ 'QUIT_POPUP.TITLE' | translate }}</h3>
    <p class="py-4">{{ 'QUIT_POPUP.SUB_TITLE' | translate }}</p>
    <p>
      <button (click)="closeModal()" class="btn btn-success px-5" data-cy="BUTTON-79fc">
        <b>{{ 'COMMON.QUITTER' | translate }}</b></button
      ><br />
      <button (click)="cancelAction()" class="mt-2 btn btn-link px-3 text-decoration-none" data-cy="BUTTON-eb5c">
        {{ 'COMMON.ANNULER' | translate }}
      </button>
    </p>
  </ng-container>
</div>
