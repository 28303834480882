import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge-parameter',
  templateUrl: './badge-parameter.component.html',
  styleUrls: ['./badge-parameter.component.scss'],
})
export class BadgeParameterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
