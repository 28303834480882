import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../../shared/layout/modal/modal.service';
import { ListManagersComponent } from '../../../shared/managers/list-managers/list-managers.component';
import { ManagerService } from '../../../shared/managers/services/managers.service';

@Component({
  selector: 'app-my-managers',
  templateUrl: './my-managers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyManagersComponent extends ListManagersComponent {

  constructor(
    protected readonly translate: TranslateService,
    protected readonly router: Router,
    protected readonly activeRoute: ActivatedRoute,
    protected readonly managerService: ManagerService,
    protected readonly modalService: ModalService,
    configService: DynamicConfigService
  ) {
    super(translate, router, activeRoute, managerService, modalService);
    this.usersApiUrl = `${configService.get<Config>().apiUrl}/settings/managers`;
  }
}
