import { Exclude, Expose, plainToClassFromExist } from 'class-transformer';
import { User } from 'src/app/auth/user';

import { EmailTypeEnum } from './email-type.enum';
import { Sharing } from '../../../sharing/entities/sharing';

@Exclude()
export class EmailTemplate {
  @Expose()
  public isActive!: boolean;
  @Expose()
  public isDeleted!: boolean;
  @Expose()
  public content: string;
  @Expose()
  public id!: number;
  @Expose()
  public language!: string;
  @Expose()
  public subject: string;
  @Expose()
  public title!: string;
  @Expose()
  public type!: EmailTypeEnum;
  @Expose()
  public statusId!: number;
  @Expose()
  public isDefault!: boolean;
  @Expose()
  public createdAt!: number;
  @Expose()
  public mode!: string;
  @Expose()
  public sharings?: Sharing[];
  @Expose()
  public isShared!: boolean;
  @Expose()
  public ownerId!: number;
  @Expose()
  public owner?: User;

  constructor(data: Partial<EmailTemplate> = {}) {
    plainToClassFromExist(this, data);
  }
}
