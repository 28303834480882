import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  CanActivateChild,
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AuthService } from './auth.service';
import { RolesEnum } from './roles.enum';
import { lastValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkRoles(route.data.roles, state.url);
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkRoles(route.data.roles, state.url);
  }

  async canLoad(route: Route, segments: UrlSegment[]) {
    const hasRoles = await this.checkRoles(route.data.roles, segments.map(s => s.path).join('/'));
    return hasRoles === true ? true : false;
  }

  private async checkRoles(roles: RolesEnum[], path: string) {
    const user = await lastValueFrom(this.authService.me());
    const userRoles = user.roles;
    const hasRole = roles.some(role => userRoles.indexOf(role) > -1);

    if (!hasRole) {
      return this.router.createUrlTree(['/unauthorized'], {
        /* Removed unsupported properties by Angular migration: skipLocationChange. */ queryParams: { url: path },
      });
    }

    return hasRole;
  }
}
