import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EmailResolverService } from './shared/email-resolver.service';
import { SharedEmailingModule } from '../../shared/business/emailing/shared-emailing.module';

@NgModule({
  imports: [RouterModule.forChild([]), SharedEmailingModule],
  providers: [EmailResolverService],
})
export class EmailingModule {}
