import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AccountMenuSelectorComponent } from './account-menu-selector.component';
import { SharePopinAccountModule } from '../share-popin-account/share-popin-account.module';

@NgModule({
  imports: [CommonModule, BsDropdownModule, TranslateModule, SharePopinAccountModule],
  providers: [],

  declarations: [AccountMenuSelectorComponent],
  exports: [AccountMenuSelectorComponent],
})
export class AccountMenuSelectorModule {}
