import { CcTableComponent, TableColumn } from '@cleverconnect/ngx-bootstrap-table';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Config } from 'src/app/shared/config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { TranslateService } from '@ngx-translate/core';

import { Status } from '../status';
import { getCcTableLabels } from '../../../shared/table/labels';

@Component({
  selector: 'app-status-list',
  templateUrl: './status-list.component.html',
  styleUrls: ['./status-list.component.scss'],
})
export class StatusListComponent implements OnInit {
  public columns: TableColumn[];
  @ViewChild('ccTable', { static: true }) public tableStatus: CcTableComponent<Status>;
  @ViewChild('dateTemplate', { static: true }) public dateTemplate!: TemplateRef<unknown>;
  @ViewChild('actionsTemplate', { static: true }) public actionsTemplate!: TemplateRef<unknown>;

  private globalSearch = '';

  @Output() public delete = new EventEmitter();
  @Output() public edit = new EventEmitter();
  @Output() public order = new EventEmitter();

  public sortBy: string = 'DESC';
  public paramToOrder: string = '';
  public statusUrl = '';
  public statusCount?: number;

  public tableLabels = getCcTableLabels(this.translate);

  @Input()
  set searchBy(value: string) {
    this.globalSearch = value;
  }

  public get filter() {
    return {
      value: this.globalSearch,
    };
  }

  constructor(readonly translate: TranslateService, readonly configService: DynamicConfigService) {
    this.statusUrl = `${this.configService.get<Config>().apiUrl}/statuses`;
  }

  ngOnInit() {
    this.columns = [
      { field: 'value', classesHeaderTh: 'w-100', title: this.translate.instant('STATUS.INTITULE') },
      {
        field: 'createdAt',
        classesHeaderTh: 'd-none d-md-table-cell',
        classesTd: 'd-none d-md-table-cell',
        title: this.translate.instant('STATUS.DATE_CREATION'),
        template: this.dateTemplate,
      },
      { title: '', classesTd: 'p-0', template: this.actionsTemplate },
    ];
  }

  public onDelete(status: Status): void {
    this.delete.emit(status);
  }

  public onEdit(status: Status): void {
    this.edit.emit(status);
  }

  public generateParams(params: { [key: string]: unknown }) {
    const newParams = { ...params };
    Object.keys(newParams)
      .filter(k => !newParams[k])
      .forEach(k => delete newParams[k]);

    return newParams;
  }
}
