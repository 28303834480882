import { Injectable } from '@angular/core';
import { ModalService } from '../../../../shared/layout/modal/modal.service';
import { ResourceMediaUploadModalComponent } from './resource-media-upload-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ResourceMediaUploadModalService {
  constructor(private modalService: ModalService) {}

  public openResourceModal() {
    const initialState = {};

    const bsModalRef = this.modalService.show(ResourceMediaUploadModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-lg modal-secondary modal-dialog-centered',
    });
    const modalComponent = bsModalRef.content as ResourceMediaUploadModalComponent;
    return modalComponent.onClose;
  }
}
