import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {SliderOption} from '../popover-slider/slider-option.model';

@Component({
  selector: 'app-chronometer-slider',
  templateUrl: './chronometer-slider.component.html',
  styleUrls: ['./chronometer-slider.component.scss'],
})
export class ChronometerSliderComponent {
  @Input() public control: UntypedFormControl;
  @Input() public isUnlimited: boolean;
  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;
  @Input() public textWidth: string;
  @Input() public selectedBarOptions: Array<SliderOption> = [];
}
