import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SubmittableFormGroup } from 'src/app/shared/form-controls/submittable-form-group';
import { UntypedFormControl, Validators } from '@angular/forms';
import { VideoRecorderComponent, VideoRecorderService } from '@cleverconnect/ngx-video-recorder';
import { ModalBaseComponent } from '../../modal/modal-base-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { timer, Subscription } from 'rxjs';

@Component({
  selector: 'app-video-record-modal',
  templateUrl: './video-record-modal.component.html',
  styleUrls: ['./video-record-modal.component.scss'],
})
export class VideoRecordModalComponent extends ModalBaseComponent<File> implements OnInit, OnDestroy {
  public videoRecordForm: SubmittableFormGroup;
  public step: 'start' | 'recording' | 'recorded' = 'start';
  public isRecorderReady = false;
  public recordingTime: number = 3;
  public readonly videoExtension: string = '.webm';

  @ViewChild('videoRecorder', { static: true })
  public videoRecorder: ElementRef<VideoRecorderComponent>;

  public recorder: VideoRecorderComponent;
  private timer: Subscription;
  public elapsedTime: number;

  constructor(private videoRecorderService: VideoRecorderService, modalRef: BsModalRef, private el: ElementRef) {
    super(modalRef);
    this.videoRecordForm = new SubmittableFormGroup({ title: new UntypedFormControl('', Validators.required) });
  }

  ngOnInit() {
    this.recorder = this.videoRecorderService.init('recorder', this.videoRecorder);
    this.recorder.startDevice();

    this.recorder.recordReady.subscribe(() => {
      this.isRecorderReady = true;
    });

    this.recorder.videoRecordedReady.subscribe(() => {
      this.step = 'recorded';
      this.startRecorded();
    });
  }

  public startRecord() {
    this.step = 'recording';
    this.recorder.startRecord();
    this.timer = timer(0, 1000).subscribe(v => {
      this.elapsedTime = v;
      if (this.elapsedTime > this.recordingTime * 60) {
        this.stopRecord();
      }
    });
  }

  public stopRecord() {
    this.recorder.stopRecord();
    this.timer.unsubscribe();
  }

  public reset() {
    this.step = 'start';
    this.elapsedTime = 0;
    this.isRecorderReady = false;
    this.recorder.reset();
    this.recorder.startDevice();
  }

  public startRecorded() {
    this.recorder.startRecorded();
  }

  public submit() {
    this.videoRecordForm.markAsSubmitted(this.el);
    if (this.videoRecordForm.invalid) {
      return;
    }

    const blob = this.recorder.getRecordedData() as any;
    blob.name = this.videoRecordForm.get('title').value + this.videoExtension;
    blob.lastModifiedDate = new Date();

    this.close(blob as File);
  }

  public ngOnDestroy() {
    this.recorder.stopDevice();
    super.ngOnDestroy();
  }
}
