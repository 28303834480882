import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Editor } from 'ngx-editor';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';

import { EmailingResetModalComponent } from '../emailing-reset-modal/emailing-reset-modal.component';
import { ModalService } from '../../../layout/modal/modal.service';

@Component({
  selector: 'app-editor-html',
  templateUrl: './editor-html.component.html',
  styleUrls: ['./editor-html.component.scss'],
})
export class EditorHtmlComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public tags: { label: string; value: string }[] = [];
  @Input() public content!: string;
  @Input() public form!: UntypedFormGroup;
  @Input() public colorText: string = 'deepskyblue';

  @Output() public saveEvent = new EventEmitter<void>();
  @Output() public resetEvent = new EventEmitter<void>();

  public placeholder = this.translate.instant('EMAILING.EDITOR.TYPE_HERE');
  public editor!: Editor;

  constructor(
    private modalService: ModalService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
  }

  public changeColorBlack(): void {
    this.editor.commands.textColor('black').exec();
  }

  ngAfterViewInit(): void {
    this.changeColorBlack();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    if (!this.resetEvent.closed) {
      this.resetEvent.complete();
    }
    if (!this.saveEvent.closed) {
      this.saveEvent.complete();
    }
  }

  public reset(): void {
    const modalRef = this.modalService.show(EmailingResetModalComponent, {
      class: 'modal-secondary modal-dialog-centered',
    });

    modalRef.content.resetEvent.subscribe(() => {
      this.resetEvent.emit();
      modalRef.content.close();
    });
  }

  public addTag(tag: any): void {
    this.editor.commands
      .textColor(this.colorText)
      .insertText(`[${tag.value}]`)
      .textColor('black')
      .insertText('')
      .focus()
      .scrollIntoView()
      .exec();
  }
}
