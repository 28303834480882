<div class="modal-body modal-style text-secondary px-5 py-4 add-user-to-team-modal" data-cy="DIV-b533">
  <button type="button" (click)="cancel()" class="close pull-right" aria-label="Close" data-cy="BUTTON-2b8b">
    <span aria-hidden="true" data-cy="SPAN-be4a">&times;</span>
  </button>
  <div data-cy="DIV-55f4">
    <h4 class="text-body py-4 text-center" [translate]="'PANEL.USERS.ACTIVER.TITLE'" data-cy="H4-dfd9"></h4>
    <div class="text-center pb-3" translate [translateParams]="{ count: selectedUsers.length }" data-cy="DIV-6580">{{ subtitle }}</div>
  </div>
  <ng-select
    name="selectedUsers"
    [searchFn]="customSearchUser"
    multiple="true"
    class="ng-select-white"
    [hideSelected]="true"
    [(ngModel)]="selectedUsers"
    [items]="users"
    [searchable]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    [placeholder]="'PANEL.USERS.ACTIVER.CHERCHER_UTILISATEURS' | translate"
    placeholder="Rechercher des utilisateurs"
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <cc-user-thumbnail
        [size]="22"
        class="me-2"
        [thumbnail]="{ url: item.avatar, label: item.firstname + ' ' + item.lastname }"
      ></cc-user-thumbnail>
      <span data-cy="SPAN-81a6" *ngIf="item.firstname && item.lastname">{{ item.firstname }} {{ item.lastname }}</span>
      <span data-cy="SPAN-81a6" *ngIf="!(item.firstname && item.lastname)">{{ item.email }}</span>
      <span class="ng-value-icon" (click)="clear.call(undefined, item)" aria-hidden="true" data-cy="SPAN-4b02">×</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <cc-user-thumbnail
        [size]="22"
        class="me-2"
        [thumbnail]="{ url: item.avatar, label: item.firstname + ' ' + item.lastname }"
      ></cc-user-thumbnail>
      <span data-cy="SPAN-81a6" *ngIf="item.firstname && item.lastname">{{ item.firstname }} {{ item.lastname }}</span>
      <span data-cy="SPAN-81a6" *ngIf="!(item.firstname && item.lastname)">{{ item.email }}</span>
    </ng-template>
  </ng-select>
  <div class="buttons-container text-center mt-5" data-cy="DIV-69b6">
    <button
      (click)="activateUsers()"
      class="btn btn-panel btn-sm fw-bold"
      [disabled]="subscription?.closed === false"
      [translate]="'PANEL.USERS.ACTIVER.SUBMIT'"
      data-cy="BUTTON-d559"
    ></button>
    <br />
    <button
      (click)="close()"
      class="btn btn-link btn-sm text-decoration-none pt-3"
      [translate]="'COMMON.ANNULER'"
      data-cy="BUTTON-151e"
    ></button>
  </div>
</div>
