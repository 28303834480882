import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {CustomStepDefinition, Options } from '@angular-slider/ngx-slider';
import {SliderOption} from '../popover-slider/slider-option.model';

@Component({
  selector: 'app-unlimited-slider',
  templateUrl: './unlimited-slider.component.html',
  styleUrls: ['./unlimited-slider.component.scss'],
})
export class UnlimitedSliderComponent {
  @Input() public control: UntypedFormControl;
  @Input() public selectedBarOptions: Array<SliderOption> = [];

  private _isUnlimited: boolean = true;
  public get isUnlimited(): boolean {
    return this._isUnlimited;
  }
  @Input()
  public set isUnlimited(v: boolean) {
    this._isUnlimited = v === undefined ? true : v;
    this.updateOptions();
  }

  private _min: number;
  public get min(): number {
    return this._min;
  }
  @Input()
  public set min(v: number) {
    this._min = v;
    this.updateOptions();
  }

  private _max: number;
  public get max(): number {
    return this._max;
  }
  @Input()
  public set max(v: number) {
    this._max = v;
    this.updateOptions();
  }

  private _step: number;
  public get step(): number {
    return this._step;
  }
  @Input()
  public set step(v: number) {
    this._step = v;
    this.updateOptions();
  }

  public options: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    showSelectionBar: true,
    stepsArray: [],
  };

  public ngOnInit(): void {
      this.options.getSelectionBarColor = (value: number): string  => {
        return this.selectedBarOptions.find((opt : any) =>
          (value >= opt.min) && ((!opt.max || value <= opt.max))
        )?.color || '';
      }
  }

  public get steps(): CustomStepDefinition[] {
    return this.options.stepsArray;
  }

  private updateOptions(): void {
    this.steps.splice(0, this.steps.length);

    for (let step = this.min; step <= this.max; step += this.step) {
      this.steps.push({ value: step });
    }
    if (this.isUnlimited) {
      this.steps.push({ value: 0 });
    }
  }
}
