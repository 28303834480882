import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterLanguage', pure: false })
export class FilterLanguagePipe implements PipeTransform {
  transform(elementsToFilter: any, term: any) {
    if (!term) {
      return elementsToFilter;
    }

    return elementsToFilter.filter(item => item.language.toLowerCase().indexOf(term.toLowerCase()) !== -1);
  }
}
