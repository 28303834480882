import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceSelectorComponent } from './resource-selector/resource-selector.component';
import { ResourceViewerComponent } from './resource-viewer/resource-viewer.component';
import { VideoRecordModalComponent } from './video-record-modal/video-record-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ngfModule } from 'angular-file';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CheckableCardComponent } from '../checkable-card/checkable-card.component';
import { VideoPlayerModule } from '@cleverconnect/ngx-video-player';
import { RouterModule } from '@angular/router';
import { ImageLoaderDirective } from './image-loader/image-loader.directive';
import { ChronometerModule } from '../chronometer/chronometer.module';
import { ResourceViewerModalComponent } from './resource-viewer/modal-viewer-modal/resource-viewer-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ResourceSelectorComponent,
        ResourceViewerComponent,
        ResourceViewerModalComponent,
        VideoRecordModalComponent,
        CheckableCardComponent,
        ImageLoaderDirective,
    ],
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        ngfModule,
        ProgressbarModule.forRoot(),
        CommonModule,
        VideoPlayerModule,
        TooltipModule,
        RouterModule,
        ChronometerModule,
    ],
    exports: [ResourceSelectorComponent, ResourceViewerComponent, VideoRecordModalComponent, CheckableCardComponent, ImageLoaderDirective]
})
export class ResourceModule {}
