<div class="col" data-cy="DIV-d166">
  <app-unlimited-slider
    [min]="min"
    [max]="max"
    [step]="step"
    [isUnlimited]="isUnlimited"
    [control]="control"
    [selectedBarOptions]="selectedBarOptions"
  ></app-unlimited-slider>
</div>
<div class="col-auto text-time" [style.width]="textWidth" data-cy="DIV-dc8b">
  <strong
    translate
    [translateParams]="{count: control.value, INTERPOLATION: control.value|chronometer}"
  >QUESTIONNAIRE.CHRONOMETER.VALUE</strong>
</div>
