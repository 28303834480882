import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterTitle', pure: false })
export class FilterTitlePipe implements PipeTransform {
  transform(elementsToFilter: any, term: any) {
    if (!term) {
      return elementsToFilter;
    }

    return elementsToFilter.filter(item => item.title.toLowerCase().indexOf(term.toLowerCase()) !== -1);
  }
}
