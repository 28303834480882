import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from './resource';
import { Config } from '../../config/config';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Credential } from '../../upload/credential/credential';
import { TranslateService } from '@ngx-translate/core';
import { ResourceTypeEnum } from './resource-type.enum';
import { Media } from './media';
import { Sharing } from '../../sharing/entities/sharing';
import { SharingObjectType } from '../../sharing/entities/sharing-object-type.enum';
import { SharingRecipientType } from '../../sharing/entities/sharing-recipient-type.enum';
import { concatMap, delay, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { UploadFileService } from '../../upload/upload-file.service';
import { throwError, iif, of, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { ResourceStatusEnum } from './resource-status.enum';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private config: Config;

  private resourceTypes: ResourceType[] = [];
  private checkSubscriptions: { [key: string]: Subscription } = {};

  private _resourceUpdated: Subject<Resource> = new Subject<Resource>();
  public get resourceUpdated(): Subject<Resource> {
    return this._resourceUpdated;
  }

  private readonly timeouts = {
    'video-welcome': 5000,
    'video-thanks': 5000,
    'video-question': 5000,
    image: 2500,
  };

  constructor(
    private http: HttpClient,
    configService: DynamicConfigService,
    private translate: TranslateService,
    private uploadFileService: UploadFileService
  ) {
    this.config = configService.get<Config>();
  }

  public getAll(
    filter?: Partial<{ type: ResourceTypeEnum; sharingRecipientType: SharingRecipientType; SharingRecipientId: string; limit?: number }>,
  ) {
    const params = {};
    if (filter.sharingRecipientType && filter.SharingRecipientId) {
      params[`${filter.sharingRecipientType}Id`] = filter.SharingRecipientId;
    }
    if (filter.type) {
      params['s'] = JSON.stringify({ type: { $eq: filter.type } });
    }
    if (filter.limit) {
      params['limit'] = filter.limit;
    }
    return this.http
      .get<{ data: Media[] }>(`${this.config.apiUrl}/resources`, {
        params,
      })
      .pipe(tap(rs => rs.data.forEach(r => this.checkResourceConverted(r))));
  }

  public getAllTypes(): ResourceType[] {
    this.resourceTypes = Object.keys(ResourceTypeEnum)
      .map(k => ResourceTypeEnum[k])
      .map((k: string) => {
        let translationKey = `MEDIA_TYPE.${k}`;
        translationKey = translationKey.toUpperCase().replace('-', '_');
        return { value: k, label: this.translate.instant(translationKey) };
      });

    return this.resourceTypes;
  }

  public getTypesFor(resource: Resource): ResourceType[] {
    return this.getAllTypes().filter(item =>
      resource.type.startsWith('video-') ? item.value.startsWith('video-') : resource.type === item.value
    );
  }

  public get(id: string) {
    return this.http.get<Media>(`${this.config.apiUrl}/resources/${id}`).pipe(tap(r => this.checkResourceConverted(r)));
  }

  public post(blob: Blob, name: string, type: string, options: { [key: string]: string }) {
    return this.uploadFileService.getBlobMd5Checksum(blob).pipe(
      mergeMap((md5: string) => {
        const formData = new FormData();
        formData.append('fileName', name);
        formData.append('fileSize', blob.size.toString());
        formData.append('fileType', blob.type);
        formData.append('md5', encodeURIComponent(md5));
        formData.append('type', type);
        formData.append('file', blob.slice(0, 4100));

        return this.http.post<{ id: string; credentials: Credential }>(`${this.config.apiUrl}/resources`, formData);
      })
    );
  }

  public delete(id: string) {
    return this.http.delete<any>(`${this.config.apiUrl}/resources/${id}`);
  }

  public share(share: Partial<Sharing>) {
    share.objectType = SharingObjectType.resource;
    return this.http.post<Sharing>(`${this.config.apiUrl}/resources/${share.objectId}/share`, { share });
  }

  private checkResourceConverted(resource: Resource) {
    if (resource.status == ResourceStatusEnum.converted || this.checkSubscriptions[resource.id]) {
      return;
    }

    this.checkSubscriptions[resource.id] = this.get(resource.id)
      .pipe(
        map(r => {
          if (r.status != ResourceStatusEnum.converted) {
            throw 202;
          }
          return r;
        }),
        retryWhen(r =>
          r.pipe(
            concatMap((e, i) =>
              e !== 202
                ? throwError(() => e)
                : iif(
                  () => i >= 10,
                  throwError(() => new Error('Resource not converted')),
                  of(e).pipe(delay((this.timeouts[resource.type] || this.timeouts.image) * (i + 1)))
                )
            )
          )
        )
      )
      .subscribe(r => {
        resource.duration = r.duration;
        resource.status = r.status;
        this.checkSubscriptions[resource.id].unsubscribe();
        this._resourceUpdated.next(resource);
        delete this.checkSubscriptions[resource.id];
      });
  }
}

export class ResourceType {
  public value: string;
  public label: string;
}
