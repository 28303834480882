import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

export function ConditionalValidator(predicate: (parent: UntypedFormGroup | UntypedFormArray) => boolean, validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    revalidateOnChanges(control);

    if (control?.parent && predicate(control.parent)) {
      return validator(control);
    }
    return null;
  };
}
function revalidateOnChanges(control: AbstractControl): void {
  if (!control?.parent || control['_revalidateOnChanges']) {
    return;
  }
  control['_revalidateOnChanges'] = true;
  control.parent.valueChanges
    .pipe(
      distinctUntilChanged((a, b) => {
        if (!a && !b) {
          return true;
        }
        if ((a && !b) || (!a && b) || Object.keys(a).length !== Object.keys(b).length) {
          return false;
        }

        return Object.keys(a).every(key => a[key] === b[key]);
      })
    )
    .subscribe(() => {
      control.updateValueAndValidity();
    });

  control.updateValueAndValidity();
}
