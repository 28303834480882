import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RouterModule } from '@angular/router';
import { ScrollSpyModule } from 'ngx-scrollspy';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserThumbnailModule } from '@cleverconnect/ngx-user-thumbnail';
import { VideoPlayerModule } from '@cleverconnect/ngx-video-player';
import { VideoRecorderModule } from '@cleverconnect/ngx-video-recorder';
import { ngfModule } from 'angular-file';

import { AccountMenuSelectorModule } from '../../share/account-menu-selector/account-menu-selector.module';
import { AutosizeDirective } from './autosize/autosize.directive';
import { BadgeParameterComponent } from './badge-parameter/badge-parameter.component';
import { BusinessModule } from '../business/business.module';
import { ChabotComponent } from '../chatbot/chabot.component';
import { ChartDirective } from './chart/chart.directive';
import { ChronometerModule } from './chronometer/chronometer.module';
import { ChronometerSliderComponent } from './chronometer-slider/chronometer-slider.component';
import { CommonFooterComponent } from './common-footer/common-footer.component';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { CommonSideNavigationComponent } from './common-side-navigation/common-side-navigation.component';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { ContentTagListComponent } from './content-tag-list/content-tag-list.component';
import { CountArrayElementsPipe } from './pipes/countarrayelements.pipe';
import { EmojiInputDirective } from './emoji-popover/emoji-input.directive';
import { EmojiPopoverComponent } from './emoji-popover/emoji-popover.component';
import { EnumToArrayPipe } from '../utils/enum-to-array.pipe';
import { FilterLanguagePipe } from './pipes/filterlanguage.pipe';
import { FilterTitlePipe } from './pipes/filtertitle.pipe';
import { FlexFillContainerComponent } from './flex-fill-container/flex-fill-container.component';
import { FoldableAccordionComponent } from './foldable-accordion/foldable-accordion.component';
import { FormModalComponent } from './modal/form-modal/form-modal.component';
import { KeywordHighlightDirective } from './keyword-highlight/keyword-highlight.directive';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ListSelectableComponent } from './list-selectable/list-selectable.component';
import { ModalModule } from './modal/modal.module';
import { NavigationComponent } from '../../navigation/navigation.component';
import { PanelComponent } from './panel/panel.component';
import { PanelRowComponent } from './panel/panel-row/panel-row.component';
import { PopoverComponent } from './popover/popover.component';
import { PopoverSliderComponent } from './popover-slider/popover-slider.component';
import { QuitComponent } from './quit/quit.component';
import { ResourceMediaEditModalComponent } from './resource/resource-media-edit-modal/resource-media-edit-modal.component';
import { ResourceMediaUploadModalComponent } from './resource/resource-media-upload-modal/resource-media-upload-modal.component';
import { ResourceModule } from './resource/resource.module';
import { SelectCompanyEntityComponent } from './select-company-entity/select-company-entity.component';
import { SelectUsersTeamsComponent } from './select-users-teams/select-users-teams.component';
import { TagsDisplayerPipe } from '../utils/tagsdisplayer.pipe';
import { TilesComponent } from './tiles/tiles.component';
import { TooltipConfigFactory } from './ngx-bootstrap/tooltip-config.factory';
import { UnlimitedSliderComponent } from './unlimited-slider/unlimited-slider.component';
import { daterangepickerConfigFactory } from './ngx-bootstrap/daterangepicker-config.factory';
import { paginationConfigFactory } from './ngx-bootstrap/pagination-config.factory';

const components = [
  ChabotComponent,
  NavigationComponent,
  CommonHeaderComponent,
  CommonFooterComponent,
  CommonSideNavigationComponent,
  BadgeParameterComponent,
  PopoverComponent,
  PopoverSliderComponent,
  ChronometerSliderComponent,
  UnlimitedSliderComponent,
  ListSelectableComponent,
  FlexFillContainerComponent,
  PanelComponent,
  FoldableAccordionComponent,
  PanelRowComponent,
  EmojiPopoverComponent,
  QuitComponent,
  ContentTagListComponent,
  ResourceMediaUploadModalComponent,
  ResourceMediaEditModalComponent,
  TilesComponent,
  LanguageSelectorComponent,
  FormModalComponent,
  ConfirmModalComponent,
  SelectUsersTeamsComponent,
  SelectCompanyEntityComponent,
];

const declarations = [
  ...components,
  KeywordHighlightDirective,
  AutosizeDirective,
  EmojiInputDirective,
  FilterTitlePipe,
  EnumToArrayPipe,
  TagsDisplayerPipe,
  CountArrayElementsPipe,
  FilterLanguagePipe,
  ChartDirective,
];

@NgModule({
  imports: [
    TranslateModule,
    VideoPlayerModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    BusinessModule,
    NgxSliderModule,
    VideoRecorderModule,
    ngfModule,
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule,
    TooltipModule.forRoot(),
    BsDatepickerModule,
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    ScrollSpyModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    UserThumbnailModule,
    ResourceModule,
    ChronometerModule,
    NgSelectModule,
    NgOptionHighlightModule,
    AccountMenuSelectorModule,
  ],
  declarations: declarations,
  exports: [
    ...declarations,
    ScrollSpyModule,
    ngfModule,
    NgxSliderModule,
    ResourceModule,
    ChronometerModule,
    UserThumbnailModule,
    PaginationModule,
  ],
  providers: [
    FilterTitlePipe,
    FilterLanguagePipe,
    { provide: PaginationConfig, useFactory: paginationConfigFactory, deps: [TranslateService] },
    { provide: BsDaterangepickerConfig, useFactory: daterangepickerConfigFactory, deps: [TranslateService] },
    { provide: TooltipConfig, useFactory: TooltipConfigFactory },
  ],
})
export class LayoutModule {}
