import { ActivatedRoute, Router } from '@angular/router';
import { CcTableComponent, TableAction, TableColumn } from '@cleverconnect/ngx-bootstrap-table';
import { Directive, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCcTableLabels } from 'src/app/shared/table/labels';

import { ActivateUserComponent } from '../../business/user/activate-user/activate-user.component';
import { Company } from '../../../panel/companies/company';
import { DeactivateUserComponent } from '../../business/user/deactivate-user/deactivate-user.component';
import { ManagerService } from '../services/managers.service';
import { ModalService } from '../../layout/modal/modal.service';
import { RolesEnum } from '../../../auth/roles.enum';
import { User } from '../../../auth/user';

@Directive()
export class ListManagersComponent implements OnInit {
  @ViewChild('actionsTemplate', { static: true }) public actionsTemplate: TemplateRef<any>;
  @ViewChild('stateTemplate', { static: true }) public stateTemplate: TemplateRef<any>;
  @ViewChild('ccTable', { static: true }) public tableUsers: CcTableComponent<User>;
  public columns: TableColumn[];
  public actions: TableAction<User>[];
  public globalSearch: string;
  public usersApiUrl: string;
  public selectedUsers: User[] = [];
  public nbUsers: number;
  public tableLabels = getCcTableLabels(this.translate);
  public stateUserFilter: boolean;
  protected company: Company;

  public statesUser = [
    { value: null, label: this.translate.instant('SETTINGS.MANAGERS.STATE_SEARCH.ALL') },
    { value: true, label: this.translate.instant('SETTINGS.MANAGERS.STATE_SEARCH.DESACTIVE') },
    { value: 'false', label: this.translate.instant('SETTINGS.MANAGERS.STATE_SEARCH.ACTIVE') },
  ];

  public me: User = this.activeRoute.snapshot.data.me;

  public get filter(): { s: string } {
    const searchFilters: any = { $and: [] };
    if (this.globalSearch) {
      searchFilters.$and = this.globalSearch.split(' ').map(s => ({
        $or: [{ id: { $eq: s } }, { email: { $contL: s } }, { firstname: { $contL: s } }, { lastname: { $contL: s } }],
      }));
    }
    if (this.stateUserFilter) {
      searchFilters.$and.push({ deleted: this.stateUserFilter });
    }
    return {
      s: JSON.stringify(searchFilters),
    };
  }
  constructor(
    protected readonly translate: TranslateService,
    protected readonly router: Router,
    protected readonly activeRoute: ActivatedRoute,
    protected readonly managerService: ManagerService,
    protected readonly modalService: ModalService
  ) {}

  ngOnInit() {
    this.actions = [
      {
        title: this.translate.instant('SETTINGS.MANAGERS.ACTIONS.DESACTIVER'),
        isVisible: users => this.me.isCsm && users.some(user => user.accountType === RolesEnum.manager && !user.deleted),
        action: 'deactivate',
        icon: "<i class='material-icons md-24' >pause_circle_filled</i>",
      },
      {
        title: this.translate.instant('SETTINGS.MANAGERS.ACTIONS.ACTIVER'),
        isVisible: users => this.me.isCsm && users.some(user => user.accountType === RolesEnum.manager && user.deleted),
        action: 'activate',
        icon: "<i class='material-icons md-24'>play_circle_filled</i>",
      },
    ];

    this.columns = [
      { title: this.translate.instant('PANEL.USERS.LIST.ETAT'), field: 'deleted', template: this.stateTemplate },
      { title: this.translate.instant('PANEL.USERS.LIST.EMAIL'), field: 'email', classesTd: ' text-break' },
      {
        title: this.translate.instant('PANEL.USERS.LIST.NOM'),
        field: 'lastname',
        formatter: (d, l) => `${l.lastname || ''} ${l.firstname || ''}`,
      },
      { title: '', classesTd: 'p-0', template: this.actionsTemplate },
    ];
  }

  public navigateToCandidats(user: User): void {
    this.router.navigate([user.id], { relativeTo: this.activeRoute });
  }

  public onUserAction(action: string, users: User[]): void {
    switch (action) {
      case 'show_candidates':
        this.navigateToCandidats(users[0]);
        break;
      case 'deactivate':
        this.openDeactivateUserModal(users);
        break;
      case 'activate':
        this.openActivateUserModal(users);
        break;
    }
  }

  public openDeactivateUserModal(users: User[]): void {
    (this.company ? this.managerService.getAllCompagnieManagers(this.company.id) : this.managerService.getAllMyManagers()).subscribe({
      next: (result: { data: User[]; total: number }) => {
        const modalComponent = this.modalService.show(DeactivateUserComponent, {
          backdrop: 'static',
          class: 'modal-lg modal-secondary modal-dialog-centered',
          initialState: {
            users: result.data.filter(user => user.accountType === RolesEnum.manager),
            selectedUsers: users.filter(user => user.accountType === RolesEnum.manager),
            companyId: this.me.company.id,
            subtitle: 'SETTINGS.MANAGERS.DESACTIVER.SUBTITLE',
            successLabel: 'SETTINGS.MANAGERS.DESACTIVER.SUCCESS',
          },
        }).content as DeactivateUserComponent;

        modalComponent.onClose.subscribe(() => {
          this.refreshTable();
        });
      },
    });
  }

  public openActivateUserModal(users: User[]): void {
    (this.company ? this.managerService.getAllCompagnieManagers(this.company.id) : this.managerService.getAllMyManagers()).subscribe({
      next: (result: { data: User[]; total: number }) => {
        const modalComponent = this.modalService.show(ActivateUserComponent, {
          backdrop: 'static',
          class: 'modal-lg modal-secondary modal-dialog-centered',
          initialState: {
            users: result.data.filter(user => user.accountType === RolesEnum.manager),
            selectedUsers: users.filter(user => user.accountType === RolesEnum.manager),
            companyId: this.me.company.id,
            subtitle: 'SETTINGS.MANAGERS.ACTIVER.SUBTITLE',
            successLabel: 'SETTINGS.MANAGERS.ACTIVER.SUCCESS',
          },
        }).content as ActivateUserComponent;

        modalComponent.onClose.subscribe(() => {
          this.refreshTable();
        });
      },
    });
  }
  private refreshTable(): void {
    this.tableUsers.uncheckAll();
    this.tableUsers.reload();
  }
}
