import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ItemsAction } from './items-action';
import { User } from '../../auth/user';

@Component({
  selector: 'app-account-menu-selector',
  templateUrl: './account-menu-selector.component.html',
  styleUrls: ['./account-menu-selector.component.scss'],
})
export class AccountMenuSelectorComponent {
  @Input() menuClasses = '';
  @Input() me: User = {} as User;
  @Input() items: ItemsAction[] = [];
  @Input() isWhite: boolean = false;

  @Output() action = new EventEmitter();
}
