import { Injectable, Inject } from '@angular/core';
import { CanActivate, CanLoad, CanActivateChild, ActivatedRouteSnapshot, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { tap, catchError, shareReplay } from 'rxjs/operators';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from '../shared/config/config';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  private loginRequest: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private configService: DynamicConfigService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.queryParamMap.get('token');
    return this.login(token);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.queryParamMap.get('token');
    return this.login(token);
  }

  canLoad(): Observable<boolean> {
    return this.login('');
  }

  private login(token: string) {
    if (token) {
      this.authService.setToken(token);
    }

    if (this.loginRequest) {
      return this.loginRequest;
    }

    this.loginRequest = this.authService.isAuthenticated().pipe(
      shareReplay(1),
      tap(isLogged => {
        if (!isLogged) {
          if (this.authService.hasToken()) {
            return this.router.navigate(['/unauthorized']);
          }
          return this.goToLoginUrl();
        }
      }),
      catchError(e => {
        console.log(e);
        return of(false);
      })
    );

    return this.loginRequest;
  }

  private goToLoginUrl(): void {
    const config = this.configService.get<Config>();
    this.document.location.href = `${config.authUrl}?returnUrl=${encodeURI(this.document.location.href)}`;
  }
}
