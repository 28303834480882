import { TranslateService } from '@ngx-translate/core';

export const ACCOUNT_TYPES = 'ACCOUNT_TYPES';

export class AccountType {
  value: string;
  label: string;
}

export const factory = (translate: TranslateService) => [
  { value: 'subscription', label: translate.instant('ACCOUNT_TYPE.RECRUTEUR') },
  { value: 'collaborator', label: translate.instant('ACCOUNT_TYPE.COLLABORATEUR') },
  { value: 'evaluator', label: translate.instant('ACCOUNT_TYPE.EVALUATEUR') },
];

export const AccountTypesFactory = {
  provide: ACCOUNT_TYPES,
  deps: [TranslateService],
  useFactory: factory,
};
