import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { EmailTemplate } from '../entities/email-template';
import { EmailTemplateRepository } from '../email-template.repository';
import { ModalBaseComponent } from '../../../layout/modal/modal-base-component';

@Component({
  selector: 'app-emailing-disable-modal',
  templateUrl: './emailing-disable-modal.component.html',
  styleUrls: ['./emailing-disable-modal.component.scss'],
})
export class EmailingDisableModalComponent extends ModalBaseComponent<object> implements OnDestroy {
  @Input()
  public emailId: number;

  @Output()
  public emailDisabledEvent = new EventEmitter<EmailTemplate>();

  constructor(bsModalRef: BsModalRef, private emailTemplateRepository: EmailTemplateRepository) {
    super(bsModalRef);
  }

  public disableConfirmClick() {
    this.emailTemplateRepository.patch(this.emailId, { isActive: false }).subscribe(response => {
      this.emailDisabledEvent.emit(response);
    });
  }

  public ngOnDestroy(): void {
    if (!this.emailDisabledEvent.closed) {
      this.emailDisabledEvent.complete();
    }
    super.ngOnDestroy();
  }
}
