import {ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SliderOption} from './slider-option.model';

@Component({
  selector: 'app-popover-slider',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './popover-slider.component.html',
  styleUrls: ['./popover-slider.component.scss'],
})
export class PopoverSliderComponent {
  @Input() content!: any;
  @Input() public title!: string;
  @Input() public description!: string;
  @Input() public control!: UntypedFormControl;
  @Input() public isUnlimited!: boolean;
  @Input() public min!: number;
  @Input() public max!: number;
  @Input() public step!: number;

  public translationKey: string | null;
  @Input() public selectedBarOptions: Array<SliderOption> = [];

  public get tranlateKey(): string {
    return this.selectedBarOptions.find(
      (opt: SliderOption) => (this.control.value >= opt.min && (!opt.max || this.control.value <= opt.max))
    )?.translationKey || '';
  }
}

