export enum RolesEnum {
  admin = 'super_admin',
  csm = 'csm',
  user = 'user',
  evaluator = 'evaluator',
  collaborator = 'collaborator',
  ats = 'ats',
  guest = 'guest',
  manager = 'manager',
}
