import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-row',
  templateUrl: './panel-row.component.html',
  styleUrls: ['./panel-row.component.scss'],
})
export class PanelRowComponent {
  @Input()
  public label: string;

  @Input()
  public link: string;

  @Input()
  public linkParams: { [k: string]: any } = {};

  private _readonly: boolean;
  public get readonly(): boolean {
    return this._readonly || !this.link;
  }
  @Input()
  public set readonly(value: boolean) {
    this._readonly = value;
  }
}
