<div class="d-flex flex-column h-100 w-100" data-cy="DIV-c95c">
  <app-common-header title="Mes email types" [title]="'EMAILING.MES_EMAILS_TYPES' | translate"></app-common-header>
  <app-flex-fill-container scrollSpyElement="flex-fill-container">
    <app-emailing-search-form (filter)="search = $event" (createEmailEmitter)="action('create')"></app-emailing-search-form>

    <div data-cy="DIV-1f50">
      <h4 class="pt-1 pb-1" data-cy="H4-36a2">
        {{ 'EMAILING.EMAILS_TYPES' | translate }}&nbsp;
        <span class="badge bg-primary rounded-pill py-1" data-cy="SPAN-5899">{{
          mailTemplateCount != undefined ? mailTemplateCount : '-'
        }}</span>
      </h4>
    </div>
    <cc-table
      class="last-col-fix"
      [url]="statusUrl"
      (dataUpdated)="mailTemplateCount = $event.total"
      tableClasses="table-hover align-middle text-nowrap"
      [columns]="columns"
      [debounceTime]="500"
      [search]="search"
      [requestParamsFormatter]="generateParams"
      (clickLine)="action('preview', $event)"
      [labels]="tableLabels"
      #ccTable
    ></cc-table>
  </app-flex-fill-container>
</div>
<ng-template #actionsTemplate let-line="line" let-data="data">
  <div class="position-relative text-center" dropdown container="body" placement="bottom right" data-cy="DIV-86e0">
    <a
      dropdownToggle
      href="javascript:void(0)"
      (click)="$event.stopPropagation()"
      class="btn btn-link link-secondary text-decoration-none p-1"
      data-cy="A-ec7f"
    >
      <i class="material-icons md-24" data-cy="I-ec7f">more_vert</i>
    </a>
    <div class="bg-white" data-cy="DIV-0de7">
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
        <li *ngIf="line.ownerId == me.id">
          <a
            class="dropdown-item text-secondary text-decoration-none"
            [translate]="'EMAILING.MODIFIER'"
            href="javascript:void(0)"
            (click)="action('edit', line)"
            data-cy="A-950a"
          ></a>
        </li>
        <li>
          <a
            class="dropdown-item text-secondary text-decoration-none"
            [translate]="'EMAILING.DUPLIQUER'"
            (click)="action('duplicate', line)"
            href="javascript:void(0)"
            data-cy="A-3c20"
          ></a>
        </li>
        <li *ngIf="line.ownerId == me.id && line.isActive">
          <a
            class="dropdown-item text-danger text-decoration-none"
            [translate]="'EMAILING.DESACTIVER'"
            (click)="action('toggle', line)"
            href="javascript:void(0)"
            data-cy="A-d279"
          ></a>
        </li>
        <li *ngIf="line.ownerId == me.id && !line.isActive">
          <a
            class="dropdown-item text-success text-decoration-none"
            [translate]="'EMAILING.ACTIVER'"
            (click)="action('toggle', line)"
            href="javascript:void(0)"
            data-cy="A-2ada"
          ></a>
        </li>
        <li *ngIf="line.ownerId == me.id && !line.default">
          <a
            class="dropdown-item text-secondary text-decoration-none"
            [translate]="'EMAILING.SUPPRIMER'"
            (click)="action('delete', line)"
            href="javascript:void(0)"
            data-cy="A-5a0a"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #dateTemplate let-formatted="formatted">
  {{ formatted['createdAt'] | date: 'shortDate' }}
</ng-template>

<ng-template #languageTemplate let-formatted="formatted">
  <img src="//visiotalent-emails.s3.amazonaws.com/flags/{{ formatted['language'] }}.png" width="26" alt="{{ formatted['language'] }}" />
</ng-template>

<ng-template #stateTemplate let-line="line" let-data="data">
  <i *ngIf="line.isActive" class="md-24 material-icons text-success" data-cy="I-8f64">fiber_manual_record</i>
  <i *ngIf="!line.isActive" class="md-24 material-icons" data-cy="I-b94a">pause_circle_filled</i>
</ng-template>

<ng-template #mailTypeTemplate let-line="line" let-data="data">
  {{ emailTypes[line.statusId] || emailTypes[line.type] }}
</ng-template>

<ng-template #avatarTemplate let-line="line" let-formatted="formatted" let-data="data">
  <cc-user-thumbnail class="d-block" [thumbnail]="formatted.owner" [size]="32"></cc-user-thumbnail>
</ng-template>

<ng-template #modeTemplate let-formatted="formatted" let-data="data">
  <div class="badge me-1 text-secondary" [ngClass]="colorMode[formatted.mode.value]" data-cy="DIV-8c35">
    {{ formatted.mode.label }}
  </div>
</ng-template>
