import { ConfigModel } from 'ngx-bootstrap/pagination/models';
import { TranslateService } from '@ngx-translate/core';
import { PaginationConfig } from 'ngx-bootstrap/pagination';

export function paginationConfigFactory(translate: TranslateService) {
  return {
    main: {
      maxSize: void 0,
      itemsPerPage: 10,
      boundaryLinks: false,
      directionLinks: true,
      firstText: '',
      previousText: translate.instant('COMMON.PRECEDENT'),
      nextText: translate.instant('COMMON.SUIVANT'),
      lastText: '',
      pageBtnClass: '',
      rotate: true,
    } as ConfigModel,
  } as PaginationConfig;
}
