import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, forkJoin, from, of } from 'rxjs';
import { Credential } from './credential/credential';
import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { Config } from 'src/app/shared/config/config';
import { VariantCredential } from './credential/variant-credential';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private config: Config;

  constructor(private httpClient: HttpClient, configService: DynamicConfigService) {
    this.config = configService.get<Config>();
  }

  public upload(file: string, name: string, variants: { [key: string]: string } = {}): Observable<VariantCredential> {
    const fileUploadUrl = `${this.config.apiUrl}/user/files`;
    let credentials: VariantCredential;

    const blob = this.dataURItoBlob(file, 'unknown');

    return this.getBlobMd5Checksum(blob).pipe(
      mergeMap((md5: string) => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('size', file.length.toString());
        formData.append('md5', encodeURIComponent(md5));

        Object.keys(variants).forEach(variant => {
          formData.append('variants[]', variant);
        });

        formData.append('file', blob.slice(0, 4100));

        return this.httpClient.post<VariantCredential>(fileUploadUrl, formData).pipe(
          mergeMap(variantCredential => {
            credentials = variantCredential;
            return forkJoin(
              [
                { file: file, credential: credentials.file, variant: false },
                ...credentials.variants.map(v => ({ file: variants[v.key], credential: v.credential, variant: true })),
              ].map(c => this.postBlob(c.file, c.credential, c.variant))
            );
          }),
          map(_ => credentials)
        );
      })
    );
  }

  public getBlobMd5Checksum(blob: Blob): Observable<string> {
    return from(
      new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.readAsBinaryString(blob);
        reader.onloadend = () => {
          resolve(CryptoJS.enc.Base64.stringify(CryptoJS.MD5(CryptoJS.enc.Latin1.parse(reader.result.toString()))));
        };
      })
    );
  }

  private postBlob(file: string, credentials: Credential, isVariant: boolean = false): Observable<void> {
    const uploadOptions = { headers: { noToken: 'noToken' } };
    const file$ = this.isURI(file) ? from(this.getBase64Image(file)) : of(file);

    return file$.pipe(
      mergeMap(base64 => {
        const formData = this.buildFormData(credentials.fields, credentials.conditions.contentType, base64, isVariant);
        return this.httpClient.post<void>(credentials.url, formData, uploadOptions);
      })
    );
  }

  private getBase64Image(imgUrl): Promise<string> {
    return new Promise<string>(resolve => {
      this.httpClient.get(imgUrl, { headers: { noToken: 'noToken' }, responseType: 'blob' }).subscribe(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
      });
    });
  }

  private isURI(str: string) {
    const pattern = new RegExp(
      '^((ft|htt)ps?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
        '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return pattern.test(str);
  }

  private buildFormData(fields: { [key: string]: string }, mimetype: string, base64: string, isVariant: boolean): FormData {
    const formData = new FormData();

    formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Date', fields['X-Amz-Date']);
    formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
    formData.append('Policy', fields.Policy);
    formData.append('ContentType', mimetype);
    formData.append('acl', 'public-read');

    if (!isVariant) {
      formData.append('Content-MD5', fields['Content-MD5']);
    }

    formData.append('key', fields.key);
    formData.append('file', this.dataURItoBlob(base64, mimetype));

    return formData;
  }

  private dataURItoBlob(dataURI, type) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  }
}
