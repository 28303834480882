import { DynamicConfigService } from '@cleverconnect/ngx-dynamic-config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../../config/config';
import { User } from '../../../auth/user';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  private config: Config;
  constructor(private http: HttpClient, configService: DynamicConfigService) {
    this.config = configService.get<Config>();
  }

  public deleteShareManager(idShare: string,idCompany?: number) {
    if (idCompany) {
      return this.http.delete<void>(`${this.config.apiUrl}/panel/companies/${idCompany}/managers/shares/${idShare}`);
    }
    return this.http.delete<void>(`${this.config.apiUrl}/settings/managers/shares/${idShare}`);
  }

  public getAllMyManagers(): Observable<{ data: User[]; total: number }> {
    return this.http.get<{ data: User[]; total: number }>(`${this.config.apiUrl}/settings/managers?limit=1000`);
  }

  public getAllCompagnieManagers(idCompany: number): Observable<{ data: User[]; total: number }> {
    return this.http.get<{ data: User[]; total: number }>(`${this.config.apiUrl}/panel/companies/${idCompany}/managers?limit=1000`);
  }

  public getManagerById(managerId: number): Observable<User> {
    return this.http.get<User>(`${this.config.apiUrl}/settings/managers/${managerId}`);
  }
}
