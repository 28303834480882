<form   [formGroup]="resourceSelectorForm"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
  [class.visually-hidden]="isModalVisible"
>
  <div class="modal-header" data-cy="DIV-b151">
    <button type="button" class="close" aria-label="Close" (click)="cancel()" data-cy="BUTTON-526c">
      <span aria-hidden="true" data-cy="SPAN-9447">&times;</span>
    </button>
    <div class="modal-title" data-cy="DIV-4a02">
      <p         class="h3"
        [translate]="'MEDIATHEQUE.TITRE_MODAL_UPLOAD_MEDIAS'"
      ></p>
      <p         class="subtitle"
        [translate]="'MEDIATHEQUE.SOUSTITRE_MODAL_UPLOAD_MEDIAS'"
      ></p>
    </div>
  </div>
  <div class="modal-body" data-cy="DIV-f714">
    <div *ngIf="uploadConfiguration" class="mb-4" data-cy="DIV-9773">
      <div class="row g-2" data-cy="DIV-a530">
        <div class="col-12 col-lg-6" data-cy="DIV-d41a">
          <label class="form-label" data-cy="LABEL-40e2">
            {{'MEDIATHEQUE.IMPORTER_FICHIER_VIDEO_IMAGE'|translate}} :
          </label>
          <br>
          <div ngfDrop selectable="1" [(validDrag)]="validUploadDrag" [(files)]="files" [accept]="uploadConfiguration.accept" [maxSize]="uploadConfiguration.maxSize * 1024 * 1024" [(lastInvalids)]="invalidFiles" class="m-auto m-lg-0 col-12 col-sm-6 p-2 text-center" [class.invalid]="validUploadDrag === false" [class.valid]="validUploadDrag" (filesChange)="upload()" data-cy="DIV-4204">
            <i class="material-icons md-36" data-cy="I-3665">add_to_photos</i>
            <br>
            <small [translate]="'MEDIATHEQUE.IMPORTER_FICHIER'" data-cy="SMALL-543a"></small>
          </div>

          <div class="form-text text-muted small col-12" [class.text-muted]="!invalidFiles?.length" [class.text-danger]="invalidFiles?.length > 0" data-cy="DIV-bf78">
            <span [translate]="'MEDIATHEQUE.FORMAT_FICHIER_AUTORISE'" data-cy="SPAN-b2a9"></span>
            {{ uploadConfiguration.extensions.join(', ') }}.
            <span [translate]="'MEDIATHEQUE.TAILLE_FICHIER_AUTORISE'" data-cy="SPAN-de62"> </span>
            {{ uploadConfiguration.maxSize }}Mo
          </div>
        </div>
        <div class="col-12 col-lg-6" data-cy="DIV-ce57">
          <label class="form-label" data-cy="LABEL-6752">
            {{'MEDIATHEQUE.WEBCAM_RECORD_TITRE'|translate}}
            :
          </label>
          <br>
          <div class="drop m-auto m-lg-0 col-12 col-sm-6 video-record p-2 text-center" (click)="openRecordVideoModal()" data-cy="DIV-0790">
            <i class="material-icons md-36" data-cy="I-93e2">video_call</i> <br>
            <small [translate]="'QUESTIONNAIRE.WEBCAM_RECORD_LABEL'" data-cy="SMALL-1698"></small>
          </div>

          <div [translate]="'MEDIATHEQUE.WEBCAM_RECORD_DESCRIPTION'" class="col-12 small form-text text-muted" data-cy="DIV-5062"></div>
        </div>
      </div>

      <div class="text-center" *ngIf="uploads?.length" data-cy="DIV-9428">
        <span class="text-secondary small" [translate]="'QUESTIONNAIRE.IMPORT_IN_PROGRESS'" data-cy="SPAN-b46e"></span>
        <div class="d-flex align-items-center" data-cy="DIV-215e">
          <div class="flex-fill" data-cy="DIV-4c50">
            <progressbar class="progress-striped active" [value]="uploads[0].percent" type="primary" data-cy="PROGRESSBAR-e662"></progressbar>
          </div>
          <div class="text-secondary small px-2" data-cy="DIV-9da4">{{ uploads[0].percent }}%</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" data-cy="DIV-8959">
    <button type="submit" [class.disabled]="!resourceSelectorForm.valid" class="btn btn-success" data-cy="BUTTON-02f0">
      <span [translate]="'COMMON.SELECTIONNER'" data-cy="SPAN-bf59"></span> &nbsp;
      <i class="material-icons" data-cy="I-58e0">arrow_forward</i>
    </button>
  </div>
</form>
